import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';
import moment from 'moment';

import { IStoresEnv } from '@core/storesEnv';
import { RoutePaths } from '@core/routes/RoutePaths';
import { performRegisterTrial, RegisterTrialParams, performRegisterHubspot } from 'Register/service';
import { toast } from 'react-toastify';
import { messages } from '../register.messages';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { HUBSPOT_REGISTER_USER_URL } from '@core/api/constants';
import { request } from 'http';

export const RegisterUIStoreInferred = types
    .model('RegisterUIStoreInferred', {
        authError: types.optional(types.string, ''),
        registerModalOpen: types.optional(types.boolean, false),
        captcha: types.maybeNull(types.string),
    })
    .volatile(() => ({
        status: DataStateStore.create({ state: DataState.initial }),
        recaptchaRef: React.createRef<ReCAPTCHA>(),
    }))
    .actions(self => {
        const { navigator, api } = getEnv<IStoresEnv>(self);


        const toggleRegisterModalOpen = (open: boolean) => {
            self.registerModalOpen = open;
        };
        const register = flow(function* (payload: RegisterTrialParams) {
            self.authError = '';
            self.status.setLoading();

            const result = yield performRegisterTrial(api, { payload, errorHandlers: { DEFAULT: '' } });

            if (result.success) {
                self.status.setDone();

                // ===== Hubspot registration =====
                yield performRegisterHubspot(payload);

            } else {
                const { body } = result.error || {};

                self.status.setError();
                self.authError = body?.errors[0];
            }

            return result;
        });
        const setCaptcha = (captcha: string | null) => {
            self.captcha = captcha;
        }
        const resetCaptcha = (): void => {
            self.recaptchaRef.current?.reset();
        };

        return ({
            toggleRegisterModalOpen,
            register,
            setCaptcha,
            resetCaptcha,
        });
    });

type RegisterUIStoreFactoryType = typeof RegisterUIStoreInferred;
interface IRegisterUIStoreFactory extends RegisterUIStoreFactoryType { }
export const RegisterUIStore: IRegisterUIStoreFactory = RegisterUIStoreInferred;
export interface IRegisterUIStore extends Instance<IRegisterUIStoreFactory> { }
export interface IRegisterUIStoreSnapshotIn extends SnapshotIn<IRegisterUIStore> { }
