import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { flex } from '@resources/mixins';
import CircularProgress from '@mui/material/CircularProgress';import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { ThemeProps } from '@styles/theme';
import { useUserAnalyticUIStore } from '@core/useStores';
import { ToastMessage } from '@shared/components/Toast';
import { messages } from '@shared/shared.messages';
import classNames from 'classnames';
import ExportIcon from '@assets/export.svg';
import { useTheme } from '@mui/material/styles';
import { Button } from '@shared/components/Button';

const useStyles = createUseStyles((theme: ThemeProps) => ({

    buttonExport: {
        width: 'auto',
        height: 'auto',
        marginLeft: 4,
        fontSize: 14,
        fontFamily: theme.font.secondary,
        padding: 8,
        borderRadius: theme.radius / 2,
    },

    exportIcon: {
        marginRight: 4,
    },
}));

export const AnalyticsExport: FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const userAnalytics = useUserAnalyticUIStore();
    const exportUserAnalytics = () => {
        toast.success(
            <ToastMessage
                message={messages['export.toast.inProgress']}
                type='success'
            />
        );
        userAnalytics.exportProcedure(
            {
                id: userAnalytics.requestParams.getParams.userId,
                startDate: userAnalytics.requestParams.getParams.startDate,
                endDate: userAnalytics.requestParams.getParams.endDate,
            });
    }

    return (
        <Observer>
            {() => {
                const {
                    isExporting,
                    canExport,
                } = userAnalytics;

                return (
                    <Button
                        onClick={exportUserAnalytics}
                        className={classNames(styles.buttonExport)}
                        color='secondary'
                        disabled={!canExport}
                        loading={isExporting}
                        startIcon={<img src={ExportIcon} className={classNames(styles.exportIcon)} />}>
                        {
                            messages['export.exportButton.analytics']
                        }
                    </Button>
                )
            }}
        </Observer>
    )
}
