import { pick } from 'lodash';

export type ForgotPasswordValues = {
  email: string,
};

export class FormValues {
  email = '';

  constructor(forgotPasswordData?: ForgotPasswordValues | null) {
    if (forgotPasswordData) {
      Object.assign(this, pick(forgotPasswordData, ...Object.keys(this)));
    }
  }
}
