import React, { useState, useEffect } from 'react';
import { getEnv } from 'mobx-state-tree';
import { Observer } from 'mobx-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { DATE_MMM_DD_YYYY } from '@shared/constants';
import { Avatar } from '@shared/components/Avatar';
import { Table } from '@shared/components/Table';
import { SearchForm } from '@shared/components/SearchForm';
import { UsersSummary } from '@shared/components/UsersSummary';
import { NoResultsView } from '@shared/components/NoResultsView';
import { ExpandableList } from '@shared/components/ExpandableList';
import ArrowRightIcon from '@assets/arrow-right.svg';
import EditIcon from '@assets/edit.svg';
import StatsIcon from '@assets/chart.svg';
import ResendInvitationIcon from '@assets/resend-invitation.svg';

import {
    useUsersGridUIStore,
    useUserUIStore,
    useUserPermissionsUIStore,
    useUserProfileUIStore,
} from '@core/useStores';
import { RoutePaths } from '@core/routes/RoutePaths';
import { IStoresEnv } from '@core/storesEnv';
import { useStyles } from './UsersPage.styles';
import { UsersFiltersBar } from '../UsersFiltersBar';
import { UsersFiltersModal } from '../UsersFiltersModal';
import { NoUsersInGroup } from '../NoUsersInGroup';
import { UserModal } from '../UserModal';
import { ResendInviteModal } from '../ResendInviteModal';
import { UserRole } from '../../domain/UserRole';
import { ResendInvitationInputData } from '../../domain/ResendInvitationInputData';
import { ConfirmationModal } from '@shared/components/ConfirmationModal';
import { ToastMessage } from '@shared/components/Toast';

import { messages } from 'Users/users.messages';
import { IPageQueryStoreSnapshotIn, IUserStore, IUserUIStore } from 'Users/stores';
import { UsersExport } from '../UsersExport';
import { UsersImport } from '../UsersImport';
import { ThemeProps } from '@styles/theme';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Button } from '@shared/components/Button';

type SubComponentProps = {
    row: {
        original: {
            subRows: Array<{
                email: string,
                groups: { id: string, name: string }[],
                procedures: { id: string, name: string }[],
            }>
        },
    }
}

type FetchDataType = {
    pageIndex: number;
    sortBy: {
        id: string;
        desc: boolean;
    }[]
}

const EXPANDER_CELL_WIDTH = 24;

const NoInfo = () => {
    const styles = useGridStyles();

    return <div className={styles.noInfo}>{messages['usersPage.noInfo']}</div>
}

const columnHelper = createColumnHelper<IUserStore>();

const columnsData = [
    columnHelper.display({
        id: 'expander',
        minSize: 40,
        maxSize: 40,
        header: () => (
            <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
        ),
        cell: ({cell: { row }}) => {
            const styles = useGridStyles();
            const expanderHandler = row.getToggleExpandedHandler();

            return (
                <div
                    style={{
                        minWidth: EXPANDER_CELL_WIDTH,
                        display: 'flex',
                    }}
                    onClick={expanderHandler}
                >
                    {row.getCanExpand() && (
                        <img
                            src={ArrowRightIcon}
                            className={classNames(styles.expandIcon, { [styles.expanded]: row.getIsExpanded() })}
                        />
                    )}
                </div>
            )
        },
        enableSorting: false,
    }),
    columnHelper.accessor('fullName', {
        header: messages['usersPage.table.fullName.header'],
        minSize: 98,
        size: 17,
        cell: ({ cell: {row}, getValue }) => {
            const styles = useGridStyles();
            const { firstName, lastName, avatar } = row.original as {
                firstName: string, lastName: string, avatar: string,
            };

            return (
                <div className={styles.userWrapper}>
                    <Avatar
                        firstName={firstName}
                        lastName={lastName}
                        image={avatar}
                        className={styles.userAvatar}
                    />
                    <span className={styles.ellipsis}>{getValue()}</span>
                </div>
            );
        },
    }),
    columnHelper.accessor('department', {
        header: messages['usersPage.table.department.header'],
        minSize: 86,
        size: 7,
        // disableEllipsis: true,
        cell: ({ getValue }) => <span title={getValue()}>{getValue()}</span>,
    }),
    columnHelper.accessor('groups', {
        header: messages['usersPage.table.groups.header'],
        minSize: 100,
        size: 9,
        cell: ({ getValue }) => {
            const styles = useGridStyles();
            const value = getValue();
            const firstGroup = value.length && value[0];

            if (!firstGroup) {
                return <NoInfo />;
            }

            return (
                <>
                    {firstGroup.name}
                    {value.length > 1 && (
                        <span className={styles.moreItemsPresent}>+{value.length - 1}</span>
                    )}
                </>
            )
        },
    }),
    columnHelper.accessor('roles', {
        header: messages['usersPage.table.roles.header'],
        minSize: 90,
        maxSize: 110,
        size: 8,
        cell: ({ cell }) => {
            const styles = useGridStyles();
            const value = cell.getValue();
            return (
                value.length
                    ? <span className={styles.capitalized}>{value[0].name.toLowerCase()}</span>
                    : <NoInfo />
            );
        },
    }),
    columnHelper.accessor('lastLoginAt', {
        header: messages['usersPage.table.lastLoginAt.header'],
        minSize: 76,
        size: 9,
        cell: ({ getValue }) => {
            const value = getValue();
            return (value ? moment(value).format(DATE_MMM_DD_YYYY) : <NoInfo />)},
    }),
    columnHelper.accessor('status', {
        header: messages['usersPage.table.status.header'],
        minSize: 100,
        maxSize: 100,
        cell: ({ getValue }) => {
            const styles = useGridStyles();
            const value = getValue();
            return (
                <div className={
                    classNames(styles.userStatus, { [styles.notActive]: value?.name !== 'Active' })
                }>
                    {value?.name}
                </div>
            )
        },
    }),
    columnHelper.display({
        id: 'edit',
        header: () => {

            return (
                <></>
            )
        },
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: ({ cell }) => {
            const styles = useGridStyles();
            const row = cell.row;
            const userUIStore = useUserUIStore();
            const userId = useUserProfileUIStore().userInfo.id;
            const userPermissionsUIStore = useUserPermissionsUIStore();

            const original: Record<string, any> = row.original;
            const userRoles = original.roles.map((role: UserRole) => role.name);

            const canEdit = original.id === userId || userPermissionsUIStore.canEditUser(userRoles);

            const onCellClick = () => {
                userUIStore.setEditUserId(original.id)
                userUIStore.toggleUserModalOpen(true);
            }

            return (
                <button
                    onClick={() => canEdit && onCellClick()}
                    disabled={!canEdit}
                    className={styles.button}
                    title={messages['usersPage.table.editUser']}
                >
                    <img src={EditIcon} />
                </button>
            )
        },
    }),
    columnHelper.display({
        id: 'additionalActions',
        minSize: 42,
        maxSize: 42,
        enableSorting: false,
        cell: ({ cell }) => {
            const styles = useGridStyles();
            const row = cell.row;
            const userUIStore = useUserUIStore();
            const userPermissionsUIStore = useUserPermissionsUIStore();
            const { navigator } = getEnv<IStoresEnv>(userUIStore);

            const original: Record<string, any> = row.original;
            const isInvited = original.status.name === 'Invited';

            const canResendInvite = userPermissionsUIStore.canCreateUser;

            const openResendInviteModal = () => {
                const inputData: ResendInvitationInputData = {
                    id: original.id,
                    email: original.email,
                    fullName: original.fullName,
                };

                userUIStore.setResendInvitationInputData(inputData);
                userUIStore.toggleResendInvitationModalOpened(true);
            };

            const openUserAnalytic = () => {
                navigator.to(`${RoutePaths.analytics}/${original.id}`);
            };

            return (
                <button
                    onClick={() => {
                        if (canResendInvite && isInvited) {
                            openResendInviteModal();
                        } else {
                            openUserAnalytic();
                        }
                    }}
                    className={classNames(styles.button, styles.buttonInvite)}
                    disabled={!canResendInvite}
                >
                    {
                        isInvited
                            ? <img title={messages['usersPage.table.resendInvitation']} src={ResendInvitationIcon} />
                            : <img title={messages['usersPage.table.goToAnalytics']} src={StatsIcon} />
                    }
                </button>
            )
        },
    }),
]

const useGridStyles = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    return styles;
}

export const UsersPage: React.FC = () => {
    const [filtersModalOpen, toggleFiltersModalOpen] = useState(false);

    const userPermissionsUIStore = useUserPermissionsUIStore();
    const usersGridUIStore = useUsersGridUIStore();
    const userUIStore = useUserUIStore();

    const { filtersOptions } = getEnv<IStoresEnv>(usersGridUIStore);

    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const initialSortState = {
        sorting: [{
            id: 'fullName',
            desc: false,
        }],
    };

    const columns: ColumnDef<IUserStore, any>[] = React.useMemo(() => columnsData, []);

    useEffect(() => {
        usersGridUIStore.cleanUp();

        usersGridUIStore.load();
        usersGridUIStore.loadStatistics();

        filtersOptions.loadFilterOptions();
        setTimeout(() => { usersGridUIStore.togglePageActivity(true) }, 100);

        return () => {
            usersGridUIStore.togglePageActivity(false);
            usersGridUIStore.cleanUp();
        }
    }, []);

    const onFetchData = ({ pageIndex, sortBy }: FetchDataType) => {
        const params: IPageQueryStoreSnapshotIn = { page: pageIndex };

        if (sortBy && sortBy.length) {
            params.sortFieldName = sortBy[0].id;
            params.sortDirection = Number(sortBy[0].desc);
        }
        if (usersGridUIStore.isActivePage) { usersGridUIStore.setParams(params); }
    };

    const onSearchTextChanged = (searchText: string) => {
        if (searchText && searchText.length === 1) {
            return;
        }

        usersGridUIStore.setParams({
            page: 0,
            searchText,
        });
    };

    const onInviteUserModalOpen = () => {
        userUIStore.clearEditUserId();
        userUIStore.toggleUserModalOpen(true);
    }

    const onDeleteUserDecline = () => {
        userUIStore.toggleDeleteConfirmationModalOpened(false);
        userUIStore.toggleUserModalOpen(false);
        userUIStore.clearEditUserId();
    }

    const onDeleteUserConfirm = async () => {
        const result = await userUIStore.deleteUser({ id: userUIStore.userToEditId });

        if (!result.success) {
            toast.error(
                <ToastMessage
                    message={messages['usersPage.removeUser.toast.error']}
                    type='error'
                />
            );
        } else {
            toast.success(
                <ToastMessage
                    message={messages['usersPage.removeUser.toast.success']}
                    type='success'
                />
            );

            usersGridUIStore.setParams({ page: 0 });
            usersGridUIStore.load();
            usersGridUIStore.loadStatistics();
            filtersOptions.loadFilterOptions();
        }

        userUIStore.clearEditUserId();
        userUIStore.toggleUserModalOpen(false);
        userUIStore.toggleDeleteConfirmationModalOpened(false);
    }

    const onInviteUserModalClose = () => {
        const params = usersGridUIStore.inviteUser.getInviteUserFields;

        usersGridUIStore.inviteUser.setInviteUserFields({ ...params, errors: null });
        userUIStore.clearEditUserId();
        userUIStore.toggleUserModalOpen(false);
    }

    const onResendInviteUserModalClose = () => {
        userUIStore.setResendInvitationInputData(null);
        userUIStore.toggleResendInvitationModalOpened(false);
    }

    const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
        const data = row.original.subRows[0];

        return (
            <div className={styles.body}>
                <div className={styles.emailWrapper}>
                    <h5 className={styles.header}>{messages['usersPage.subRow.email']}</h5>
                    <p className={styles.text}>{data.email}</p>
                </div>

                <div className={styles.groupsWrapper}>
                    <h5 className={styles.header}>{messages['usersPage.subRow.assignedGroups']}</h5>
                    <div className={styles.text}>
                        {
                            data.groups.length
                                ? <ExpandableList list={data.groups} />
                                : <NoInfo />
                        }
                    </div>
                </div>

                <div>
                    <h5 className={styles.header}>{messages['usersPage.subRow.assignedProcedures']}</h5>
                    <div className={styles.text}>
                        {
                            data.procedures.length
                                ? <ExpandableList list={data.procedures} />
                                : <NoInfo />
                        }
                    </div>
                </div>
            </div>
        )
    }, []);

    return (
        <>
            <UsersSummary
                statistics={usersGridUIStore.statistics}
                pageQueryStore={usersGridUIStore.requestParams}
            />

            <div className={styles.tableActions}>
                <div className={styles.invite}>
                    <Button variant="contained"
                            color="primary"
                            startIcon={<PersonAddIcon />}
                            disabled={!userPermissionsUIStore.canCreateUser}
                            onClick={onInviteUserModalOpen}>
                        {messages['usersPage.inviteUser.title']}
                    </Button>
                </div>
                <div className={styles.filters}>
                    <Observer>
                        {() => (
                            <>
                                <SearchForm
                                    initialValues={{
                                        search: usersGridUIStore.requestParams.getStorageParams
                                            ? usersGridUIStore.requestParams.getStorageParams.searchText
                                            : '',
                                    }}
                                    onSearchTextChanged={onSearchTextChanged}
                                    className={styles.searchWrapper}
                                    disabled={usersGridUIStore.status.isLoading}
                                />
                            </>
                        )}
                    </Observer>

                    <span style={{ display: 'inherit' }} title={messages['usersPage.filter.title']}>
                        <FilterListOutlinedIcon
                            onClick={() => toggleFiltersModalOpen(true)}
                            className={styles.filterIcon}
                        />
                    </span>
                </div>
                {
                    (userPermissionsUIStore.isSuperAdmin || userPermissionsUIStore.isMultiAdmin) && (
                        <div className={classNames(styles.importButton)}>
                            <UsersImport />
                        </div>
                    )
                }
                <div className={classNames(styles.exportButton)}>
                    <UsersExport />
                </div>
                <UsersFiltersModal
                    isOpen={filtersModalOpen}
                    onRequestClose={() => toggleFiltersModalOpen(false)}
                />
            </div>
            <UsersFiltersBar />
            <Observer>
                {() => {
                    const data = usersGridUIStore.users.toJSON();
                    const preparedData = data.map(row => ({
                        ...row,
                        subRows: [{
                            email: row.email,
                            groups: row.groups,
                            procedures: row.procedures,
                        }],
                    }));

                    const genericColumns = columns as ColumnDef<{}, any>[];
                    return (
                        <>
                            <UserModal
                                isOpen={userUIStore.userModalOpened}
                                onRequestClose={onInviteUserModalClose}
                            />
                            <ResendInviteModal
                                isOpen={userUIStore.resendInvitationModalOpened}
                                onRequestClose={onResendInviteUserModalClose}
                            />
                            {/* TODO move logic of modals to separate component */}
                            <ConfirmationModal
                                isOpen={userUIStore.deleteConfirmationModalOpened}
                                message={messages['usersPage.delete.message']}
                                confirmText={messages['usersPage.delete.confirmText']}
                                onDecline={onDeleteUserDecline}
                                onConfirm={onDeleteUserConfirm}
                            />

                            {
                                usersGridUIStore.isTableVisible && (
                                    <Table
                                        data={preparedData}
                                        columns={genericColumns}
                                        fetchData={onFetchData}
                                        isLoading={usersGridUIStore.status.isLoading}
                                        renderRowSubComponent={renderRowSubComponent}
                                        paginationStatus={{ ...usersGridUIStore.pagination }}
                                        initialState={initialSortState}
                                    />
                                )
                            }

                            {
                                usersGridUIStore.noFilteringResults && <NoResultsView entityName='users' />
                            }

                            {
                                usersGridUIStore.noItemsInGroup && <NoUsersInGroup />
                            }
                        </>
                    )
                }}
            </Observer>
        </>
    )
}
