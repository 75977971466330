import * as Yup from 'yup';
import { LicenseFormValues } from './';
import { FIELD_NAMES } from './valuesSchema';
import { messages } from 'Licenses/license.messages';

export const errors = {
    required: messages['licenseModal.error.required'],
};

export const validationSchema = Yup.object<Partial<LicenseFormValues>>({

    name: Yup.string()
        .required(errors.required(FIELD_NAMES.name)),
    unityId: Yup.string()
        .required(errors.required(FIELD_NAMES.unityId)),
});
