import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { ThemeProps } from '@styles/theme';
import { useGroupsUIStore, useUserProfileUIStore, useUsersGridUIStore } from '@core/useStores';
import { ToastMessage } from '@shared/components/Toast';
import { messages } from '@shared/shared.messages';
import classNames from 'classnames';
import ExportIcon from '@assets/export.svg';
import { useTheme } from '@mui/material/styles';
import { Button } from '@shared/components/Button';

const useStyles = createUseStyles((theme: ThemeProps) => ({

    buttonExport: {
        width: 'auto',
        height: 'auto',
        fontFamily: theme.font.secondary,
        fontSize: 14,
        lineHeight: 1.4,
        fontWeight: 'normal',
        padding: 8,
        borderRadius: theme.radius / 2,
        background: theme.colors.icon_background,
        color: theme.colors.violet_primary,
        '&:hover': {
            background: theme.colors.transp_violet,
        },
    },

    exportIcon: {
        margin: '0 2px',
        height: 22,
        width: 22
    },
}));

export const UsersExport: FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const usersUIStore = useUsersGridUIStore();
    const groupsUIStore = useGroupsUIStore();
    const userProfileUIStore = useUserProfileUIStore();

    const exportUsers = () => {
        toast.success(
            <ToastMessage
                message={messages['export.toast.inProgress']}
                type='success'
            />
        );
        const companyId = userProfileUIStore.userInfo.companyId;
        usersUIStore.exportAllUsers({ extendedData: true, groupId: groupsUIStore.selectedGroup?.id }, companyId);
    }

    return (
        <Observer>
            {() => {
                const {
                    isExporting,
                    canExport,
                } = usersUIStore;

                return (
                    <Button
                        onClick={exportUsers}
                        className={classNames(styles.buttonExport)}
                        color='secondary'
                        disabled={!canExport}
                        loading={isExporting}
                        startIcon={<img src={ExportIcon} className={styles.exportIcon} />}
                        >
                        {
                            messages['export.exportButton.all']
                        }
                    </Button>
                )
            }}
        </Observer>
    )
}
