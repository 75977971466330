import { HUBSPOT_REGISTER_USER_URL } from '@core/api/constants';
import { RegisterTrialParams } from './registerTrial';


export const performRegisterHubspot = async (payload: RegisterTrialParams) => {
    const hubspotPayload = {
        fields: [
            {
                "objectTypeId": "0-1",
                "name": "email",
                "value": payload.email,
            },
            {
                "objectTypeId": "0-1",
                "name": "firstname",
                "value": payload.firstName,
            },
            {
                "objectTypeId": "0-1",
                "name": "lastname",
                "value": payload.lastName,
            },
            {
                "objectTypeId": "0-1",
                "name": "company",
                "value": payload.companyName,
            }
        ],
        "context": {
            "pageUri": "manager.xr-weavr.com",
            "pageName": "WEAVR Manager",
        },
    };
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(hubspotPayload),
    };

    await fetch(HUBSPOT_REGISTER_USER_URL, requestMetadata)
        .then(res => res.json())
        .then(output => output); // Handle result
}