import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { IStoresEnv } from '@core/storesEnv';
import { SetPasswordFormValues } from '../components/SetPasswordForm/FormValues';

type FormValuesToSend = SetPasswordFormValues & {
    token: string;
    email: string;
};

export const SetPasswordUIStoreInferred = types
    .model('SetPasswordUIStoreInferred', {
        processError: types.optional(types.string, ''),
    })
    .volatile(() => ({
        status: DataStateStore.create({ state: DataState.initial }),
        tokenValidationStatus: DataStateStore.create({ state: DataState.initial }),
    }))
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);
        const { auth } = getEnv(api);

        return ({
            setPassword: flow(function* (values: FormValuesToSend, isInviteMode: boolean) {
                self.processError = '';
                self.status.setLoading();

                const result = isInviteMode
                    ? yield auth.setPassword(values)
                    : yield auth.resetPassword(values);

                if (result.success) {
                    self.status.setDone();
                } else {
                    const { body } = result.error || {};

                    self.status.setError();
                    self.processError = body?.errors[0];
                }
            }),

            verifyToken: flow(function* (email: string, token: string, mode?: string) {
                self.tokenValidationStatus.setLoading();

                const values = { email, token, mode };
                const result = yield auth.verifyToken(values);

                if (result.success) {
                    self.tokenValidationStatus.setDone();
                } else {
                    const { body } = result.error || {};

                    self.tokenValidationStatus.setError();
                    self.processError = body?.errors[0];
                }
            }),

            resetSuccessView: () => self.status.setInitial(),
        })
    });

type SetPasswordUIStoreFactoryType = typeof SetPasswordUIStoreInferred;
interface ISetPasswordUIStoreFactory extends SetPasswordUIStoreFactoryType { }
export const SetPasswordUIStore: ISetPasswordUIStoreFactory = SetPasswordUIStoreInferred;
export interface ISetPasswordUIStore extends Instance<ISetPasswordUIStoreFactory> { }
export interface ISetPasswordUIStoreSnapshotIn extends SnapshotIn<ISetPasswordUIStore> { }
