import React, { useEffect } from 'react';
import { getEnv } from 'mobx-state-tree';
import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import ArrowRightIcon from '@assets/arrow-right.svg';
import EditIcon from '@assets/edit.svg';
import KeysIcon from '@assets/keys.png';

import { useCompaniesGridUIStore, useCompanyUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import { useStyles } from './CompaniesPage.styles';
import { CompanyModal } from '../CompanyModal';
import { Table } from '@shared/components/Table';
import { ConfirmationModal } from '@shared/components/ConfirmationModal';
import { ConfirmPasswordModal } from 'AuthPart/ConfirmWithPassword';
import { ToastMessage } from '@shared/components/Toast';

import { messages } from 'Companies/companies.messages';
import moment from 'moment';
import { DATE_MMM_DD_YYYY } from '@shared/constants';
import { SearchForm } from '@shared/components/SearchForm';
import { ThemeProps } from '@styles/theme';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ICompanyStore } from 'Companies/stores';
import { Button } from '@shared/components/Button';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LogoutIcon from '@mui/icons-material/Logout';

const EXPANDER_CELL_WIDTH = 24;
const MIN_DESCRIPTION_LENGTH = 100;

type SubComponentProps = {
    row: {
        original: {
            subRows: Array<{
                description: string,
                dataIsolation: boolean,
                allowStandaloneBuild: boolean,
                maxUsers: number,
                maxProcedures: number,
                maxGroups: number,
                maxLicensesCreator: number,
                maxLicensesPlayer: number
            }>,
        }
    }
}
type FetchDataType = {
    pageIndex: number;
    sortBy: {
        id: string;
        desc: boolean;
    }[]
}
const NoInfo = () => {
    const styles = useGridStyles();

    return <div className={styles.noInfo}>{messages['companiesPage.noInfo']}</div>
}

const columnHelper = createColumnHelper<ICompanyStore>();

const columnsData = [
    columnHelper.display({
        id: 'expander',
        minSize: 40,
        maxSize: 40,
        header: () => (
            <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
        ),
        cell: ({ cell:{ row }}) => {
            const styles = useGridStyles();
            const expanderHandler = row.getToggleExpandedHandler();

            return (
                <div
                    style={{
                        minWidth: EXPANDER_CELL_WIDTH,
                        display: 'flex',
                    }}
                    onClick={expanderHandler}
                >
                    {row.getCanExpand() && (
                        <img
                            src={ArrowRightIcon}
                            className={classNames(styles.expandIcon, { [styles.expanded]: row.getIsExpanded() })}
                        />
                    )}
                </div>
            )
        },
        enableSorting: false,
    }),
    columnHelper.accessor('name', {
        header: messages['companiesPage.table.name.header'],
        minSize: 86,
        maxSize: 250,
        size: 7,
        cell: ({ getValue }) => <span title={getValue()}>{getValue()}</span>,
    }),
    columnHelper.accessor('description', {
        header: messages['companiesPage.table.description.header'],
        minSize: 86,
        maxSize: 500,
        cell: ({ getValue }) => <span title={getValue() ?? messages['companiesPage.table.description.header.empty']}>{getValue()}</span>,
    }),
    columnHelper.accessor('createdAt', {
        header: messages['companiesPage.table.createdAt.header'],
        minSize: 50,
        size: 7,
        cell: ({ getValue }) => {
            const value = getValue();
            return (
                value ? moment(value).format(DATE_MMM_DD_YYYY) : <></>
            );
        }
    }),
    columnHelper.accessor('expireAt', {
        header: messages['companiesPage.table.expireAt.header'],
        minSize: 50,
        size: 7,
        cell: ({ getValue }) => {
            const value = getValue();
            return (
                value ? moment(value.replace("T23:59:59Z", "T12:00:00Z")).format(DATE_MMM_DD_YYYY) : <></>);
            }
    }),
    columnHelper.display({
        id: 'edit',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: ({ cell: { row } }) => {
            const styles = useGridStyles();

            const companyUIStore = useCompanyUIStore();
            const userPermissionsUIStore = useUserPermissionsUIStore();

            const original: Record<string, any> = row.original;

            const canEdit = userPermissionsUIStore.canUpdateCompany;

            const onCellClick = () => {
                companyUIStore.setEditCompanyId(original.id)
                companyUIStore.toggleCompanyModalOpen(true);
            }

            return (
                <button
                    onClick={() => canEdit && onCellClick()}
                    disabled={!canEdit}
                    className={styles.button}
                    title={messages['companiesPage.table.editCompany']}
                >
                    <img src={EditIcon} />
                </button>
            )
        },
    }),
    columnHelper.display({
        id: 'additionalActions',
        minSize: 42,
        maxSize: 42,
        enableSorting: false,
        cell: ({ cell: { row } }) => {
            const styles = useGridStyles();
            const companyUIStore = useCompanyUIStore();
            const { api } = getEnv<IStoresEnv>(companyUIStore);
            const { auth } = getEnv(api);

            const original: Record<string, any> = row.original;

            const isOvertaken = original.id == auth.userProfile.companyId;

            const enterCompany = () => {
                companyUIStore.setSwitchCompanyId(original.id);
                companyUIStore.toggleConfirmSwithModalOpen(true);
            };

            return (
                <Observer>
                    {() => (
                        <button
                            onClick={() => enterCompany()}
                            className={classNames(styles.button, styles.buttonTakeover)}
                            disabled={isOvertaken}
                        >
                            {
                                <img className={styles.actionIcon}
                                    title={messages['companiesPage.table.enterCompany']}
                                    src={KeysIcon} />
                            }
                        </button>
                    )}
                </Observer>
            )
        },
    }),
]

const useGridStyles = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    return styles;
}

export const CompaniesPage: React.FC = () => {
    const userPermissionsUIStore = useUserPermissionsUIStore();
    const companiesGridUIStore = useCompaniesGridUIStore();
    const companiesUIStore = useCompanyUIStore();

    const { filtersOptions } = getEnv<IStoresEnv>(companiesGridUIStore);

    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    if (userPermissionsUIStore.canUpdateCompany) {
        companiesUIStore.loadAdmins();
    }
    const initialSortState = {
        sorting: [{
            id: 'name',
            desc: false,
        }],
    };

    const columns: ColumnDef<ICompanyStore, any>[] = React.useMemo(() => columnsData, []);

    const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
        const data = row.original.subRows[0];

        return (
            <div className={styles.bodySubComponent} >
                { data.description && data.description.length > MIN_DESCRIPTION_LENGTH &&
                    <div className={styles.descriptionWrapper}>
                        <h5 className={styles.header}>{messages['companyModal.description.label']}</h5>
                        <div className={styles.text} style={{ wordBreak: "break-all" }}>
                            {data.description || <NoInfo />}
                        </div>
                    </div>
                }
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['companiesPage.details.limitations']}</h5>
                    <div className={styles.optionWrapper}>
                        <h6 className={styles.subheader}>{messages['companiesPage.table.maxUsers.header']}</h6>
                        <div className={styles.text} >
                            <span >{data.maxUsers}</span>
                        </div>
                    </div>
                    <div className={styles.optionWrapper}>
                        <h6 className={styles.subheader}>{messages['companiesPage.table.maxProcedures.header']}</h6>
                        <div className={styles.text} >
                            <span >{data.maxProcedures}</span>
                        </div>
                    </div>
                    <div className={styles.optionWrapper}>
                        <h6 className={styles.subheader}>{messages['companiesPage.table.maxGroups.header']}</h6>
                        <div className={styles.text} >
                            <span >{data.maxGroups}</span>
                        </div>
                    </div>
                    <div className={styles.optionWrapper}>
                        <h6 className={styles.subheader}>{messages['companiesPage.table.maxLicensesCreator.header']}</h6>
                        <div className={styles.text} >
                            <span >{data.maxLicensesCreator}</span>
                        </div>
                    </div>
                    <div className={styles.optionWrapper}>
                        <h6 className={styles.subheader}>{messages['companiesPage.table.maxLicensesPlayer.header']}</h6>
                        <div className={styles.text} >
                            <span >{data.maxLicensesPlayer}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['companiesPage.details.title']}</h5>
                    <div className={styles.optionWrapper}>
                        <h6 className={styles.subheader}>{messages['companiesPage.table.dataIsolation.header']}</h6>
                        <div className={styles.text} >
                            <span title={data.dataIsolation ? 'True' : 'False'}>{data.dataIsolation ? 'True' : 'False'}</span>
                        </div>
                    </div>
                    <div className={styles.optionWrapper}>
                        <h6 className={styles.subheader}>{messages['companiesPage.table.allowStandaloneBuild.header']}</h6>
                        <div className={styles.text} >
                            <span title={data.allowStandaloneBuild ? 'True' : 'False'}>{data.allowStandaloneBuild ? 'True' : 'False'}</span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }, []);

    useEffect(() => {
        companiesGridUIStore.togglePageActivity(true);

        companiesGridUIStore.load();

        filtersOptions.loadFilterOptions();

        return () => {
            companiesGridUIStore.togglePageActivity(false);
            companiesGridUIStore.cleanUp();
        }
    }, []);

    const onExitCompany = () => {
        companiesUIStore.setSwitchCompanyId(null);
        companiesUIStore.toggleConfirmSwithModalOpen(true);
    }

    const onCreateCompanyModalOpen = () => {
        companiesUIStore.clearEditCompanyId();
        companiesUIStore.toggleCompanyModalOpen(true);
    }

    const onDeleteCompanyDecline = () => {
        companiesUIStore.toggleDeleteConfirmationModalOpened(false);
        companiesUIStore.toggleCompanyModalOpen(false);
        companiesUIStore.clearEditCompanyId();
    }

    const onDeleteCompanyConfirm = async () => {
        const result = await companiesUIStore.deleteCompany({ id: companiesUIStore.companyToEditId });

        if (!result.success) {
            toast.error(
                <ToastMessage
                    message={messages['companiesPage.removeCompany.toast.error']}
                    type='error'
                />
            );
        } else {
            toast.success(
                <ToastMessage
                    message={messages['companiesPage.removeCompany.toast.success']}
                    type='success'
                />
            );

            //companiesGridUIStore.setParams({ page: 0 });
            companiesGridUIStore.load();
            //companiesGridUIStore.loadStatistics();
            filtersOptions.loadFilterOptions();
        }

        companiesUIStore.clearEditCompanyId();
        companiesUIStore.toggleCompanyModalOpen(false);
        companiesUIStore.toggleDeleteConfirmationModalOpened(false);
    }

    const onCreateCompanyModalClose = () => {
        const params = companiesGridUIStore.createCompany.getCompanyFields;

        companiesGridUIStore.createCompany.setCompanyFields({ ...params, errors: null });
        companiesUIStore.clearEditCompanyId();
        companiesUIStore.clearErrors();
        companiesUIStore.toggleCompanyModalOpen(false);
    }

    const onSwitchCompany = (password: string) => {
        const result = companiesUIStore.switchCompany({ companyId: companiesUIStore.switchCompanyId, password });

        return result;
    }

    const onSwitchCompanyClosed = () => {
        companiesUIStore.setSwitchCompanyId(null);
        companiesUIStore.toggleConfirmSwithModalOpen(false);
    }
    const onSearchTextChanged = (searchText: string) => {
        companiesGridUIStore.changeSearchText(searchText);
    };

    const onFetchData = ({ pageIndex, sortBy }: FetchDataType) => {
        if (sortBy && sortBy.length) {
            companiesGridUIStore.setupSort(sortBy[0].id, Number(sortBy[0].desc));
        }
        companiesGridUIStore.setupPage(pageIndex);
    };

    return (
        <>

            <h2 className={styles.title}>
                {messages['companiesPage.pageTitle']}
            </h2>
            <div className={styles.tableActions}>
                <div className={styles.invite}>
                    {
                        userPermissionsUIStore.canCreateCompany && (
                            <span style={{ display: 'inherit' }}>
                                <Button
                                    color='primary'
                                    onClick={onCreateCompanyModalOpen}
                                    startIcon={<AddBusinessIcon />}
                                >
                                    {
                                        messages['companiesPage.createCompany.title']
                                    }
                                </Button>
                                <Observer>
                                    {() => {
                                        return (
                                            <Button
                                                color='secondary'
                                                onClick={onExitCompany}
                                                className={classNames(styles.marginLeft)}
                                                disabled={!companiesUIStore.canExitCompany}
                                                startIcon={<LogoutIcon />}
                                            >
                                                {
                                                    messages['companiesPage.exitCompany.title']
                                                }
                                            </Button>)
                                    }}
                                </Observer>


                            </span>
                        )
                    }
                </div>
                <div className={styles.filters}>
                    <Observer>
                        {() => (
                            <SearchForm
                                initialValues={{
                                    search: companiesGridUIStore.searchText
                                        ? companiesGridUIStore.searchText
                                        : '',
                                }}
                                onSearchTextChanged={onSearchTextChanged}
                                className={styles.searchWrapper}
                                disabled={companiesGridUIStore.status.isLoading}
                            />
                        )}
                    </Observer>
                </div>
            </div>

            <Observer>
                {() => {
                    const data = companiesGridUIStore.companies.toJSON();
                    const preparedData = data.map(row => ({
                        ...row,
                        subRows: [{
                            description: row.description,
                            dataIsolation: row.dataIsolation,
                            allowStandaloneBuild: row.allowStandaloneBuild,
                            maxUsers: row.maxUsers,
                            maxGroups: row.maxGroups,
                            maxProcedures: row.maxProcedures,
                            maxLicensesCreator: row.maxLicensesCreator,
                            maxLicensesPlayer: row.maxLicensesPlayer,
                        }],
                    }));

                    return (
                        <>
                            <ConfirmPasswordModal
                                isOpen={companiesUIStore.confirmPasswordModalOpened}
                                actionName={messages['companiesPage.switchCompany.label']}
                                onSubmitPassword={onSwitchCompany}
                                onRequestClose={onSwitchCompanyClosed}
                            />
                            <CompanyModal
                                isOpen={companiesUIStore.companyModalOpened}
                                onRequestClose={onCreateCompanyModalClose}
                            />
                            {/* TODO move logic of modals to separate component */}
                            <ConfirmationModal
                                isOpen={companiesUIStore.deleteConfirmationModalOpened}
                                message={messages['companiesPage.delete.message']}
                                confirmText={messages['companiesPage.delete.confirmText']}
                                onDecline={onDeleteCompanyDecline}
                                onConfirm={onDeleteCompanyConfirm}
                            />

                            {
                                companiesGridUIStore.isTableVisible && (
                                    <Table
                                        fetchData={onFetchData}
                                        data={preparedData}
                                        columns={columns}
                                        isLoading={companiesGridUIStore.status.isLoading}
                                        renderRowSubComponent={renderRowSubComponent}

                                        paginationStatus={{ ...companiesGridUIStore.pagination }}
                                        initialState={initialSortState}
                                    />
                                )
                            }
                        </>
                    )
                }}
            </Observer>
        </>
    )
}
