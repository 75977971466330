import { RouteProps, Route, Navigate, NavigateProps, PathRouteProps, Outlet } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';

import { useUserProfileUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { RoutePaths } from '@core/routes/RoutePaths';
import { PagesRoutes } from '@core/routes/PagesRoutes';
import { PagesNames } from '@core/routes/PagesNames';

interface RoutePropsExtended {
    page: string;
    children?: React.ReactNode;
};

export const ProtectedRoute = () => {
    const userProfileUIStore = useUserProfileUIStore();

    return (
        userProfileUIStore.isAuthentificated
                ? <Outlet />
                : <Navigate to={{ pathname: RoutePaths.login}} state={ {from: location.pathname} } />
        );
}

export const ProtectedPage: React.FC<RoutePropsExtended> = ({ page, children }: RoutePropsExtended) => {
    const userProfileUIStore = useUserProfileUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    const isPageAvailable = userPermissionsUIStore.isPageAvailable(page);
    const firstAvailablePage = userPermissionsUIStore.firstAvailablePage;

    return (
        userProfileUIStore.isAuthentificated
            ? isPageAvailable
                ? <>{children}</>
                : (
                    <Navigate
                        to={{
                            pathname: PagesRoutes.get(firstAvailablePage || PagesNames.Login),
                        }}
                        replace
                    />
                )
            : (
                <Navigate
                    to={{
                        pathname: RoutePaths.login,
                    }}
                    // state={{ from: location }}
                />
            )
        );
}
