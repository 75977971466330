import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export type ExportProcedureParams = {
    id: string;
    groupId: string;
    extendedData: boolean;
}

export const performExportAllProcedures = async (api: IApiStore, params: EndpointCallParams<ExportProcedureParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .getBlob('export-app/api/v1/Procedures/all', {
            ...rest,
            payload,
        });

    return req;
}

export const performExportProcedure = async (api: IApiStore, params: EndpointCallParams<ExportProcedureParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .getBlob(`export-app/api/v1/Procedures/${payload.id}?extendedData=${payload.extendedData}`, {
            ...rest,
            payload,
        });

    return req;
}