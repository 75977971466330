import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export type ExportAllExecutionsParams = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  procedureName?: string;
  procedureId?: string;
  userId?: string;
  versionId?: string;
  platform?: string;
  group?: string;
}

export const performExportAllExecutions
  = async (api: IApiStore, params: EndpointCallParams<ExportAllExecutionsParams>) => {
    const { payload, ...rest } = params;
    const req = api
      .getBlob(`analytic-app/api/v1/Analytics/GetUsageData?startDate=${payload.startDate?.toISOString()}&endDate=${payload.endDate?.toISOString()}`, {
                // TODO: Add the rest of the query parameters
        payload: '',
        ...rest,
      });

    return req;
  }