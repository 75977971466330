import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';
import { Subject } from 'rxjs';

import { LicensesDataStore } from './Licenses.store';
import { GetLicensesParams } from 'Licenses/service/getLicenses';
import { SelectGroup } from '@shared/components/SelectDropdown/Group';

export const LICENSES_SEARCH_TEXT_KEY = 'licenses_search_text';
export const LicensesUIStoreInferred = types
    .model('LicensesUIStore', {
        _licenses: types.optional(LicensesDataStore, {}),
        searchText: types.optional(types.string, ''),
        isLoading: types.optional(types.boolean, false),
        licenseModalOpened: types.optional(types.boolean, false),
        page: types.optional(types.integer, 0),
        sortFieldName: types.optional(types.string, ''),
        sortDirection: types.optional(types.integer, 0),
    })
    .actions(self => {
        const loadLicenses = flow(function* () {
            // Default params
            const payload: GetLicensesParams = {
                limit: 10,
                page: self.page,
                searchText: self.searchText,
                sortFieldName: self.sortFieldName,
                sortDirection: self.sortDirection,
            }

            self.isLoading = true;
            yield self._licenses.load(payload);
            self.isLoading = false;
        });
        const resetLicense = (id: string, isDisabled: boolean) => {
            self._licenses.licenses.forEach(lic => {
                if (lic.unityId == id) {
                    lic.isDisabled = isDisabled;
                }
            });
        }
        const createLicense = (id: string, name: string) => {
            self._licenses.selectedId = id;
            self._licenses.selectedName = name;

            return self._licenses.createLicense();
        }

        const toggleLicense = (id: string, disabled: boolean) => {
            self._licenses.selectedId = id;
            self._licenses.selectedDisabled = disabled;

            return self._licenses.toggleLicense();
        }
        const changeSearchText = (search: string) => {
            self.searchText = search;
            self.page = 0;
            loadLicenses();
        }
        const saveSearchTextToStorage = () => {
            sessionStorage.setItem(LICENSES_SEARCH_TEXT_KEY, JSON.stringify(self.searchText));
        }
        const search = (searchText: string) => {
            self.searchText = searchText;
            saveSearchTextToStorage();
        }
        const setupSort = (sortFieldName: string, sortDirection: number) => {
            if (self.sortFieldName != sortFieldName || self.sortDirection != sortDirection) {
                self.sortFieldName = sortFieldName;
                self.sortDirection = sortDirection;
                //this to refresh the pagecount also if I'm in the first page already
                self.page = -1;
            }
        }
        const setupPage = (pageIndex: number) => {
            if (self.page != pageIndex) {
                self.page = pageIndex;
                loadLicenses();
            }
        }
        const downloadLicense = (license_key: string, name: string) => {
            const blob = new Blob([license_key]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'license_key.' + name + '.lic';
            link.click();
        }
        const loadStatistics = flow(function* () {
            yield self._licenses.loadStatistics();
        });
        const toggleLicenseModalOpen = (open: boolean) => {
            self.licenseModalOpened = open;
        };

        return {
            loadLicenses,
            createLicense,
            changeSearchText,
            search,
            downloadLicense,
            toggleLicense,
            toggleLicenseModalOpen,
            resetLicense,
            setupPage,
            setupSort,
            loadStatistics,
        }
    })
    .views(self => {

        return {
            get licenses() {
                return self._licenses.licenses;
            },
            get pagination() { return self._licenses.paginationStatus; },
            get isSearchApplied() {
                return !!self.searchText;
            },
            get statistics() { return self._licenses.statistics; },
            get getStorageSearchText() {
                const storedSearchText = sessionStorage.getItem(LICENSES_SEARCH_TEXT_KEY);

                return storedSearchText && JSON.parse(storedSearchText);
            },
        }
    });

type LicensesUIStoreFactoryType = typeof LicensesUIStoreInferred;
interface ILicensesUIStoreFactory extends LicensesUIStoreFactoryType { }
export const LicensesUIStore: ILicensesUIStoreFactory = LicensesUIStoreInferred;
export interface ILicensesUIStore extends Instance<ILicensesUIStoreFactory> { }
export interface ILicensesUIStoreSnapshotIn extends SnapshotIn<ILicensesUIStore> { }
