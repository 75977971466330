import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { ClickOutsideListener } from '@ace/core';
import { getEnv } from 'mobx-state-tree';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { useUserProfileUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import { RoutePaths } from '@core/routes/RoutePaths';
import { Avatar } from '@shared/components/Avatar';
import { DropdownMenu } from '@shared/components/DropdownMenu';
import { OtpModal } from './components/OtpModal';


import WhiteArrowUpIcon from '@assets/white-arrow-up.svg';
import { messages } from '../../header.messages';
import { useStyles } from './UserMenu.styles';
import { ThemeProps } from '@styles/theme';

export const UserMenu = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const userProfileUIStore = useUserProfileUIStore();
    const { navigator } = getEnv<IStoresEnv>(userProfileUIStore);
    const [dropdownOpen, toggleDropdownOpen] = useState(false);

    const signOutUser = async () => {
        await userProfileUIStore.signOut();
        navigator.to(RoutePaths.login);
    };
    const goToProfile = () => {
        toggleDropdownOpen(false);
        navigator.to(RoutePaths.profile);
    };

    const closeOtpModal = () => {
        userProfileUIStore.toggleOtpModalOpened(false);
    };

    const requestOtp = () =>{
        userProfileUIStore.toggleOtpModalOpened(true);
        if (userProfileUIStore.otp == null) {
            userProfileUIStore.requestOtp(null);
        }
    };

    return (
        <section className={styles.wrapper}>
            <span className={styles.userName}>
                {userProfileUIStore.userInfo?.fullName}
            </span>

            <Observer>
                {() => (
                    <OtpModal
                        isOpen={userProfileUIStore.otpModalOpened}
                        onRequestClose={() => closeOtpModal()}
                    />
                )}
            </Observer>

            <Observer>
                {() => (
                    <Avatar
                        firstName={userProfileUIStore.userInfo?.firstName || ''}
                        lastName={userProfileUIStore.userInfo?.lastName || ''}
                        image={userProfileUIStore.userInfo?.avatar}
                    />
                )}
            </Observer>

            <ClickOutsideListener
                outsideClickIgnoreClass={styles.dropdown}
                onClickOutside={() => toggleDropdownOpen(false)}
            >
                <button
                    onClick={() => toggleDropdownOpen(!dropdownOpen)}
                    className={classNames(styles.button, { [styles.rotated]: dropdownOpen })}
                >
                    <img src={WhiteArrowUpIcon} />
                </button>

                <DropdownMenu
                    isOpen={dropdownOpen}
                    className={styles.dropdown}
                    items={[
                        {
                            id: 0,
                            title: messages['userMenu.profile'],
                            onClick: goToProfile,
                        },
                        {
                            id: 1,
                            title: messages['userMenu.requestOtp'],
                            onClick: requestOtp,
                        },
                        {
                            id: 2,
                            title: messages['userMenu.logout'],
                            onClick: signOutUser,
                        }
                    ]}
                />
            </ClickOutsideListener>
        </section>
    );
}
