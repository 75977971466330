import { Instance, SnapshotIn, types, getEnv, flow, applySnapshot } from 'mobx-state-tree';
import { Subject } from 'rxjs';
import _ from 'lodash';

import { IStoresEnv } from '@core/storesEnv';
import {
    performRequestOtp, performRefreshOtp, performValidateOtp
} from '../services/otpManager';


type otpPayload = {
    intent: string;
    clientId: string;
    callbackUrl: string;
}

export type otpResponseType = {
    instructions: string;
    otp: string;
    expiration: Date;
    refreshUrl: string;
    launchUrl: string;
}

export const UserProfileStoreInferred = types
    .model('UserProfileStoreInferred', {
        otp: types.maybeNull(types.frozen<otpResponseType>()),
    })
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);


        const getDefaultOtpPayload = () => {
            return {
                intent: 'login',
                clientId: '',
                callbackUrl: '',
            }
        };

        const requestOtp = flow(function* (payload: otpPayload) {
            const result = yield performRequestOtp(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                },
            );

            if (result.success) {
                self.otp = result.data;
            }
            else {
                self.otp = null;
            }
        });

        const refreshOtp = flow(function* (refreshUrl) {
            const result = yield performRefreshOtp(
                api,
                refreshUrl,
            );

            if (result.success) {
                self.otp = result.data;
            }
            else {
                self.otp = null;
            }
        });

        const validateOtp = flow(function* (otp) {
            const result = yield performValidateOtp(
                api,
                otp,
            );

            if (result.success) {
                return true;
            }
            else {
                return false;
            }
        });

        return {
            getDefaultOtpPayload,
            requestOtp,
            refreshOtp,
            validateOtp,
        }
    })

type UserProfileStoreFactoryType = typeof UserProfileStoreInferred;
interface IUserProfileStoreFactory extends UserProfileStoreFactoryType { }
export const UserProfileStore: IUserProfileStoreFactory = UserProfileStoreInferred;
export interface IUserProfileStore extends Instance<IUserProfileStoreFactory> { }
export interface IUserProfileStoreSnapshotIn extends SnapshotIn<IUserProfileStore> { }
