import React from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useGroupsUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { useStyles } from './GroupsHeading.styles';
import { messages } from 'Groups/groups.messages';
import { ThemeProps } from '@styles/theme';
import { Button } from '@shared/components/Button';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export const GroupsHeading: React.FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const groupsUIStore = useGroupsUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    return (
        <div className={styles.headingWrapper}>
            <div className={styles.titleWrapper}>
                <Typography variant="h6">{messages['groupsHeading.title']}</Typography>

                <Observer>
                {() => (
                    <>
                        {
                            groupsUIStore.isLoading ? (
                                <CircularProgress
                                    className={styles.loader}
                                    color='inherit'
                                    size={24}
                                />
                            )
                            :
                            userPermissionsUIStore.canCreateGroup && (
                                <span title={messages['groupsHeading.create.tooltip']}>
                                    <Button
                                        color='primary'
                                        className={styles.addButton}
                                        onClick={() => groupsUIStore.toggleGroupModalOpen(true)}
                                        startIcon={<GroupAddIcon />}>
                                    </Button>
                                </span>
                            )
                        }
                    </>
                )}
            </Observer>
            </div>
        </div>
    )
};
