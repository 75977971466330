import request from 'superagent';

import { BASE_URL, CLIENT_ID, SECRET, SCOPE, TOKEN_TYPE, GRANT_TYPE } from '@core/api/constants';
import { ApiResponse, ApiError } from '@ace/core';

type LoginParams = {
    email: string;
    password: string;
}

type SwitchCompanyParams = {
    companyId: string,
    email: string,
    password: string;
    token: string,
}

type ForgotPasswordParams = {
    email: string;
}

export type SetPasswordParams = {
    email: string;
    password: string;
    confirmPassword: string;
    token: string;
}

export type VerifyTokenParams = {
    email: string;
    token: string;
    mode?: string;
}

export type AdditionalInfo = {
    deviceIdentifier: string;
    devicePlatform: string;
    applicationVersion: string;
    IP?: string;
    location: Location;
}

export type Location = {
    latitude?: number;
    longitude?: number;
    altitude?: number;
    horizontalAccuracy?: number;
    verticalAccuracy?: number;
    speed?: number;
    course?: number;
    timestamp?: number;
}

const handleError = (error: Error) => {
    const errorWrapped = new ApiError(error);

    return new ApiResponse(null, errorWrapped);
}

export const performLogin = async (params: LoginParams) => {
    try {
        // TODO: Change to additionalInfo
        const additionalInfo: AdditionalInfo | null = null;

        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/login`)
            .send({
                ...params,
                clientId: CLIENT_ID,
                secret: SECRET,
                scope: SCOPE,
                additionalInfo: additionalInfo,
            });

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}

export const performSwitchCompany = async (params: SwitchCompanyParams) => {
    try {
        const { email, companyId, password, token } = params;
        const additionalInfo: AdditionalInfo | null = null;

        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/SwitchCompany/${companyId}`)
            .set('Authorization', token)
            .send({
                email: email,
                password: password,
                clientId: CLIENT_ID,
                secret: SECRET,
                scope: SCOPE,
                additionalInfo: additionalInfo,
            });

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}

export const performLogout = async (params: { token: string }) => {
    try {
        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/logout`)
            .set('Authorization', params.token)
            .send();

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}

export const performForgotPassword = async (params: ForgotPasswordParams) => {
    try {
        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/SendResetPassword`)
            .send(params);

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}

export const performSetPassword = async (params: SetPasswordParams) => {
    try {
        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/ConfirmRegistration`)
            .send(params);

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}

export const performResetPassword = async (params: SetPasswordParams) => {
    try {
        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/ResetPassword`)
            .send(params);

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}

export const performCloseWelcomePopup = async (params: { token: string }) => {
    try {
        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/CloseWelcomeScreen`)
            .set('Authorization', params.token)
            .send();

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}


export const performVerifyToken = async (params: VerifyTokenParams) => {
    try {
        const req = await request
            .post(`${BASE_URL}/identity-app/api/v1/Account/VerifyToken`)
            .send(params);

        return new ApiResponse(req);
    } catch (error: any) {
        return handleError(error);
    }
}

export const performRefreshToken = (refreshToken: string) => {
    const reqBody = {
        'client_id': CLIENT_ID,
        'client_secret': SECRET,
        'token_type': TOKEN_TYPE,
        'grant_type': GRANT_TYPE,
        'scope': SCOPE,
        'refresh_token': refreshToken,
    }

    const req = request
        .post(`${BASE_URL}/identity-app/connect/token`)
        .type('form')
        .send(reqBody);

    return req;
}
