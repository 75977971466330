import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    disabled: {},
    appExternal: {},
    secondary: {},
    button: {
        border: '1px solid transparent',
        borderRadius: theme.radius,
        fontFamily: theme.font.secondary,
        textTransform: 'none',
        fontSize: 16,
        lineHeight: 1.4,
        transition: '0.3s',
        height: 40,
        padding: `0 ${theme.spacing(5)}`,
        backgroundColor: theme.colors.violet_primary,
        color: theme.colors.white,
        position: 'relative',

        '&:focus': {
            boxShadow: `0 0 8px 0px ${color(theme.colors.violet_darker).fade(0.76)}`,
            border: `1px solid ${theme.colors.violet_primary}`,
            outline: 'none',
        },

        '&$disabled': {
            backgroundColor: theme.colors.transp_violet,
            pointerEvents: 'none',
        },

        '&$primary' : {
            '&$disabled': {
                backgroundColor: theme.colors.grey_text,
            },
        },

        '&:hover': {
            backgroundColor: theme.colors.violet_darker,
            cursor: 'pointer',
        },

        '&:active': {
            background: theme.colors.violet_darkest,
            boxShadow: 'none',
            '&:focus': {
                boxShadow: 'none',
                border: '1px solid transparent',
            },
        },

        '&$secondary': {
            color: theme.colors.violet_primary,
            backgroundColor: theme.colors.transp_violet,

            '&$disabled': {
                color: theme.colors.grey_text,
                backgroundColor: `${color(theme.colors.transp_violet).fade(0.84)}`,
                pointerEvents: 'none',
            },

            '&:hover': {
                backgroundColor: `${color(theme.colors.violet_darker).fade(0.84)}`,
                color: theme.colors.violet_darker,
                cursor: 'pointer',
            },

            '&:active': {
                background: `${color(theme.colors.violet_darker).fade(0.76)}`,
                color: theme.colors.violet_darker,
                boxShadow: 'none',
            },
        },

        '&$appExternal': {
            background: theme.colors.blue_primary,
            color: theme.colors.black,
            height: 52,
            padding: '6 34',

            '&$disabled': {
                color: theme.colors.white,
                opacity: .2,
                pointerEvents: 'none',
            },

            '&:hover': {
                background: theme.colors.blue_neon,
                cursor: 'pointer',
            },

            '&:active': {
                background: theme.colors.blue_neon_luminating,
                // boxShadow: 'none',
            },
            '&:focus': {
            boxShadow: `0 0 8px 0px ${color(theme.colors.blue_neon_luminating).fade(0.76)}`,
            border: `1px solid ${theme.colors.blue_neon}`,
            outline: 'none',
        },
        },
    },

    loaderWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 24,
    },

    loader: {
        color: theme.colors.violet_darkest,
        margin: '2px 4px',
    },
}));
