import React from 'react';
import classNames from 'classnames';
import { useTheme } from '@mui/material/styles';

import { useStyles } from './SidebarFilterButton.styles';
import { ThemeProps } from '@styles/theme';
import { ButtonOwnProps } from '@mui/material';

export type SidebarFilterButtonProps = {
    onClick: () => void;
    children: React.ReactNode;
    active?: boolean;
    className?: string;
}

// TODO try to customize Button component instead of this
export const SidebarFilterButton: React.FC<SidebarFilterButtonProps & ButtonOwnProps> = ({ onClick, children, active, className, ...restOfProps }) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    return (
        <button
            onClick={onClick}
            type="button"
            className={
                classNames(styles.wrapper, className, { [styles.active]: active })
            }
            data-testid="sidebar-filter-button"
            { ...restOfProps }
        >
            {children}
        </button>
    )
};

SidebarFilterButton.displayName = 'SidebarFilterButton';
