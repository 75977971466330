import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    headingWrapper: {
        ...flex('row', 'space-between', 'center'),
        marginBottom: theme.spacing(6),
    },

    titleWrapper: {
        ...flex('row', '', 'center'),
        flexGrow: 1,
        justifyContent: 'space-between',
    },

    addButton: {
        width: theme.spacing(12),
        minWidth: 'auto',
        height: 36,
        paddingRight: theme.spacing(2),
        margin: 0,
        marginLeft: theme.spacing(4),
        right: 0,
    },

    loader: {
        color: theme.colors.violet_primary,
    },
}));
