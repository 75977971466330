import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export const performGetProceduresStatistics = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { payload, ...rest } = params;
    const req = api
        .get('uiaggregator-app/api/v1/Procedures/Statistics', {
            ...rest,
            payload,
        })

    return req;
}
