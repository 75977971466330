import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { useTheme } from '@mui/material/styles';

export interface LinkProps {
    to: string;
    disabled?: boolean;
    children: React.ReactNode;
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    disabled: {},
    link: {
        color: theme.colors.blue_primary,
        fontFamily: theme.font.secondary,
        fontSize: 16,
        lineHeight: 1.4,
        textDecoration: 'none',
        padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
        margin: `0, -${theme.spacing(1)}`,
        border: '1px solid transparent',
        borderRadius: theme.radius,
        transition: '0.3s',

        '&$disabled': {
            color: theme.colors.white,
            opacity: .3,
            pointerEvents: 'none',
        },

        '&:hover': {
            color: theme.colors.blue_neon,
            textDecoration: 'underline',
            cursor: 'pointer',
        },

        '&:focus': {
            boxShadow: `0 0 4px 0 ${theme.colors.blue_neon}`,
            borderColor: theme.colors.blue_primary,
            outline: 'none',
        },

        '&:active': {
            color: theme.colors.blue_neon_luminating,
            textDecoration: 'underline',
            boxShadow: 'none',
        },
    },
}))

// TODO silimar as NavLink, should be refactored
export const Link: React.FC<LinkProps> = ({ to, children, disabled }) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    return (
        <RouterLink
            to={to}
            className={
                classNames(styles.link, {
                    [styles.disabled]: disabled,
                })
            }
        >
            {children}
        </RouterLink>
    )
};

Link.displayName = 'Link';
