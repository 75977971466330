import { Instance, SnapshotIn, types, flow, getEnv, cast } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { UsersStatisticsStore } from '@shared/stores';
import { UserStore } from './User.store';
import { PageQueryStore } from './PageQuery.store';
import { PaginationStatusStore } from './PaginationStatus.store';
import { InviteUserStore } from './InviteUser.store';

import { performGetUsers, performGetUsersStatistics } from 'Users/services';
import { ExportUserParams, performExportAllUsers } from 'Users/services/exportUser';
import { ImportUsersParams, performImportUsers } from 'Users/services/importUsers';

export const UsersGridStoreInferred = types
    .model('UsersGridStoreInferred', {
        usersList: types.array(UserStore),
        requestParams: types.optional(PageQueryStore, {}),
        paginationStatus: types.optional(PaginationStatusStore, {}),
        inviteUser: types.optional(InviteUserStore, {}),
        statistics: types.optional(UsersStatisticsStore, {}),
    })
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);

        const loadUsers = flow(function* () {
            const result = yield performGetUsers(
                api,
                {
                    payload: { ...self.requestParams.getParams },
                    errorHandlers: { DEFAULT: '' },
                },
            );

            self.usersList = result.data.entities;
            self.paginationStatus = result.data.meta;
        });

        const loadStatistics = flow(function* () {
            const { inGroup, inGroupId: groupId } = self.requestParams.getParams;
            const result = yield performGetUsersStatistics(
                api,
                {
                    payload: { inGroup, groupId },
                    errorHandlers: { DEFAULT: '' },
                },
            );

            self.statistics = result.data;
        });

        const cleanData = () => {
            self.usersList = cast([]);
            self.paginationStatus = cast({});
        };

        const exportAllUsers = flow(function* (payload: ExportUserParams, companyId: string) {
            const result = yield performExportAllUsers(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (!(result.data instanceof Blob)) { return; }

            const filename = 'Users' + (payload.groupId ? `_in_${payload.groupId}` : '') + '_of_' + companyId + (payload.extendedData ? '_ex' : '') + '.csv';
            const blob = new Blob([result.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });

        const importUsers = flow(function* (payload: ImportUsersParams) {
            const result = yield performImportUsers(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });


        return {
            loadUsers,
            loadStatistics,
            cleanData,
            exportAllUsers,
            importUsers,
        }
    })

type UsersGridStoreFactoryType = typeof UsersGridStoreInferred;
interface IUsersGridStoreFactory extends UsersGridStoreFactoryType { }
export const UsersGridStore: IUsersGridStoreFactory = UsersGridStoreInferred;
export interface IUsersGridStore extends Instance<IUsersGridStoreFactory> { }
export interface IUsersGridStoreSnapshotIn extends SnapshotIn<IUsersGridStore> { }
