import React, { FC } from 'react';
import Select from 'react-select';
import { FieldProps } from 'formik';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import { InputLabel } from '../forms/inputs/InputLabel';

import { useStyles, customStyles } from './SelectDropdown.styles';
import { ThemeProps } from '@styles/theme';

const ENTER_KEYCODE = "Enter";

export type SelectDropdownProps = {
    className?: string,
    options: Array<object>,
    components?: object,
    closeMenuOnSelect?: boolean,
    hideSelectedOptions?: boolean,
    isMulti?: boolean,
    onChange?: (value: OptionType, fieldName: string) => void,
    label?: string;
    fieldName: string;
    placeholder?: string;
    isRequired?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    disabled?: boolean;
    error?: string;
}

export type OptionType = {
    value: string,
    label: string,
    type?: string
}

export const SelectDropdown: FC<SelectDropdownProps & FieldProps> = ({
    className,
    label,
    fieldName,
    isRequired,
    meta,
    disabled,
    form,
    field,
    ...props
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const handleChange = (value: any) => {
        props.onChange && props.onChange(value, fieldName);
        form.setFieldValue(fieldName, value);
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.code === ENTER_KEYCODE) {
            e.preventDefault();
            form.submitForm();
        }
    }

    const handleBlur = () =>
        form.setFieldTouched(fieldName, true);

    const filterOption = (option: OptionType, inputValue: string) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase());

    return (
        <div className={styles.wrapper}>
            {
                label && (
                    <InputLabel
                        label={label}
                        isRequired={isRequired}
                        className={classNames(
                            { [styles.labelWithError]: meta.touched && meta.error }
                        )}
                    />
                )
            }
            <Select
                className={classNames(styles.select, className)}
                isDisabled={disabled}
                // getOptionLabel={option => option.name}
                {...props}
                onChange={handleChange}
                onBlur={handleBlur}
                styles={customStyles}
                value={field.value}
                filterOption={filterOption}
                onKeyDown={onKeyDown}
                components={{
                    ...props.components,
                    MultiValueRemove: ({ data, innerProps: { className, ...rest } }) => !data.disabled ? (
                        <div className={styles.valueRemove} {...rest}>
                            <CloseIcon className={styles.valueRemoveIcon} />
                        </div>
                    ) : <div>&nbsp;</div>,
                }}
            />
        </div>
    )
}
