import { ISelectOption } from '@shared/helpers/form/ISelectOption';

export type ProcedureFormValues = {
  name: string,
  description: string,
  groups: ISelectOption[] | null,
  users: ISelectOption[] | null,
  ownership: ISelectOption,
};

export class FormValues {
  name = '';
  description = '';
  groups = [];
  users = [];
  ownership = {} as ISelectOption;
}
