import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    expanded: {},
    notActive: {},
    body: {
        display: 'flex',
        padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(10)}`,
    },

    header: {
        color: theme.colors.grey_text,
        fontSize: 16,
        fontFamily: theme.font.secondary,
        lineHeight: 1.25,
        paddingBottom: 10,
    },

    descriptionWrapper: {
        padding: `${0}px ${theme.spacing(2)}`,
        flex: '18 0 18%',
        maxWidth: 300,
    },

    devicesWrapper: {
        padding: `${0}px ${theme.spacing(2)}`,
        flex: '15 0 15%',
        maxWidth: 150,
    },

    accessWrapper: {
        width: 200,
        padding: `${0}px ${theme.spacing(2)}`,
    },

    historyWrapper: {
        padding: `${0}px ${theme.spacing(2)}`,
    },

    text: {
        color: theme.colors.black,
        lineHeight: 1.5,
        fontSize: 16,
        fontFamily: theme.font.primary,
    },

    modesContainer: {
        ...flex('row', 'center', 'flex-start'),
    },

    mode: {
        fontSize: 18,
        backgroundColor: theme.colors.transp_violet,
        fontStyle: 'bold',
        padding: '6px 12px',
        marginRight: 8,
        borderRadius: theme.radius,
        fontFamily: theme.font.secondary,

        '&.MuiTypography-root': {
            // color: theme.colors.white,
            fontStyle: 'bold',
        }
    },

    expandIcon: {
        transformOrigin: 'center',
        transition: 'transform .2s ease',
        transform: 'rotate(90deg)',

        '&$expanded': {
            transform: 'rotate(-90deg)',
        },
    },

    button: {
        border: 'none',
        background: 'none',
        outline: 'none',
        padding: 0,
        height: 24,
        width: 24,

        '&:disabled': {
            opacity: .3,
        },

        '&:hover': {
            background: theme.colors.icon_background,
            borderRadius: theme.radius / 2,
            cursor: 'pointer',
        },
    },

    tableActions: {
        ...flex('row', 'space-between', 'center'),
        padding: `${0}px ${theme.spacing(4)}`,
        marginBottom: theme.spacing(4),
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
    },
    invite: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    h4: {
        fontFamily: theme.font.secondary,
        fontSize: 20,
        lineHeight: 1.4,
        fontWeight: 'normal',
        color: theme.colors.black,
    },
    addIcon: {
        color: theme.colors.violet_primary,
        cursor: 'pointer',
        marginLeft: theme.spacing(3),
    },

    filterIcon: {
        color: theme.colors.violet_primary,
        cursor: 'pointer',
    },

    searchWrapper: {
        width: 312,
        marginRight: theme.spacing(4),
    },

    moreItemsPresent: {
        color: theme.colors.blue_link,
        marginLeft: theme.spacing(2),
        fontFamily: theme.font.secondary,
    },

    noInfo: {
        color: theme.colors.grey_text,
    },

    counter: {
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        borderRadius: theme.radius,
        color: theme.colors.violet_primary,
        backgroundColor: `${color(theme.colors.violet_primary).fade(0.92)}`,
    },

    capitalized: {
        textTransform: 'capitalize',
    },

    nameWrapper: {
        ...flex('row', 'center', 'center'),
    },

    procedureImage: {
        width: 40,
        height: 40,
        objectFit: 'cover',
        marginRight: theme.spacing(3),
        borderRadius: theme.radius,
        backgroundColor: theme.colors.grey_light,
        ...flex('row', 'center', 'center'),
        flexShrink: 0,
    },

    procedureName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    exportButton: {
        marginLeft: 10,
    },
}));
