import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export type ExportUserParams = {
    id: string;
    groupId: string;
    extendedData: boolean;
}

export const performExportAllUsers = async (api: IApiStore, params: EndpointCallParams<ExportUserParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .getBlob('export-app/api/v1/Users/all', {
            ...rest,
            payload,
        });

    return req;
}

export const performExportUser = async (api: IApiStore, params: EndpointCallParams<ExportUserParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .getBlob(`export-app/api/v1/Users/${payload.id}?extendedData=${payload.extendedData}`, {
            ...params,
        });

    return req;
}