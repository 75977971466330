import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';


export const useStyles = createUseStyles((theme: ThemeProps) => ({
    panelWrapper: {
        borderTop: `1px solid ${theme.colors.grey_light}`,
        paddingTop: theme.spacing(4),
    },

    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(6.5),
    },

    searchPanel: {
        flexGrow: 1,
        paddingRight: theme.spacing(4),
        // marginBottom: theme.spacing(6.5),
    },

    buttonExport: {
        width: theme.spacing(12),
        minWidth: 'auto',
        height: 36,
        paddingRight: theme.spacing(2),
        margin: 0,
    },

    emptyListText: {
        color: theme.colors.grey_text,
        fontSize: 16,
        lineHeight: 1.5,
    },

    addNewGroupButton: {
        marginTop: theme.spacing(6),
    },

    menuItem: {
        marginBottom: theme.spacing(2),
    },

    loader: {
        color: theme.colors.violet_primary,
        marginRight: 4,
    },
}));
