import { FormSchema } from '@shared/components/forms/FormSchema';

import { FormValues } from './FormValues';
import { messages } from 'AuthPart/authpart.messages';

type LoginFormSchema = FormSchema<FormValues>;

export const valuesSchema: LoginFormSchema = {
    email: {
        fieldName: 'email',
        label: messages['loginForm.email.label'],
        isRequired: true,
        type: 'email',
    },
    password: {
        fieldName: 'password',
        label: messages['loginForm.password.label'],
        isRequired: true,
        type: 'password',
    },
};
