import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

type GetLicensesStatisticsParams = {
}

export const performGetLicensesStatistics = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { payload, ...rest } = params;
    const req = api
        .get('uiaggregator-app/api/v1/Licenses/Statistics', {
            ...rest,
            payload: { ...payload },
        })

    return req;
}
