import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

type PageParams = {
  limit: number,
  page: number,
}

type SearchParams = {
  searchText: string,
}



export type GetDownloadablesParams = PageParams & SearchParams;

export const performGetDownloadables = async (api: IApiStore, params: EndpointCallParams<GetDownloadablesParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .post('uiaggregator-app/api/v1/Downloadables/Search', {
      ...rest,
      payload,
    })

  return req;
}
