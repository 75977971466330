import { IApiStore } from '@core/api/apiStore';
import { EndpointCallParams } from '@core/api/endpointCallParams';

type CreateLicenseParams = {
    UnityId: string;
    Name: string;
}

export const performCreateLicense = async (api: IApiStore, params: EndpointCallParams<CreateLicenseParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .post('identity-app/api/v1/License/Create', {
            ...rest,
            payload,
        })

    return req;
}
