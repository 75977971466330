import { Instance, SnapshotIn, types, flow, getEnv, cast } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { PageQueryStore } from './PageQuery.store';
import { ListProcedureStore } from './ListProcedure.store';
import { PaginationStatusStore } from 'Users/stores/PaginationStatus.store';
import { ProceduresStatisticsStore } from './ProceduresStatistics.store';
import { performGetProcedures, performGetProceduresStatistics } from '../services';
import { ProceduresFiltersOptionsStore } from './ProceduresFiltersOptions.store';
import { ExportProcedureParams, performExportAllProcedures } from 'Procedures/services/exportProcedure';

export const ProceduresGridStoreInferred = types
    .model('ProceduresGridStoreInferred', {
        proceduresList: types.array(ListProcedureStore),
        requestParams: types.optional(PageQueryStore, {}),
        paginationStatus: types.optional(PaginationStatusStore, {}),
        statistics: types.optional(ProceduresStatisticsStore, {}),
        proceduresFilterOptions: types.optional(ProceduresFiltersOptionsStore, {}),
    })
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);

        const loadProcedures = flow(function* () {
            const result = yield performGetProcedures(
                api,
                {
                    payload: { ...self.requestParams.getParams },
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (result.success) {
                self.proceduresList = result.data.entities;
                self.paginationStatus = result.data.meta;
            }
        });

        const loadStatistics = flow(function* () {
            const { inGroup, inGroupId: groupId, procedureAvailability } = self.requestParams.getParams;
            const result = yield performGetProceduresStatistics(
                api,
                {
                    payload: { inGroup, groupId, procedureAvailability },
                    errorHandlers: { DEFAULT: '' },
                },
            );

            self.statistics = result.data;
        });

        const cleanData = () => {
            self.proceduresList = cast([]);
            self.paginationStatus = cast({});
        };

        const exportAllProcedures = flow(function* (payload: ExportProcedureParams, companyId: string) {
            const result = yield performExportAllProcedures(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (!(result.data instanceof Blob)) { return; }

            const filename = 'Procedures' + (payload.groupId ? `_in_${payload.groupId}` : '')
                + '_of_' + companyId + (payload.extendedData ? '_ex' : '') + '.csv';
            const blob = new Blob([result.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });

        return {
            loadProcedures,
            loadStatistics,
            cleanData,
            exportAllProcedures,
        }
    })

type ProceduresGridStoreFactoryType = typeof ProceduresGridStoreInferred;
interface IProceduresGridStoreFactory extends ProceduresGridStoreFactoryType { }
export const ProceduresGridStore: IProceduresGridStoreFactory = ProceduresGridStoreInferred;
export interface IProceduresGridStore extends Instance<IProceduresGridStoreFactory> { }
export interface IProceduresGridStoreSnapshotIn extends SnapshotIn<IProceduresGridStore> { }
