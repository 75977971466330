import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const LicenseStoreInferred = types.model('LicenseStore', {
    id: types.identifier,
    name: types.optional(types.string, ''),
    unityId: types.optional(types.string, ''),
    key: types.optional(types.string, ''),
    expireDate: types.maybeNull(types.string),
    isCreator: types.optional(types.boolean, false),
    isPlayer: types.optional(types.boolean, false),
    isDisabled: types.optional(types.boolean, false),
    usedIn: types.optional(types.string, ''),
    lastUser: types.optional(types.string, ''),
    lastLogin: types.maybeNull(types.string),
    allUser: types.optional(types.string, ''),
});

type LicenseStoreFactoryType = typeof LicenseStoreInferred;
interface ILicenseStoreFactory extends LicenseStoreFactoryType { }
export const LicenseStore: ILicenseStoreFactory = LicenseStoreInferred;
export interface ILicenseStore extends Instance<ILicenseStoreFactory> { }
export interface ILicenseStoreSnapshotIn extends SnapshotIn<ILicenseStore> { }
