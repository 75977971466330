import React from 'react';
import classNames from 'classnames';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { useTheme } from '@mui/material/styles';

export interface NavLinkProps {
    to: string;
    children: React.ReactNode;
    onClick?: () => void;
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    wrapper: {
        color: theme.colors.blue_primary,
        fontFamily: theme.font.secondary,
        fontSize: 20,
        lineHeight: 1.4,
        textDecoration: 'none',
        padding: `6px ${theme.spacing(5)}`,
        border: '1px solid transparent',
        borderRadius: theme.radius,
        transition: '0.3s',

        '&:focus': {
            boxShadow: `0 0 4px 0 ${theme.colors.blue_neon}`,
            borderColor: theme.colors.blue_primary,
            outline: 'none',
        },

        '&:active': {
            color: theme.colors.blue_neon_luminating,
            boxShadow: 'none',
        },

        ['&.active, &:hover']: {
            color: theme.colors.blue_neon,
            cursor: 'pointer',
            backgroundColor: 'rgba(9, 237, 245, 0.12)',
            boxShadow: 'none',
            borderColor: 'transparent',
        },
    },
}))

// TODO similar as Link, should be refactored
export const NavLink: React.FC<NavLinkProps> = ({ to, children, onClick }) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    return (
        <RouterNavLink
            to={to}
            onClick={onClick}
            className={
                classNames(styles.wrapper)
            }
        >
            {children}
        </RouterNavLink>
    )
};

NavLink.displayName = 'NavLink';
