import { Instance, SnapshotIn, types, flow } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { CompaniesGridStore } from './CompaniesGrid.store';
import { GetCompaniesParams } from 'Companies/services';

export const CompaniesGridUIStoreInferred = types
    .model('CompaniesGridUIStoreInferred', {
        _companies: types.optional(CompaniesGridStore, {}),
        isActivePage: types.optional(types.boolean, false),
        searchText: types.optional(types.string, ''),
        page: types.optional(types.integer, 0),
        sortFieldName: types.optional(types.string, ''),
        sortDirection: types.optional(types.integer, 0),
    })
    .volatile(() => ({
        status: DataStateStore.create({ state: DataState.initial }),
    }))
    .views(self => ({
        get pagination() { return self._companies.paginationStatus; },
        get companies() { return self._companies.companiesList; },
        get createCompany() { return self._companies.createCompany; },
    }))
    .views(self => ({
        get isTableVisible() { return self.companies.length || self.status.isLoading; },
    }))
    .actions(self => {
        const load = flow(function* () {
            const payload: GetCompaniesParams = {
                limit: 10,
                page: self.page,
                searchText: self.searchText,
                sortFieldName: self.sortFieldName,
                sortDirection: self.sortDirection,
            }
            self.status.setLoading();
            yield self._companies.loadCompanies(payload);
            self.status.setDone();
        });

        const togglePageActivity = (isActive: boolean) => self.isActivePage = isActive;

        const cleanUp = () => {
            self._companies.cleanData();
        }
        const changeSearchText = (search: string) => {
            self.searchText = search;
            self.page = 0;
            load();
        }
        const setupSort = (sortFieldName: string, sortDirection: number) => {
            if (self.sortFieldName != sortFieldName || self.sortDirection != sortDirection) {
                self.sortFieldName = sortFieldName;
                self.sortDirection = sortDirection;
                //this to refresh the pagecount also if I'm in the first page already
                self.page = -1;
            }
        }
        const setupPage = (pageIndex: number) => {
            if (self.page != pageIndex) {
                self.page = pageIndex;
                load();
            }
        }

        return {
            load,
            togglePageActivity,
            cleanUp,
            changeSearchText,
            setupPage,
            setupSort,
        }
    })

type CompaniesGridUIStoreFactoryType = typeof CompaniesGridUIStoreInferred;
interface ICompaniesGridUIStoreFactory extends CompaniesGridUIStoreFactoryType { }
export const CompaniesGridUIStore: ICompaniesGridUIStoreFactory = CompaniesGridUIStoreInferred;
export interface ICompaniesGridUIStore extends Instance<ICompaniesGridUIStoreFactory> { }
export interface ICompaniesGridUIStoreSnapshotIn extends SnapshotIn<ICompaniesGridUIStore> { }
