import { Instance, SnapshotIn, types, getSnapshot } from 'mobx-state-tree';

import { BasePageQueryStore } from '@shared/stores/BasePageQuery.store';

export const USERS_FILTERS_KEY = 'users_filters';

export const PageQueryStoreInferred = types.compose(BasePageQueryStore, types
    .model({
        departments: types.array(types.string),
        roleIds: types.array(types.string),
        statusIds: types.array(types.string),
        groupIds: types.array(types.string),
        procedureIds: types.array(types.string),
        lastLoginStart: types.maybe(types.string),
        lastLoginEnd: types.maybe(types.string),
    }))
    .volatile(() => ({
        _FILTER_KEY: USERS_FILTERS_KEY,
    }))
    .views(self => ({
        get getParams() {
            return getSnapshot(self);
        },
    }))
    .actions(self => ({
        setParams(params: IPageQueryStoreSnapshotIn) {
            self._setParams<IPageQueryStoreSnapshotIn>(params);
        },
    }))
    .actions(self => ({
        _prefillFromStorage() {
            if (self.getStorageParams) {
                self.setParams(self.getStorageParams);
            }
        },
    }))
    .actions(self => ({
        resetParams() {
            const FILTERS_TO_KEEP = {
                inGroup: self.getParams.inGroup,
                inGroupId: self.getParams.inGroupId,
            }
            self._resetParams(FILTERS_TO_KEEP);
        },
    }));

type PageQueryStoreFactoryType = typeof PageQueryStoreInferred;
interface IPageQueryStoreFactory extends PageQueryStoreFactoryType { }
export const PageQueryStore: IPageQueryStoreFactory = PageQueryStoreInferred;
export interface IPageQueryStore extends Instance<IPageQueryStoreFactory> { }
export interface IPageQueryStoreSnapshotIn extends SnapshotIn<IPageQueryStore> { }
