import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const PaginationStatusStoreInferred = types
    .model({
        currentPage: types.optional(types.number, 0),
        limit: types.optional(types.number, 12),
        isFirstPage: types.optional(types.boolean, true),
        isLastPage: types.optional(types.boolean, false),
        totalEntities: types.optional(types.number, 0),
        totalPages: types.optional(types.number, 0),
    })

type PaginationStatusStoreFactoryType = typeof PaginationStatusStoreInferred;
interface IPaginationStatusStoreFactory extends PaginationStatusStoreFactoryType { }
export const PaginationStatusStore: IPaginationStatusStoreFactory = PaginationStatusStoreInferred;
export interface IPaginationStatusStore extends Instance<IPaginationStatusStoreFactory> { }
export interface IPaginationStatusStoreSnapshotIn extends SnapshotIn<IPaginationStatusStore> { }
