import { Instance, SnapshotIn, types, flow, onSnapshot, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { IStoresEnv } from '@core/storesEnv';
import { ProceduresGridStore } from './ProceduresGrid.store';
import { IGroupsDataStoreSnaphotOut, GroupUpdate$ } from 'Groups/store';
import { PROCEDURES_IN_GROUP_ID, PROCEDURES_AVAILABILITY_ALL, ALL_PROCEDURES_ID } from 'Procedures/domain/constants';
import { IPageQueryStoreSnapshotIn } from './PageQuery.store';

export const ProceduresGridUIStoreInferred = types
    .model('ProceduresGridUIStoreInferred', {
        _procedures: types.optional(ProceduresGridStore, {}),
        isActivePage: types.optional(types.boolean, false),
        isExporting: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        status: DataStateStore.create({ state: DataState.initial }),
    }))
    .views(self => ({
        get procedures() { return self._procedures.proceduresList; },
        get pagination() { return self._procedures.paginationStatus; },
        get requestParams() { return self._procedures.requestParams; },
        get statistics() { return self._procedures.statistics; },
        get isFilterOrSearchApplied() { return self._procedures.requestParams.areSearchParamsSet; },
        get proceduresFilters() { return self._procedures.proceduresFilterOptions; },
    }))
    .views(self => ({
        get canExport() { return self.procedures?.length; },
        get isTableVisible() { return self.procedures.length || self.status.isLoading; },
        get noFilteringResults() { return !self.procedures.length && !self.status.isLoading && self.isFilterOrSearchApplied },
        get noItemsInGroup() { return !self.procedures.length && !self.status.isLoading && !self.isFilterOrSearchApplied },
    }))
    .actions(self => {
        const load = flow(function* () {
            self.status.setLoading();
            yield self._procedures.loadProcedures();
            self.status.setDone();
        });

        const loadStatistics = flow(function* () {
            yield self._procedures.loadStatistics();
        });

        const setParams = (params: IPageQueryStoreSnapshotIn) => {
            self.requestParams.setParams(params);
            self.requestParams.saveParamsToStorage();
        }

        const resetParams = () => self.requestParams.resetParams();

        const togglePageActivity = (isActive: boolean) => self.isActivePage = isActive;

        const cleanUp = () => {
            resetParams();
            self._procedures.cleanData();
        }

        const exportAllProcedures = flow(function* (payload, companyId) {
            self.isExporting = true;
            yield self._procedures.exportAllProcedures(payload, companyId);
            self.isExporting = false;
        });

        return {
            load,
            setParams,
            resetParams,
            loadStatistics,
            togglePageActivity,
            cleanUp,
            exportAllProcedures,
        }
    })
    .actions(self => {
        const { groups } = getEnv<IStoresEnv>(self);

        return {
            afterCreate: () => {
                GroupUpdate$.subscribe(() => {
                    if (self.isActivePage) {
                        self.load();
                        self.loadStatistics();
                    }
                });

                onSnapshot(self.requestParams, () => {
                    if (self.isActivePage) {
                        self.load();
                        self.loadStatistics();
                    }

                    self.requestParams.saveParamsToStorage();
                });

                onSnapshot(groups, (snap: IGroupsDataStoreSnaphotOut) => {
                    if (snap.selectedGroup) {
                        self.setParams({
                            page: 0,
                            inGroup: PROCEDURES_IN_GROUP_ID,
                            inGroupId: snap.selectedGroup as string,
                            procedureAvailability: PROCEDURES_AVAILABILITY_ALL,
                        });
                    }

                    // Show 0 page of 'All procedures' after group removing
                    if (self.requestParams.inGroup === PROCEDURES_IN_GROUP_ID && !snap.selectedGroup) {
                        self.setParams({
                            page: 0,
                            inGroup: ALL_PROCEDURES_ID,
                            inGroupId: undefined,
                            procedureAvailability: PROCEDURES_AVAILABILITY_ALL,
                        });
                    }
                })
            },
        }
    })

type ProceduresGridUIStoreFactoryType = typeof ProceduresGridUIStoreInferred;
interface IProceduresGridUIStoreFactory extends ProceduresGridUIStoreFactoryType { }
export const ProceduresGridUIStore: IProceduresGridUIStoreFactory = ProceduresGridUIStoreInferred;
export interface IProceduresGridUIStore extends Instance<IProceduresGridUIStoreFactory> { }
export interface IProceduresGridUIStoreSnapshotIn extends SnapshotIn<IProceduresGridUIStore> { }
