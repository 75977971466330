import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

type PageParams = {
  limit: number,
  page: number,
}

type SearchParams = {
  searchText: string,
}

type GroupParams = {
  inGroup: number | null,
  inGroupId: string,
}

export type GetUserAnalyticParams = {
  id: string;
};

type GetAnalyticParams = PageParams & SearchParams & GroupParams;

export const performGetAnalytic = async (api: IApiStore, params: EndpointCallParams<GetAnalyticParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .post('uiaggregator-app/api/v1/Analytic/Search', {
      ...rest,
      payload: { ...payload },
    })

  return req;
}

export const performGetUserAnalytic = async (api: IApiStore, params: EndpointCallParams<GetUserAnalyticParams>) => {
  const { payload, ...rest } = params;

  const req = api
    .get(`uiaggregator-app/api/v1/Analytic/Statistics/${payload.id}`, {
      payload: '',
      ...rest,
    })

  return req;
}
