import { ISelectOption } from '@shared/helpers/form/ISelectOption';

export type GroupFormValues = {
  name: string,
  users: ISelectOption[],
  procedures: ISelectOption[],
};

export class FormValues {
  name: string = '';
  users: ISelectOption[] = [];
  procedures: ISelectOption[] = [];
}
