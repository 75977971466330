import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { ThemeProps } from '@styles/theme';
import { useTheme } from '@mui/material/styles';

type ToastMessageProps = {
    message: React.ReactNode,
    // TODO move to enum
    type: 'error' | 'success'
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    container: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    icon: {
        marginRight: theme.spacing(4),
    },
}))

export const ToastMessage: FC<ToastMessageProps> = ({
    message,
    type,
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const MESSAGE_ICONS: Record<string, any> = {
        error: <ErrorOutlineOutlinedIcon className={styles.icon} style={{ fontSize: 24 }} />,
        success: <CheckOutlinedIcon className={styles.icon} style={{ fontSize: 24 }} />,
    }

    return (
        <div className={styles.container}>
            {MESSAGE_ICONS[type] || <ErrorOutlineOutlinedIcon className={styles.icon} />}
            <div data-testid="toast-message">
                {message}
            </div>
        </div>
    )
}
