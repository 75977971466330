import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { Permission } from '@core/domain/Permission';

const PermissionRule = types.enumeration<Permission>('Permission', Object.values(Permission));

const FieldsStore = types.model('FieldsStore', {
    allow: types.array(types.string),
    denied: types.array(types.string),
    mine: types.array(types.string),
    entries: types.frozen(),
});

const OperationsStore = types.model('Operations', {
    canCreate: PermissionRule,
    canDelete: PermissionRule,
    canRead: PermissionRule,
    canUpdate: PermissionRule,
});

const PagePermissions = types.model('PagePermissions', {
    fields: types.maybeNull(FieldsStore),
    operations: types.maybeNull(OperationsStore),
});

const ViewTabsStore = types.model('ViewTabs', {
    analytics: PermissionRule,
    collaborations: PermissionRule,
    groups: PermissionRule,
    procedures: PermissionRule,
    users: PermissionRule,
    companies: PermissionRule,
    licenses: PermissionRule,
    download: PermissionRule,
});

export const UserPermissionsInferred = types.model('UserPermissions', {
    analytic: PagePermissions,
    collaboration: PagePermissions,
    group: PagePermissions,
    procedure: PagePermissions,
    company: PagePermissions,
    license: PagePermissions,
    download: PagePermissions,
    user: PagePermissions,
    viewTabs: ViewTabsStore,
});

type UserPermissionsFactoryType = typeof UserPermissionsInferred;
interface IUserPermissionsFactory extends UserPermissionsFactoryType { }
export const UserPermissions: IUserPermissionsFactory = UserPermissionsInferred;
export interface IUserPermissions extends Instance<IUserPermissionsFactory> { }
export interface IUserPermissionsSnapshotIn extends SnapshotIn<IUserPermissions> { }
