import * as Yup from 'yup';

import { FormValues } from './FormValues';
import { valuesSchema } from './valuesSchema';
import { messages } from 'AuthPart/authpart.messages';

export const errors = {
    required: messages['setPasswordForm.error.required'],
    notSame: messages['setPasswordForm.error.notSame'],
    min: messages['setPasswordForm.error.min'],
    otherRequirements: messages['setPasswordForm.error.otherRequirements'],
    notContainsUsername: messages['setPasswordForm.error.notContainsUsername'],
};

export const createValidationSchema = (email: string | null) => {
    const username = (!!email && email.split('@')[0]) as string;

    return Yup.object({
        password: Yup.string()
            .required(errors.required(valuesSchema.password.label))
            .min(8, errors.min(8))
            .matches(/[a-z]+/, errors.otherRequirements)
            .matches(/[A-Z]+/, errors.otherRequirements)
            .matches(/[0-9]+/, errors.otherRequirements)
            .test('notContainsUsername', errors.notContainsUsername, item => !!item && !item.includes(username)),

        confirmPassword: Yup.string()
            .required(errors.required(valuesSchema.confirmPassword.label))
            .oneOf([Yup.ref('password'), ""], errors.notSame),
    });
}
