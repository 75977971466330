import { Instance, SnapshotIn, types, flow, getEnv, getSnapshot } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { BaseItem } from '@shared/stores/BaseItem.store';
import { getOptionsByNameId, convertStringsToOptions } from '@shared/helpers/form';
import {
    performGetUserDepartments,
    performGetUsersList,
    performGetUserAssignableRoles,
    performGetAllFilters,
} from '../services';

export const FiltersOptionsStoreInferred = types
    .model('FiltersOptionsStoreInferred',
        {
            departments: types.array(types.string),
            roles: types.array(BaseItem),
            assignableRoles: types.array(BaseItem),
            statuses: types.array(BaseItem),
            groups: types.array(BaseItem),
            procedures: types.array(BaseItem),
            users: types.array(BaseItem),
        })
    .views(self => ({
        get getFilterOptions() {
            return getSnapshot(self);
        },
        get getDepartmentsOptions() {
            return convertStringsToOptions(self.departments);
        },
        get getRolesOptions() {
            return getOptionsByNameId(self.roles);
        },
        get getAssignableRolesOptions() {
            return getOptionsByNameId(self.assignableRoles);
        },
        get getStatusesOptions() {
            return getOptionsByNameId(self.statuses);
        },
        get getGroupsOptions() {
            return getOptionsByNameId(self.groups);
        },
        get getProceduresOptions() {
            return getOptionsByNameId(self.procedures);
        },
        get getUsersOptions() {
            return getOptionsByNameId(self.users);
        },
    }))
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);

        const loadFilters = flow(function* () {
            const result = yield performGetAllFilters(
                api,
                {
                    payload: {},
                    errorHandlers: { DEFAULT: '' },
                },
            );
            if (result.data) {
                // self.departments = result.data.departments; // Wrong format from BE here
                self.roles = result.data.roles;
                self.statuses = result.data.status;
                self.groups = result.data.groups;
                self.procedures = result.data.procedures;
            }
        });

        const loadDepartments = flow(function* () {
            const result = yield performGetUserDepartments(
                api,
                {
                    payload: {},
                    errorHandlers: { DEFAULT: '' },
                },
            );
            if (result.data) {
                self.departments = result.data;
            }
        });

        const loadAssignableRoles = flow(function* () {
            const result = yield performGetUserAssignableRoles(
                api,
                {
                    payload: {},
                    errorHandlers: { DEFAULT: '' },
                },
            );
            if (result.data) {
                self.assignableRoles = result.data;
            }
        });

        const loadUsers = flow(function* () {
            const result = yield performGetUsersList(
                api,
                {
                    payload: {},
                    errorHandlers: { DEFAULT: '' },
                },
            );
            if (result.data) {
                self.users = result.data;
            }
        });

        const loadFilterOptions = flow(function* () {
            yield Promise.all([
                loadFilters(),
                loadDepartments(),
                loadAssignableRoles(),
                loadUsers(),
            ])
        });

        return {
            loadFilterOptions,
        }
    })

type FiltersOptionsStoreFactoryType = typeof FiltersOptionsStoreInferred;
interface IFiltersOptionsStoreFactory extends FiltersOptionsStoreFactoryType { }
export const FiltersOptionsStore: IFiltersOptionsStoreFactory = FiltersOptionsStoreInferred;
export interface IFiltersOptionsStore extends Instance<IFiltersOptionsStoreFactory> { }
export interface IFiltersOptionsStoreSnapshotIn extends SnapshotIn<IFiltersOptionsStore> { }
