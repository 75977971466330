import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { IUserAnalyticStore } from '../../stores';
import { ExecutionsProgress } from './components';
import { RoutePaths } from '@core/routes/RoutePaths';
import { Avatar } from '@shared/components/Avatar';

import { useStyles } from './AnalyticsPage.styles';
import { messages } from '../../analytic.messages';
import { ThemeProps } from '@styles/theme';
import { Row } from '@tanstack/react-table';

type RowProps<T> = {
    row: Row<T>;
};

export function GridItem<T>( { row }: RowProps<T>) {
    const navigate = useNavigate();
    const theme = useTheme<ThemeProps>()
    const styles = useStyles({ theme });

    const data = row.original as IUserAnalyticStore;

    const onUserClick = () => navigate(`/${RoutePaths.analytics}/${data.userId}`);

    return (
        <div className={styles.gridItemWrapper}>
            <div className={styles.heading}>
                <div className={styles.userInfo}>
                    <Avatar
                        firstName={data.userFirstName}
                        lastName={data.userLastName}
                        image={data.userAvatar}
                        className={styles.avatar}
                    />
                    <span className={styles.userName}>
                        {data.userFirstName} {data.userLastName}
                    </span>
                </div>

                <span title={messages['analyticsPage.proceduresExecution']}>
                    <ArrowForwardIcon className={styles.goToUser} onClick={onUserClick} />
                </span>
            </div>

            <ExecutionsProgress
                completed={data.completedProcedures}
                assigned={data.assignedProcedures}
            />
        </div>
    )
}
