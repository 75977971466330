import { IBaseItem } from '@shared/stores/BaseItem.store';
import { ISelectOption } from './ISelectOption';
import { getValueLabelOptions } from './getValueLabelOptions';

export function getOptionsByNameId(items: IBaseItem[], options: ISelectOption[] | undefined = undefined): ISelectOption[] {
    return getValueLabelOptions<IBaseItem>(
        items,
        item => item.name,
        item => item.id,
        item => options != undefined && options.find(el => el.value == item.id) == undefined
    );
}
