import React, { FC, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import _ from 'lodash';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { useUserProfileUIStore } from '@core/useStores';
import { SelectOption } from '@shared/components/SelectDropdown/Option';

import { useStyles } from './OtpModal.styles';
import { messages } from './otpModal.messages';
import { ThemeProps } from '@styles/theme';

export const COMMON_SELECT_PROPS = {
    isSearchable: false,
    hideSelectedOptions: false,
    isClearable: false,
    isMulti: false,
    closeMenuOnSelect: true,
    components: { Option: SelectOption },
}

export type OtpModalProps = {
    isOpen: boolean,
    onRequestClose: () => void,
}

export const OtpModal: FC<OtpModalProps> = ({
    isOpen,
    onRequestClose,
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const userProfileUIStore = useUserProfileUIStore();

    const onCancelOtp = () => {
        userProfileUIStore.toggleOtpModalOpened(false);
        userProfileUIStore.forceStopOtpPolling();
        // TODO add revoke API functionality
        userProfileUIStore.cleanUpOtpModal();
    };

    const onRefreshOtp = () => {
        userProfileUIStore.refreshOtp();
        userProfileUIStore.cleanUpOtpModal();
    }

    const calculateExpiration = () => {
        let timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        if (userProfileUIStore.otpExpiration != null) {
            const difference = +userProfileUIStore.otpExpiration! - +new Date();

            if (difference > 0) {
                timeLeft = {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60),
                };
            }
        }

        return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateExpiration());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateExpiration());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
        >
            <div className={styles.modalHeading}>
                <h2 className={styles.h2}>
                    { messages['otpModal.header'] }
                </h2>
            </div>

            <Observer>
                {() => (
                    <div className={styles.centeredDiv}>
                        {
                            userProfileUIStore.isLoading &&
                            <CircularProgress
                                className={classNames(styles.loader)}
                                color='inherit' size={24}
                            />
                        }
                    </div>
                )}
            </Observer>
            <Observer>
                {() => (
                    <>
                        {
                            userProfileUIStore.otp &&
                            <>
                                <div className={styles.centeredDiv}>
                                    { messages['otpModal.otp'] }
                                    <h2 className={styles.otp}>
                                        {userProfileUIStore.otp}
                                    </h2>
                                </div>
                                <div className={styles.instructions}>
                                    { userProfileUIStore.otpInstructions }
                                </div>
                                <div>
                                    <p className={styles.expiration}>
                                        { messages['otpModal.expiration'] + `${timeLeft.minutes.toString().padStart(2, '0')}:${timeLeft.seconds.toString().padStart(2, '0')}`}
                                    </p>
                                </div>
                            </>
                        }
                    </>
                )}
            </Observer>


            <div className={classNames(styles.buttons)}>
                <Button
                    onClick={onCancelOtp}
                    className={styles.button} color="secondary"
                >
                    {messages['otpModal.button.close']}
                </Button>

                <Observer>
                    {() => (
                        <Button
                        className={styles.button}
                        onClick={onRefreshOtp}
                        disabled={userProfileUIStore.otpRefreshUrl == null}
                        >
                            {messages['otpModal.button.refreshOtp']}
                        </Button>
                    )}
                </Observer>
            </div>
        </Modal>
    )
}
