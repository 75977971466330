import * as Yup from 'yup';
import { UserFormValues } from './';
import { FIELD_NAMES } from './valuesSchema';
import { messages } from 'Users/users.messages';

const NAME_REGEX = /^([A-Za-z-\s]+)$/;
const DEPARTMENT_REGEX = /^[A-Za-z0-9-]+( [a-zA-Z0-9]+)*$/;

export const errors = {
    email: messages['resendInviteModal.error.email'],
    required: messages['resendInviteModal.error.required'],
};

export const validationSchema = Yup.object<Partial<UserFormValues>>({
    email: Yup.string().email(errors.email).required(errors.required(FIELD_NAMES.email)),
    firstName: Yup.string()
        .matches(NAME_REGEX, messages['userModal.validation.name'](FIELD_NAMES.firstName))
        .required(errors.required(FIELD_NAMES.firstName)),
    lastName: Yup.string()
        .matches(NAME_REGEX, messages['userModal.validation.name'](FIELD_NAMES.lastName))
        .required(errors.required(FIELD_NAMES.lastName)),
    department: Yup.string()
        .trim()
        .matches(DEPARTMENT_REGEX, messages['userModal.validation.department'](FIELD_NAMES.department)),
    roleId: Yup.object({
        value: Yup.string().required(errors.required(FIELD_NAMES.roleId)), label: Yup.string(),
    }),
});
