import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { flex, setSize } from '@styles/resources/mixins';
import { ThemeProps } from '@styles/theme';
import { messages } from '@shared/components/components.messages';
import { useTheme } from '@mui/material/styles';

type AvatarProps = {
    firstName: string,
    lastName: string,
    image?: string | null,
    className?: string
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    avatar: {
        ...flex('', 'center', 'center'),
        ...setSize(40, 40),
        flexShrink: 0,
        borderRadius: theme.radius,
        backgroundColor: theme.colors.grey_light,
    },
    text: {
        color: theme.colors.violet_primary,
        fontSize: 20,
        lineHeight: 1.6,
        textTransform: 'uppercase',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: theme.radius,
        objectFit: 'scale-down',
    },
}))

export const Avatar: FC<AvatarProps> = ({
    className,
    image,
    firstName,
    lastName,
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    return (
        <div className={classNames(styles.avatar, className)}>
            {image ? (
                <img
                    className={styles.image}
                    src={`data:image/png;base64,${image}`}
                    alt={messages['avatar.alt']}
                    data-testid="avatar-image"
                />
            ) : (
                <p className={styles.text}>
                    {firstName.slice(0, 1)}{lastName.slice(0, 1)}
                </p>
            )}
        </div>
    )
}
