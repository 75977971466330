import { Instance, SnapshotIn, types, getEnv } from 'mobx-state-tree';

import { PagesNames } from '@core/routes/PagesNames';
import { Permission } from '@core/domain/Permission';
import { Roles } from '@core/domain/Roles';
import { IStoresEnv } from '@core/storesEnv';

export const UserPermissionsUIStoreInferred = types.model('UserPermissionsUIStore', {})
    .views(self => {
        const { api } = getEnv<IStoresEnv>(self);
        const { auth } = getEnv(api);

        return {
            get tabs() {
                return auth?.userPermissions?.viewTabs;
            },

            get groups() {
                return auth?.userPermissions?.group?.operations;
            },

            get companies() {
                return auth?.userPermissions?.company?.operations;
            },

            get users() {
                return auth?.userPermissions?.user?.operations;
            },

            get procedures() {
                return auth?.userPermissions?.procedure;
            },
            get licenses() {
                return auth?.userPermissions?.license;
            },
            get userRoles() {
                return auth?.userRoles;
            },
        }
    })
    .views(self => {
        return {
            get isCompaniesAvailable() {
                return self.tabs.companies !== Permission.Denied;
            },

            get isUsersAvailable() {
                return self.tabs.users !== Permission.Denied;
            },

            get isProceduresAvailable() {
                return self.tabs.procedures !== Permission.Denied;
            },

            get isCollaborationsAvailable() {
                return self.tabs.collaborations !== Permission.Denied;
            },

            get isAnalyticsAvailable() {
                return self.tabs.analytics !== Permission.Denied;
            },
            get isLicensesAvailable() {
                return self.tabs.licenses !== Permission.Denied;
            },
            get isDownloadAvailable() {
                return self.tabs.download !== Permission.Denied;
            },
            isPageAvailable(page: string) {
                return self.tabs && (self.tabs[page] !== Permission.Denied);
            },
        }
    })
    .views(self => {
        return {
            get firstAvailablePage() {
                if (!self.tabs) { return null; }

                if (self.isCompaniesAvailable) { return PagesNames.Companies; }
                if (self.isUsersAvailable) { return PagesNames.Users; }
                if (self.isProceduresAvailable) { return PagesNames.Procedures; }
                if (self.isCollaborationsAvailable) { return PagesNames.Collaboration; }
                if (self.isAnalyticsAvailable) { return PagesNames.Analytics; }
                if (self.isLicensesAvailable) { return PagesNames.Licenses; }
                if (self.isDownloadAvailable) { return PagesNames.Download; }

                return null;
            },
        }
    })
    .views(self => {
        return {
            get canCreateGroup() {
                return self.groups && (self.groups.canCreate !== Permission.Denied);
            },

            get canUpdateGroup() {
                return self.groups && (self.groups.canUpdate !== Permission.Denied);
            },
        }
    })
    .views(self => {
        return {
            get canCreateCompany() {
                return self.companies && (self.companies.canCreate !== Permission.Denied);
            },

            get canUpdateCompany() {
                return self.companies && (self.companies.canUpdate !== Permission.Denied);
            },
        }
    })
    .views(self => {
        return {
            get canCreateUser() {
                return self.users && (self.users.canCreate !== Permission.Denied);
            },

            get canUpdateUser() {
                return self.users && (self.users.canUpdate !== Permission.Denied);
            },
        }
    })
    .views(self => {
        return {
            get canDisableLicense() {
                return self.licenses && (self.licenses.operations.canDelete !== Permission.Denied);
            },
        }
    })
    .views(self => {
        return {
            get canCreateProcedure() {
                return self.procedures.operations && (self.procedures.operations.canCreate !== Permission.Denied);
            },

            get canUpdateProcedure() {
                return self.procedures.operations && (self.procedures.operations.canUpdate !== Permission.Denied);
            },

            get canChangeAssignedUsers() {
                return self.procedures.fields && self.procedures.fields.entries.users !== Permission.Denied;
            },

            get canChangeAssignedGroups() {
                return self.procedures.fields && self.procedures.fields.entries.groups !== Permission.Denied;
            },

            get canChangeOwnership() {
                return self.procedures.fields && self.procedures.fields.entries.ownership !== Permission.Denied;
            },
        }
    })
    .views(self => {
        return {
            get isSuperAdmin() {
                return self.userRoles && self.userRoles.includes(Roles.SuperAdmin);
            },

            get isMultiAdmin() {
                return self.userRoles && self.userRoles.includes(Roles.MultiAdmin);
            },

            get isAdmin() {
                return self.userRoles && self.userRoles.includes(Roles.Admin);
            },

            get isManager() {
                return self.userRoles && self.userRoles.includes(Roles.Manager);
            },

            get isInstructor() {
                return self.userRoles && self.userRoles.includes(Roles.Instructor);
            },

            get isPlayer() {
                return self.userRoles && self.userRoles.includes(Roles.Player);
            },

            get isCreator() {
                return self.userRoles && self.userRoles.includes(Roles.Creator);
            },
        }
    })
    .views(self => {
        return {
            canEditUser(roles: string[]) {
                if (!self.canUpdateUser) {
                    return false;
                }

                if (
                    self.isManager &&
                    roles.some(role => [Roles.Manager, Roles.Admin].includes(role as Roles))
                ) {
                    return false;
                }

                if (
                    self.isInstructor &&
                    roles.some(role => [Roles.Manager, Roles.Instructor, Roles.Admin].includes(role as Roles))
                ) {
                    return false;
                }

                if (self.isCreator || self.isPlayer) {
                    return false;
                }

                return true;
            },
        }
    })

type UserPermissionsUIStoreFactoryType = typeof UserPermissionsUIStoreInferred;
interface IUserPermissionsUIStoreFactory extends UserPermissionsUIStoreFactoryType { }
export const UserPermissionsUIStore: IUserPermissionsUIStoreFactory = UserPermissionsUIStoreInferred;
export interface IUserPermissionsUIStore extends Instance<IUserPermissionsUIStoreFactory> { }
export interface IUserPermissionsUIStoreSnapshotIn extends SnapshotIn<IUserPermissionsUIStore> { }
