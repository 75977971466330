import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import { Formik, Form, FormikProps } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import { Fade, Typography } from '@mui/material';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Brush,
    AreaChart,
    Area
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { AutoSubmit } from '@shared/components/SearchForm/AutoSubmit';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { FormSchema } from '@shared/components/forms/FormSchema';
import { FormError } from '@shared/components/FormError';
import { ISelectOption } from '@shared/helpers/form/ISelectOption';
import { getOptionByString } from '@shared/helpers/form';
import { useUserAnalyticUIStore } from '@core/useStores';
import {
    COLOR_GREY_TEXT,
    COLOR_TRANSP_GREY_LIGHT,
    COLOR_GREY_LIGHT,
    COLOR_YELLOW,
    COLOR_GREY_LIGHTER,
    COLOR_GREY_DARKER,
    COLOR_RED,
    COLOR_VIOLET_PRIMARY,
    COLOR_TRANSP_VIOLET
} from '@styles/constants';
import { useStyles } from './AnalyticsLineChart.styles';
import { formatSecondsToTimeString } from '@shared/helpers/timeFormatters';
import { messages } from 'Analytics/analytic.messages';
import { CustomizedAxisTick } from './CustomizedAxisTick';
import { CustomizedTooltip } from './CustomizedTooltip';
import { ThemeProps } from '@styles/theme';
import { CustomizedCountTooltip } from './CustomizedCountTooltip';
import _ from 'lodash';

const SHOW_DOTS_LIMIT = 30;
const STROKE_WIDTH = 1.5;
const EMPTY_OPTION = { value: '', label: '' } as ISelectOption;
const NULL_STEP = { time: 0, date: new Date() }
const START_STEP = {
    name: messages['lineChart.start'],
    allAverage: NULL_STEP,
    usrBest: NULL_STEP,
    usr1: NULL_STEP,
    usr2: NULL_STEP,
    usr3: NULL_STEP,
    count: 0,
}

const FINISH_STEP = {
    ...START_STEP, name: messages['lineChart.finish'],
}

class FormValues {
    procedureVersionId = EMPTY_OPTION;
    executionMode = EMPTY_OPTION;
    procedureVersionPlatformId = EMPTY_OPTION;
}

type FilterFormSchema = FormSchema<FormValues>;

const valueSchema: FilterFormSchema = {
    procedureVersionId: {
        fieldName: 'procedureVersionId',
        placeholder: '',
    },
    executionMode: {
        fieldName: 'executionMode',
        placeholder: '',
    },
    procedureVersionPlatformId: {
        fieldName: 'procedureVersionPlatformId',
        placeholder: '',
    },
}

const COMMON_SELECT_PROPS = {
    isSearchable: false,
    hideSelectedOptions: false,
    isClearable: false,
    isMulti: false,
    closeMenuOnSelect: true,
    components: { Option: SelectOption },
}
const BRUSH_LIMIT = 20;

const yAxisTickFormatter = (value: number) => (value).toFixed(2);

export const AnalyticsLineChart: FC = () => {
    const userAnalyticUIStore = useUserAnalyticUIStore();
    const params = useParams();

    const delay = (time: number) => new Promise(res => setTimeout(res, time));

    const onSubmit = async (values: FormValues) => {
        await delay(0);
        debugger;

        userAnalyticUIStore.setParams({
            userId: params.id,
            procedureVersionId: values.procedureVersionId?.value,
            executionMode: values.executionMode?.value,
            procedureVersionPlatformId: values.procedureVersionPlatformId?.value,
        })
    }

    const getValidOption = (options: ISelectOption[], value: string | null | undefined) => {
        if(value && options.find(o => o.value === value)){
            return getOptionByString(value, options);
        }
        if(options && options.length > 0){
            return options[0];
        }
        return EMPTY_OPTION;
    }

    useEffect(() => {
        const validParams = userAnalyticUIStore.firstValidRequestParams;
        userAnalyticUIStore.resetParams();
        userAnalyticUIStore.setParams({
            ...validParams,
            userId: params.id,
            procedureId: params.procedureId,
        });
    }, [params.id, params.procedureId])

    const {
        analyticsFilters: {
            getProcedureVersionsOptions,
            getModeOptions,
            getProcedureVersionPlatformsOptions,
        }
    } = userAnalyticUIStore;

    return (
        <Observer>
            {() => {
                const {
                    procedureSteps,
                    allUsersAverage,
                    bestTime,
                    requestParams: {
                        procedureId,
                        procedureVersionId,
                        executionMode,
                        procedureVersionPlatformId,
                    },
                    isStepsLoading,
                    procedureStepsError,
                    selectedProcedureName,
                } = userAnalyticUIStore;

                const showBrush = (procedureSteps && procedureSteps.length > BRUSH_LIMIT) as boolean;
                const showExecutions = (procedureSteps && procedureSteps.length > 0 && _.some(procedureSteps, s => s.count > 1)) as boolean;
                const showDots = (procedureSteps && procedureSteps.length < SHOW_DOTS_LIMIT) as boolean;

                const theme = useTheme<ThemeProps>();
                const styles = useStyles({ theme, showBrush });

                const tickWidth = (100 / (procedureSteps ? procedureSteps.length + 2 : 100));

                // Memoize options
                const getProcedureVersionsOptionsMemo = useMemo(
                    () => getProcedureVersionsOptions(),
                    [getProcedureVersionsOptions, procedureId]
                );
                const getModeOptionsMemo = useMemo(
                    () => getModeOptions(procedureVersionId),
                    [getModeOptions, procedureVersionId]
                );
                const getProcedureVersionPlatformsOptionsMemo = useMemo(
                    () => getProcedureVersionPlatformsOptions(procedureVersionId),
                    [getProcedureVersionPlatformsOptions, procedureVersionId]
                );

                // Memoize valid options
                const versionIdOption = useMemo(
                    () => getValidOption(getProcedureVersionsOptionsMemo, procedureVersionId),
                    [getProcedureVersionsOptionsMemo, procedureVersionId]
                );
                const executionModeOption = useMemo(
                    () => getValidOption(getModeOptionsMemo, executionMode),
                    [getModeOptionsMemo, executionMode]
                );
                const platformIdOption = useMemo(
                    () => getValidOption(getProcedureVersionPlatformsOptionsMemo, procedureVersionPlatformId),
                    [getProcedureVersionPlatformsOptionsMemo, procedureVersionPlatformId]
                );

                // Memoize initialValues
                const initialValues = useMemo(
                    () => ({
                    procedureVersionId: versionIdOption,
                    executionMode: executionModeOption,
                    procedureVersionPlatformId: platformIdOption,
                    }),
                    [versionIdOption, executionModeOption, platformIdOption]
                );

                const filtersAreSelected = !!initialValues.procedureVersionId && !!initialValues.procedureVersionPlatformId;

                if (!procedureId) {
                    return <><Typography className={styles.placeholder} variant="h6">{messages['lineChart.procedureNotSelected']}</Typography></>;
                }

                return (
                    <div className={
                        classNames(styles.chart, { [styles.noData]: !procedureSteps })
                    }>
                        <div className={styles.header}>
                            <div className={styles.info}>
                                <Typography className={styles.title} variant="h6">{selectedProcedureName}</Typography>
                                <div className={styles.summary}>
                                    <div className={styles.chip}>
                                        {messages['lineChart.bestTime'](formatSecondsToTimeString(bestTime))}
                                    </div>
                                    <div className={classNames(styles.chip, styles.average)}>
                                        {messages['lineChart.averageTime'](formatSecondsToTimeString(allUsersAverage))}
                                    </div>
                                </div>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                            >
                                {({ values, submitForm }: FormikProps<FormValues>) => {
                                    return (
                                        <>
                                            <Form className={styles.filters} autoComplete='off' noValidate>
                                                <div className={styles.filter}>
                                                    <div className={styles.filterSubElement}>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            className={styles.h5}
                                                        >
                                                            {messages['lineChart.filter.version']}
                                                        </Typography>
                                                        <FormikSelectDropdown
                                                            schema={valueSchema.procedureVersionId}
                                                            className={styles.dropdown}
                                                            {...COMMON_SELECT_PROPS}
                                                            options={getProcedureVersionsOptionsMemo}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.filter}>
                                                    <div className={styles.filterSubElement}>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            className={styles.h5}
                                                        >
                                                            {messages['lineChart.filter.mode']}
                                                        </Typography>

                                                        <FormikSelectDropdown
                                                            schema={valueSchema.executionMode}
                                                            className={styles.dropdown}
                                                            disabled={!procedureVersionId || procedureVersionId.length == 0}
                                                            {...COMMON_SELECT_PROPS}
                                                            options={getModeOptionsMemo}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.filter}>
                                                    <div className={styles.filterSubElement}>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            className={styles.h5}
                                                        >
                                                            {messages['lineChart.filter.platform']}
                                                        </Typography>

                                                        <FormikSelectDropdown
                                                            schema={valueSchema.procedureVersionPlatformId}
                                                            className={styles.dropdown}
                                                            disabled={!procedureVersionId || procedureVersionId.length == 0}
                                                            {...COMMON_SELECT_PROPS}
                                                            options={getProcedureVersionPlatformsOptionsMemo}
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                            <AutoSubmit values={values} submitForm={submitForm} />
                                        </>
                                    )
                                }}
                            </Formik>
                        </div>
                        {procedureStepsError && (
                            <FormError text={procedureStepsError} />
                        )}
                        {isStepsLoading && (
                            <CircularProgress className={styles.loader} color='inherit' size={24} />
                        )}
                        {(filtersAreSelected && procedureSteps && procedureSteps.length > 0) ? (
                            <>
                                {showExecutions && <ResponsiveContainer width="100%" height={160}>
                                    <AreaChart
                                        data={[...procedureSteps]}
                                        height={260}
                                        syncId={'procedureSteps'}
                                        margin={{ top: 25, right: 20, left: -38, bottom: 5 }}
                                    >
                                        <XAxis
                                            width={1000}
                                            tick={<CustomizedAxisTick visible={false}/>}
                                            interval={showBrush ? 'preserveStartEnd' : 0}
                                            dataKey="name"
                                        />
                                        <YAxis
                                            allowDecimals={false}
                                            tickCount={Math.min(5, procedureSteps[0].count)}
                                            tick={{ fill: COLOR_GREY_TEXT, fontSize: 10 }}
                                            label={{
                                                value: messages['lineChart.YAxisCount.label'],
                                                position: 'top',
                                                offset: 0,
                                                className: styles.yAxisLabel,
                                            }}
                                        />
                                        <CartesianGrid stroke={COLOR_GREY_LIGHT} horizontal={false} />
                                        <Tooltip content={<CustomizedCountTooltip />} />
                                        <Area dot={showDots} type="monotone" animationDuration={1000} dataKey="count" stroke={COLOR_VIOLET_PRIMARY} fill={COLOR_TRANSP_VIOLET} strokeWidth={STROKE_WIDTH} connectNulls />
                                    </AreaChart>
                                </ResponsiveContainer>}
                                <ResponsiveContainer width="100%" height={260}>
                                    <LineChart
                                        data={[...procedureSteps]}
                                        height={260}
                                        syncId={'procedureSteps'}
                                        margin={{ top: 25, right: 20, left: -38, bottom: 5 }}
                                    >
                                        <XAxis
                                            width={1000}
                                            tick={<CustomizedAxisTick />}
                                            interval={showBrush ? 'preserveStartEnd' : 0}
                                            dataKey="name"
                                        />
                                        <YAxis
                                            // tickFormatter={yAxisTickFormatter}
                                            tick={{ fill: COLOR_GREY_TEXT, fontSize: 10 }}
                                            label={{
                                                value: messages['lineChart.YAxis.label'],
                                                position: 'top',
                                                offset: 0,
                                                className: styles.yAxisLabel,
                                            }}
                                        />
                                        <CartesianGrid stroke={COLOR_GREY_LIGHT} horizontal={false} />
                                        <Tooltip content={<CustomizedTooltip />} />
                                        <Line dot={showDots} type="monotone" animationDuration={1000} dataKey="usr1.time" stroke={COLOR_GREY_DARKER} strokeWidth={STROKE_WIDTH} />
                                        <Line dot={showDots} type="monotone" animationDuration={1000} dataKey="usr2.time" stroke={COLOR_GREY_DARKER} strokeWidth={STROKE_WIDTH} />
                                        <Line dot={showDots} type="monotone" animationDuration={1000} dataKey="usr3.time" stroke={COLOR_GREY_DARKER} strokeWidth={STROKE_WIDTH} />
                                        <Line dot={showDots} type="monotone" animationDuration={1000} dataKey="allAverage.time" stroke="#13c2c2" strokeWidth={STROKE_WIDTH} />
                                        <Line dot={showDots} type="monotone" animationDuration={1000} dataKey="usrBest.time" stroke={COLOR_YELLOW} strokeWidth={STROKE_WIDTH} />
                                        {/** TODO: use theme color */}
                                        {showBrush && (
                                            <Brush
                                                className={styles.brush}
                                                dataKey='name'
                                                height={30}
                                                stroke="#13c2c2"
                                            />
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                                {showBrush && (
                                    <div className={styles.brushTicks}>
                                        <div
                                            key="startTick"
                                            className={styles.tick}
                                            style={{
                                                width: `${tickWidth}%`,
                                            }}
                                        >
                                            <span className={styles.startTick}>
                                                {messages['lineChart.start']}
                                            </span>
                                        </div>
                                        {[...procedureSteps].map((item, i) => (
                                            <div
                                                key={`${item.name}-${i}`}
                                                className={styles.tick}
                                                style={{
                                                    width: `${tickWidth}%`,
                                                }}
                                            >
                                                <div className={styles.index}>
                                                    {i + 1}
                                                </div>
                                            </div>
                                        ))}
                                        <div
                                            key="finishTick"
                                            className={styles.tick}
                                            style={{
                                                width: `${tickWidth}%`,
                                            }}
                                        >
                                            <span className={styles.finishTick}>
                                                {messages['lineChart.finish']}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    : (filtersAreSelected && <Fade in={true} timeout={1000}><Typography className={styles.placeholder} variant="h6">{messages['lineChart.noData']}</Typography></Fade>)}
                    </div>
                )
            }}
        </Observer>
    )
}
