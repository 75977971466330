import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ThemeProps } from '@styles/theme';
import { useTheme } from '@mui/material/styles';

import VRIcon from '@assets/vr.svg';
import ARIcon from '@assets/ar.svg';
import MobileIcon from '@assets/tablet.svg';
import PCIcon from '@assets/pc.svg';

type DeviceIconProps = {
    id?: number;
    type: string;
    platform?: string;
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    deviceIcon: {
        paddingRight: theme.spacing(1),
    },
}))

export const DeviceIcon: FC<DeviceIconProps> = props => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const { id, type, platform} = props;

    const deviceTypesImages = {
        'VR': VRIcon,
        'AR': ARIcon,
        'Mobile': MobileIcon,
        'PC': PCIcon,
    } as { [key: string]: string };

    const imgSrc = deviceTypesImages[type];

    return (
        <img
            className={styles.deviceIcon}
            src={imgSrc}
            alt={platform ?? ''}
            title={type ?? ''}
            key={id}
        />
    )
}
