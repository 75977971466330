import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import { TablePagination } from '../Table/components/TablePagination';
import { PaginationStatus } from './paginationStatus';

import { useStyles } from './Grid.styles';
import { ThemeProps } from '@styles/theme';
import { ColumnDef, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, Row, TableState, useReactTable } from '@tanstack/react-table';

type Props<T> = {
    columns: ColumnDef<T>[],
    data: T[],
    className?: string,
    isLoading?: boolean,
    paginationStatus: PaginationStatus,
    initialState?: Partial<TableState>;
    fetchData?: Function,
    GridItem: React.FC<{ row: Row<T> }>,
};

export function Grid<T extends object = {}>({
    columns,
    data,
    className,
    isLoading,
    paginationStatus,
    initialState,
    GridItem,
    fetchData = () => { },
}: Props<T>) {
    const instance = useReactTable(
        {
            columns,
            data,
            autoResetAll: true,
            manualPagination: true,
            manualExpanding: false,
            manualSorting: true,
            enableMultiSort: true,
            enableSortingRemoval: true,
            initialState: {
                sorting: initialState?.sorting,
            },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
        },
    );

    const {
        getHeaderGroups,
        getRowModel,
        initialState: {
            pagination,
            sorting,
            columnFilters,
        },
    } = instance;

    const { pageIndex, pageSize } = pagination;

    React.useEffect(() => {
        fetchData({ pageIndex, pageSize, sorting });
    }, [pageIndex, pageSize, sorting])

    const goToPage = (pageIndex: number) => fetchData({ pageIndex });

    const theme = useTheme<ThemeProps>();
    
    const styles = useStyles({ theme });

    return (
        <div
            className={classNames(styles.grid, className, { [styles.gridDisabled]: isLoading })}
        >
            <div className={styles.body} >
                {getRowModel().rows.map(row => {

                    const key = row.id;

                    return <GridItem key={key} row={row} />
                })}
            </div>

            <TablePagination
                className={styles.pagination}
                paginationStatus={paginationStatus}
                goToPage={goToPage}
            />
        </div>
    )
}
