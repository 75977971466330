import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export type ExportUserExecutionsParams = {
  id: string;
  startDate: string | undefined;
  endDate: string | undefined;
}

export const performExportUserExecutions
  = async (api: IApiStore, params: EndpointCallParams<ExportUserExecutionsParams>) => {
    const { payload, ...rest } = params;
    const req = api
      .getBlob(`export-app/api/v1/Analytic/users/executions/${payload.id}
              ?startDate=${payload.startDate}&endDate=${payload.endDate}`, {
        payload: '',
        ...rest,
      });

    return req;
  }