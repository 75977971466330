import React, { FC } from 'react';
import { Text } from 'recharts';

import { COLOR_GREY_TEXT } from '@styles/constants';
import { messages } from 'Analytics/analytic.messages';

type TickProps = {
    visible?: boolean,
    x?: number,
    y?: number,
    payload?: any,
}

export const CustomizedAxisTick: FC<TickProps> = ({
    x, y, payload, visible = true
}) => {
    const isFlat = payload.value === messages['lineChart.start'] || payload.value === messages['lineChart.finish'];

    return (
        visible && <g transform={`translate(${x},${y})`}>
            <Text
                x={2}
                y={2}
                dy={16}
                width={160}
                textAnchor="start"
                fill={COLOR_GREY_TEXT}
                fontSize={10}
                transform={isFlat ? 'scale(1, 1)' : 'scale(-1, -1) rotate(-150)'}
            >
                {payload.value}
            </Text>
        </g>
    );
};
