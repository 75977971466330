import React from 'react';
import { Field, FieldProps } from 'formik';

import { ToggleInput } from '../../inputs/ToggleInput';
import { FormSchemaItem } from '../../FormSchema';

export type FormikToggleInputProps = {
    schema: FormSchemaItem;
    helperText?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    styleVariant?: 'appExternal' | 'appInner',
    type?: string;
}

export const FormikToggleInput: React.FC<FormikToggleInputProps> = props => {
    const {
        schema, helperText, autoFocus,
        styleVariant, type, disabled,
    } = props;

    return (
        <Field name={schema.fieldName}>
            {({ field, form, meta }: FieldProps) => (
                <ToggleInput
                    field={field}
                    form={form}
                    meta={meta}
                    label={schema.label}
                    isRequired={schema.isRequired}
                    restrictEngine={schema.restrictEngine}
                    type={type || schema.type}
                    min={schema.min}
                    max={schema.max}
                    className={schema.className}
                    styleVariant={styleVariant}
                    helperText={helperText}
                    autoFocus={autoFocus}
                    disabled={disabled}
                />
            )}
        </Field>
    )
};

FormikToggleInput.displayName = 'FormikToggleInput';
