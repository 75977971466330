import { Instance, SnapshotIn, types } from 'mobx-state-tree';

const StepModel = types.model('StepModel', {
    date: types.optional(types.string, ''),
    executionTime: types.optional(types.string, ''),
});

export const ExecutionStepStoreInferred = types.model('ExecutionStepStore', {
    stepName: types.optional(types.string, ''),
    averageExecutionsTime: types.optional(types.string, ''),
    bestTime: types.optional(StepModel, {}),
    stepExecutions: types.optional(types.array(StepModel), []),
    stepExecutionsCount: types.optional(types.number, 0),
});

type ExecutionStepStoreFactoryType = typeof ExecutionStepStoreInferred;
interface IExecutionStepStoreFactory extends ExecutionStepStoreFactoryType { }
export const ExecutionStepStore: IExecutionStepStoreFactory = ExecutionStepStoreInferred;
export interface IExecutionStepStore extends Instance<IExecutionStepStoreFactory> { }
export interface IExecutionStepStoreSnapshotIn extends SnapshotIn<IExecutionStepStore> { }
