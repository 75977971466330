import * as Yup from 'yup';

import { ResendInviteFormValues } from './ResendInviteModal';
import { FIELD_NAMES } from './valuesSchema';
import { messages } from 'Users/users.messages';

export const errors = {
    email: messages['resendInviteModal.error.email'],
    required: messages['resendInviteModal.error.required'],
};

export const validationSchema = Yup.object<Partial<ResendInviteFormValues>>({
    email: Yup.string().email(errors.email).required(errors.required(FIELD_NAMES.email)),
});
