import { FormSchema } from '@shared/components/forms/FormSchema';

import { ConfirmPasswordFormValues } from './ConfirmWithPasswordModal';
import { messages } from 'AuthPart/authpart.messages';

type CompanySwitchFormSchema = FormSchema<ConfirmPasswordFormValues>;

export const FIELD_NAMES = {
    password: messages['confirmPasswordForm.label.password'],
}

export const valuesSchema: CompanySwitchFormSchema = {
    password: {
        fieldName: 'password',
        label: FIELD_NAMES.password,
        isRequired: true,
        type: 'password',
    },
};
