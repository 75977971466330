import { Navigate, Outlet, PathRouteProps } from 'react-router-dom';
import React from 'react';

import { useUserProfileUIStore } from '@core/useStores';
import { RoutePaths } from '@core/routes/RoutePaths';

export const AnonymousRoute = (_ : PathRouteProps) => {
    const userProfileUIStore = useUserProfileUIStore();


    return (userProfileUIStore.isAuthentificated
                    ? <Navigate to={{ pathname: RoutePaths.root }} replace/>
                    : <Outlet /> );
}
