import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const GroupStoreInferred = types.model('GroupStore', {
    id: types.identifier,
    name: types.optional(types.string, ''),
});

type GroupStoreFactoryType = typeof GroupStoreInferred;
interface IGroupStoreFactory extends GroupStoreFactoryType { }
export const GroupStore: IGroupStoreFactory = GroupStoreInferred;
export interface IGroupStore extends Instance<IGroupStoreFactory> { }
export interface IGroupStoreSnapshotIn extends SnapshotIn<IGroupStore> { }
