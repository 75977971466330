import React from 'react'
import { Observer } from 'mobx-react';
import { Form, FormikProps } from 'formik';
import { useTheme } from '@mui/material/styles';

import { useSetPasswordUIStore } from '@core/useStores';
import { Button } from '@shared/components/Button';
import { FormikPasswordInput } from '@shared/components/forms/formikWrappers/FormikPasswordInput';
import { FormError } from '@shared/components/FormError';

import { FormValues } from './FormValues';
import { valuesSchema } from './valuesSchema';
import { loginErrors, DefaultError } from './errors';
import { APP_EXTERNAL_STYLE_VARIANT } from '@shared/components/forms/BaseInputProps';

import { useStyles } from './SetPassword.styles';
import { messages } from 'AuthPart/authpart.messages';
import { ThemeProps } from '@styles/theme';

export interface SetPasswordFormControlsProps extends FormikProps<FormValues> {
    inviteMode: boolean;
};

export const SetPasswordFormControls: React.FC<SetPasswordFormControlsProps> = ({ errors, inviteMode }) => {
    const theme = useTheme<ThemeProps>();
    
    const styles = useStyles({ theme });

    const setPasswordUIStore = useSetPasswordUIStore();
    const formErrors = Object.values(errors);

    return (
        <Form autoComplete='off' noValidate>
            <div className={styles.wrapper}>
                <h4 className={styles.formTitle}>
                    {messages['setPasswordForm.formTitle'](inviteMode)}
                </h4>
            </div>

            <Observer>
                {() => (
                    <div className={styles.errorWrapper}>
                        {
                            (
                                setPasswordUIStore.status.isError ||
                                setPasswordUIStore.tokenValidationStatus.isError
                            ) && (
                                <FormError
                                    text={loginErrors.get(setPasswordUIStore.processError) || DefaultError}
                                />
                            )
                        }
                    </div>
                )}
            </Observer>

            {
                formErrors && (
                    <div className={styles.errorWrapper}>
                        {
                            formErrors.map((error, index) => (
                                <FormError key={index} text={error || ''} />
                            ))
                        }
                    </div>
                )
            }

            <div className={styles.wrapper}>
                <div className={styles.fieldWrapper}>
                    <FormikPasswordInput
                        schema={valuesSchema.password}
                        helperText={messages['setPasswordForm.helperText']}
                        autoFocus={true}
                        styleVariant={APP_EXTERNAL_STYLE_VARIANT}
                    />
                </div>

                <div className={styles.fieldWrapper}>
                    <FormikPasswordInput
                        schema={valuesSchema.confirmPassword}
                        styleVariant={APP_EXTERNAL_STYLE_VARIANT}
                    />
                </div>

                <div className={styles.actionWrapper}>
                    <Observer>
                        {() => (
                            <Button
                                type='submit'
                                styleVariant="appExternal"
                                loading={setPasswordUIStore.status.isLoading}
                                disabled={setPasswordUIStore.tokenValidationStatus.isError}
                            >
                                {messages['setPasswordForm.actionTitle'](inviteMode)}
                            </Button>
                        )}
                    </Observer>
                </div>
            </div>
        </Form>
    )
}
