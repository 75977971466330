import color from 'color';

export const COLOR_BLACK = '#110729';
export const COLOR_WHITE = '#ffffff';
export const COLOR_RED = '#ff6464';
export const COLOR_RED_TRANSPARENT = color(COLOR_RED).fade(0.33).string();
export const COLOR_GREEN = '#23b46a';
export const COLOR_GREEN_TRANSPARENT = color(COLOR_GREEN).fade(0.33).string();
export const COLOR_YELLOW = '#f2b45b';
export const COLOR_TRANSP_YELLOW_HIGHLIGHT = color(COLOR_YELLOW).fade(0.62).string();
export const COLOR_PRIMARY = '#32376C';
export const COLOR_VIOLET_DARKEST = '#322159';
export const COLOR_VIOLET_DARKER = '#453271';
export const COLOR_VIOLET_PRIMARY = '#5f4b8b';
export const COLOR_DEEP_BLUE = '#00558c';
export const COLOR_BLUE_LINK = '#1a91ab';
export const COLOR_BLUE_PRIMARY = '#2acaed';
export const COLOR_BLUE_NEON = '#09edf5';
export const COLOR_BLUE_NEON_LUMINATING = '#00f6ff';
export const COLOR_GREY_TEXT = '#737373';
export const COLOR_GREY_DARKER = '#cbcbcb';
export const COLOR_GREY_LIGHT = '#e5e5e5';
export const COLOR_GREY_BACKGROUND = '#f8f8f8';
export const COLOR_TRANSP_VIOLET = color('#5f4b8b').fade(0.92).string();
export const COLOR_GREY_LIGHTER = '#ebebeb';
export const COLOR_ICON_BACKGROUND = '#f1eff6';
export const COLOR_TRANSP_GREY_LIGHT = color(COLOR_GREY_LIGHTER).fade(0.35).string();
export const COLOR_TRANSP_BLUE_DARKER = color(COLOR_BLUE_NEON).fade(0.68).string();
export const COLOR_TRANSP_BLUE = color(COLOR_BLUE_NEON).fade(0.88).string();
export const COLOR_TRANSP_BLUE_DISABLED = color(COLOR_BLUE_PRIMARY).fade(0.8).string();

export const SPACING_BASE = 4;

export const RADIUS = 4;

export const FONT_PRIMARY = 'Nunito Sans';
export const FONT_SECONDARY = 'Electrolize';
