import React from 'react';
import { createUseStyles } from 'react-jss';
import { NavigationManager } from '@core/services/NavigationManager';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';
import { RoutePaths } from '@core/routes/RoutePaths';
import { Button } from '@shared/components/Button';
import { messages } from '@shared/components/components.messages';
import { useTheme } from '@mui/material/styles';

export type AppExternalSuccessMessageProps = {
    text: string;
    navigator: NavigationManager;
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    successMessageWrapper: {
        color: theme.colors.white,
        fontSize: 16,
        lineHeight: '24px',
        fontFamily: theme.font.secondary,
        width: 310,
        marginBottom: theme.spacing(6),
        display: 'flex',
    },

    viewWrapper: {
        width: 468,
        ...flex('column', 'center', 'center'),
    },

    icon: {
        marginRight: theme.spacing(4),
        color: theme.colors.blue_neon,
    },
}))

export const AppExternalSuccessMessage: React.FC<AppExternalSuccessMessageProps> = ({ text, navigator }) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const navigateToLogin = () => navigator.to(RoutePaths.login);

    return (
        <div className={styles.viewWrapper}>
            <p className={styles.successMessageWrapper}>
                <DoneOutlinedIcon className={styles.icon} />
                {text}
            </p>

            <Button
                styleVariant="appExternal"
                onClick={navigateToLogin}
            >
                {messages['appExternalSuccessMessage.back']}
            </Button>
        </div>
    )
};

AppExternalSuccessMessage.displayName = 'AppExternalSuccessMessage';
