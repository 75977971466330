import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const ProceduresStatisticsStoreInferred = types
    .model({
        proceduresCount: types.maybeNull(types.number),
        averageExecutionsCount: types.maybeNull(types.number),
        averageTimeSpentCount: types.maybeNull(types.number),
    });

type ProceduresStatisticsStoreFactoryType = typeof ProceduresStatisticsStoreInferred;
interface IProceduresStatisticsStoreFactory extends ProceduresStatisticsStoreFactoryType { }
export const ProceduresStatisticsStore: IProceduresStatisticsStoreFactory = ProceduresStatisticsStoreInferred;
export interface IProceduresStatisticsStore extends Instance<IProceduresStatisticsStoreFactory> { }
export interface IProceduresStatisticsStoreSnapshotIn extends SnapshotIn<IProceduresStatisticsStore> { }
