import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams';
import { IApiStore } from '@core/api/apiStore';

export type CancelProcessParams = {
    token: string;
};

export const performCancelProcess = async (
    api: IApiStore,
    params: EndpointCallParams<CancelProcessParams>,
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const req = api
        .delete('contentfileprovider-app/api/v1/FileProvider', {
            ...rest,
            payload,
        })

    return req;
}
