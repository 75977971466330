import React from 'react';
import { Formik } from 'formik';
import { createUseStyles } from 'react-jss';

import { LoginFormControls } from './LoginFormControls';
import { validationSchema } from './validationSchema';
import { FormValues } from './FormValues';
import { useLoginUIStore } from '@core/useStores';
import { useTheme } from '@mui/material/styles';
import { ThemeProps } from '@styles/theme';

const useStyles = createUseStyles((_: ThemeProps) => ({
    viewWrapper: {
        width: 468,
    },
}));

type LoginFormProps = {
    isRegister?: boolean,
}

export const LoginForm: React.FC<LoginFormProps> = ({isRegister} : LoginFormProps) => {

    const loginUiStore = useLoginUIStore();

    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const loginUser = (values: FormValues) => {
        loginUiStore.login(values.email, values.password);
    }

    return (
        <div className={styles.viewWrapper}>
            <Formik
                onSubmit={loginUser}
                initialValues={new FormValues()}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {props => <LoginFormControls {...props} isRegister={isRegister} />}
            </Formik>
        </div>
    )
}
