import { createBrowserRouter, createRoutesFromElements, Router, useLocation } from 'react-router-dom';
import { MobXProviderContext } from 'mobx-react';
import ReactDOM from 'react-dom/client';
import React from 'react';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import jssTheme from '@styles/theme';

import { App } from './App';
import { initializeDependenciesAndCreateStores, Stores } from './createStores';
import { routes } from './routes/AppRouter';
import { basename } from 'path';


declare module '@mui/material/styles' {
    // interface DefaultTheme extends Theme {}
    type ThemeProps = {
        colors: {
            black: string,
            white: string,
            red: string,
            green: string,
            yellow: string,
            transp_yellow_hightlight: string,
            violet_darkest: string,
            violet_darker: string,
            violet_primary: string,
            deep_blue: string,
            blue_link: string,
            blue_primary: string,
            blue_neon: string,
            blue_neon_luminating: string,
            grey_text: string,
            grey_darker: string,
            grey_light: string,
            grey_background: string,
            transp_violet: string,
            transp_grey_light: string,
            grey_lighter: string,
            icon_background: string,
            transp_blue_darker: string,
            transp_blue_disabled: string,
            transp_blue: string,
        },
        font: {
            primary: string,
            secondary: string,
        },
        spacing: Function,
        radius: number
    }

    interface ThemeOptions {
        colors?: {
            black?: string,
            white?: string,
            red?: string,
            green?: string,
            yellow?: string,
            transp_yellow_hightlight?: string,
            violet_darkest?: string,
            violet_darker?: string,
            violet_primary?: string,
            deep_blue?: string,
            blue_link?: string,
            blue_primary?: string,
            blue_neon?: string,
            blue_neon_luminating?: string,
            grey_text?: string,
            grey_darker?: string,
            grey_light?: string,
            grey_background?: string,
            transp_violet?: string,
            transp_grey_light?: string,
            grey_lighter?: string,
            icon_background?: string,
            transp_blue_darker?: string,
            transp_blue_disabled?: string,
            transp_blue?: string,
        },
        font?: {
            primary?: string,
            secondary?: string,
        },
        spacing?: Function,
        radius?: number
    }
}


const rootHtmlElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootHtmlElement!);
const theme = createTheme(jssTheme);

const renderApp = (stores: Stores, router: any) => (
    root.render(
        <MobXProviderContext.Provider value={stores}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <App router={router}/>
                </ThemeProvider>
            </StyledEngineProvider>
        </MobXProviderContext.Provider>,
    )
);

const renderAppBootstrapError = () => (
    root.render(
        <div>ERROR - app bootsrap failed</div>,
    )
);

(async () => {

    const router = createBrowserRouter(routes);

    const navigate = router.navigate;

    try {
        const initialStores = await initializeDependenciesAndCreateStores({ navigate });
        // Was using { basename: '/' } as parameter for createBrowserHistory
        // navigate('/');

        renderApp(initialStores, router);
    } catch (e) {
        renderAppBootstrapError();
    }
})();

if (module.hot) {
    module.hot.accept();
}
