import * as Yup from 'yup';

import { ISelectOption } from '@shared/helpers/form/ISelectOption';
import { ProcedureFormValues } from './FormValues';
import { FIELD_NAMES } from './valuesSchema';
import { messages } from 'Procedures/procedures.messages';

export const errors = {
    required: messages['procedureModal.error.required'],
};

export const validationSchema = Yup.object<Partial<ProcedureFormValues>>({
    name: Yup.string().required(errors.required(FIELD_NAMES.name)),
    description: Yup.string(),
    groups: Yup.array().of(
        Yup.object<ISelectOption>({
            label: Yup.string(),
            value: Yup.string(),
        })
    ).nullable(),
    users: Yup.array().of(
        Yup.object<ISelectOption>({
            label: Yup.string(),
            value: Yup.string(),
        })
    ).nullable(),
    ownership: Yup.object<ISelectOption>({
        label: Yup.string(),
        value: Yup.string(),
    }),
});
