export const messages = {
    'appExternalSuccessMessage.back': 'Back to login page',
    'avatar.alt': 'User avatar',
    'confirmationModal.default.cancelText': 'Cancel',
    'confirmationModal.default.confirmText': 'Confirm',
    'expandableList.toggleButton': (isLimit: boolean) => `Show ${isLimit ? 'more' : 'less'}`,
    'noResultsView.text': (entityName: string) => `There are no ${entityName} matching the selected parameters.`,
    'searchForm.placeholder': 'Search',
    'usersSummary.editGroup': 'Edit group',
    'usersSummary.allUsers': 'All users',
    'usersSummary.usersWithoutGroup': 'Ungrouped users',
    'usersSummary.active.title': 'Active',
    'usersSummary.active.text': (count: number | string) => `${count} ${count == 1 ? 'Player' : 'Players'}`,
    'usersSummary.managed.title': 'Managed by',
    'usersSummary.managed.text': (count: number | string) => `${count} ${count == 1 ? 'Manager' : 'Managers'}`,
    'usersSummary.access.title': 'Access to',
    'usersSummary.access.text': (count: number | string) => `${count} ${count == 1 ? 'Procedure' : 'Procedures'}`,
    'tablePagination.summary': (current: number, total: number) => `Page ${current} of ${total}`,
    'dateRange.lastWeek': 'Last week',
    'dateRange.lastMonth': 'Last month',
    'dateRange.lastThreeMonths': 'Last three months',
    'dateRange.lastYear': 'Last year',
    'dateRange.allTime': 'All time',
}
