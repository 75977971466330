import * as React from 'react';
import { createUseStyles } from 'react-jss';

import { useUserPermissionsUIStore } from '@core/useStores';
import { NavLink } from '@shared/components/NavLink';
import { ThemeProps } from '@styles/theme';
import { messages } from '../../header.messages';
import { useTheme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    menuWrapper: {
        listStyle: 'none',
        display: 'flex',

        '& li': {
            marginRight: theme.spacing(2),
        },
    },
}))

export const MainMenu = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    const userPermissionsUIStore = useUserPermissionsUIStore();

    return (
        <nav>
            <ul className={styles.menuWrapper}>
                {
                    userPermissionsUIStore.isCompaniesAvailable && (
                        <li>
                            <NavLink
                                to="/companies"
                                data-testid="menu-item-companies"
                            >
                                {messages['mainMenu.companies']}
                            </NavLink>
                        </li>
                    )
                }
                {
                    userPermissionsUIStore.isUsersAvailable && (
                        <li>
                            <NavLink
                                to="/users"
                                data-testid="menu-item-users"
                            >
                                {messages['mainMenu.users']}
                            </NavLink>
                        </li>
                    )
                }
                {
                    userPermissionsUIStore.isProceduresAvailable && (
                        <li>
                            <NavLink
                                to="/procedures"
                                data-testid="menu-item-procedures"
                            >
                                {messages['mainMenu.procedures']}
                            </NavLink>
                        </li>
                    )
                }

                {
                    userPermissionsUIStore.isCollaborationsAvailable && (
                        <li>
                            <NavLink
                                to="/collaboration"
                                data-testid="menu-item-collaboration"
                            >
                                {messages['mainMenu.collaboration']}
                            </NavLink>
                        </li>
                    )
                }

                {
                    userPermissionsUIStore.isAnalyticsAvailable && (
                        <li>
                            <NavLink
                                to="/analytics"
                                data-testid="menu-item-analytics"
                            >
                                {messages['mainMenu.analytics']}
                            </NavLink>
                        </li>
                    )
                }

                {
                    userPermissionsUIStore.isLicensesAvailable && (
                        <li>
                            <NavLink
                                to="/licenses"
                                data-testid="menu-item-licenses"
                            >
                                {messages['mainMenu.licenses']}
                            </NavLink>
                        </li>
                    )
                }
                {
                    userPermissionsUIStore.isDownloadAvailable && (
                        <li>
                            <NavLink
                                to="/download"
                                data-testid="menu-item-download"
                            >
                                {messages['mainMenu.download']}
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </nav>
    );
}
