import React, { FC } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { Observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { ToastMessage } from '@shared/components/Toast';
import { FormError } from '@shared/components/FormError';
import { useUsersGridUIStore, useUserUIStore } from '@core/useStores';

import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';

import { messages } from 'Users/users.messages';
import { useStyles } from './ResendInviteModal.styles';
import { ThemeProps } from '@styles/theme';

export type ResendInviteModalProps = {
    isOpen: boolean,
    onRequestClose: () => void,
}

export class ResendInviteFormValues {
    email = '';
}

export const ResendInviteModal: FC<ResendInviteModalProps> = ({
    isOpen,
    onRequestClose,
}) => {
    const theme = useTheme<ThemeProps>();
    
    const styles = useStyles({ theme });

    const usersGridUIStore = useUsersGridUIStore();
    const userUIStore = useUserUIStore();

    const onSubmit = async (values: ResendInviteFormValues) => {
        const { email } = values;

        if (!userUIStore.resendInvitationInputData) {
            return;
        }

        const computedValues = {
            email,
            id: userUIStore.resendInvitationInputData.id,
        };

        const result = await usersGridUIStore.inviteUser.resendInviteUser(computedValues);

        if (result.success) {
            onRequestClose();

            toast.success(
                <ToastMessage
                    message={messages['resendInviteModal.success.toast']}
                    type='success'
                />
            );
        }
    }

    const initialValues = {
        email: userUIStore.resendInvitationInputData?.email || '',
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
        >
            <h2 className={styles.h2}>
                {messages['resendInviteModal.title'](userUIStore.resendInvitationInputData?.fullName)}
            </h2>

            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
            >
                {(form: FormikProps<ResendInviteFormValues>) => {
                    const storeErrors = usersGridUIStore.inviteUser.errors;
                    const formErrors: Record<string, any> = form.errors;
                    const errors = Object.keys(formErrors).length
                        ? Object.keys(formErrors).map((key: string) =>
                            formErrors[key].value ? formErrors[key].value : formErrors[key]
                        )
                        : storeErrors;

                    return (
                        <Form autoComplete='off' noValidate>
                            <Observer>
                                {() => (
                                    <>
                                        {errors?.length && (
                                            <div className={styles.errors}>
                                                {errors.map((text: string) => (
                                                    <FormError className={styles.error} key={text} text={text} />
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </Observer>

                            <div className={styles.field}>
                                <FormikTextInput
                                    schema={valuesSchema.email}
                                    cleanable
                                />
                            </div>

                            <div className={classNames(styles.buttons, styles.end)}>
                                <div>
                                    <Button
                                        onClick={onRequestClose}
                                        className={styles.button} color="secondary"
                                    >
                                        {messages['resendInviteModal.button.cancel']}
                                    </Button>

                                    <Button
                                        className={styles.button}
                                        disabled={!form.values.email}
                                        type="submit"
                                    >
                                        {messages['resendInviteModal.button.submit']}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}
