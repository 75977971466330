import classNames from 'classnames';
import moment from 'moment';
import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { DATE_MMM_DD, DATE_MMM_DD_YYYY_HH_MM } from '@shared/constants';
import { formatSecondsToTimeString } from '@shared/helpers/timeFormatters';
import { messages } from 'Analytics/analytic.messages';
import { useStyles } from './AnalyticsLineChart.styles';
import { ThemeProps } from '@styles/theme';

type TooltipProps = {
    type?: string,
    payload?: {
        value: number,
        payload: Record<string, any>
    }[],
    label?: string,
    active?: boolean,
}

export const CustomizedCountTooltip: FC<TooltipProps> = ({
    payload,
    label,
    active,
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme, showBrush: false });

    const DATA_KEYS = ['usrBest', 'allAverage', 'usr1', 'usr2', 'usr3'];

    const countValue = payload && payload.length && payload[0].payload['count'];
    const firstTime = payload && payload.length && payload[0].payload['firstTime'];
    const lastTime = payload && payload.length && payload[0].payload['lastTime'];

    if (active) {
        return (
            <div className={styles.tooltip} data-testid="customized-tooltip">
                <div className={styles.label}>{label}</div>
                <div className={styles.row}>
                    <p className={styles.date}>
                        {messages['lineChart.count']}
                    </p>
                    <p className={classNames(styles.time)}>
                        {countValue}
                    </p>
                </div>
                <div className={styles.row}>
                    <p className={styles.date}>
                        {moment(firstTime).format(DATE_MMM_DD_YYYY_HH_MM)}
                    </p>
                    <p className={classNames(styles.time)}>
                        {messages['lineChart.firstExecution']}
                    </p>
                </div>
                <div className={styles.row}>
                    <p className={styles.date}>
                        {moment(lastTime).format(DATE_MMM_DD_YYYY_HH_MM)}
                    </p>
                    <p className={classNames(styles.time)}>
                        {messages['lineChart.lastExecution']}
                    </p>
                </div>
            </div>
        );
    }

    return null;
};
