import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

type PageParams = {
  limit: number,
  page: number,
}

type SearchParams = {
  searchText: string,
}

type SortParams = {
  sortDirection: number | undefined,
  sortFieldName: string | undefined,
}

type FilterParams = {
  departments: string[],
  roleIds: string[],
  statusIds: string[],
  groupIds: string[],
  procedureIds: string[],
  lastLogin: string,
}

type GroupParams = {
  inGroup: number | null,
  inGroupId: string,
}

type GetProceduresParams = PageParams & SearchParams & SortParams & Partial<FilterParams> & GroupParams;

export const performGetProcedures = async (api: IApiStore, params: EndpointCallParams<GetProceduresParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .post('uiaggregator-app/api/v1/Procedures/Search', {
      ...rest,
      payload,
    })

  return req;
}
