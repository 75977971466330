import { NavigateFunction, useNavigate } from 'react-router-dom';
export class NavigationManager {
    private navigate : NavigateFunction;

    constructor(navigate: NavigateFunction)
    {
        this.navigate = navigate;
    }

    to(url: string, shouldReplace?: boolean){
        this.navigate('/' + url, { replace: shouldReplace, relative: "route" });
    }
}

export const createNavigationManagerMock = (): NavigationManager => new NavigationManager(useNavigate());