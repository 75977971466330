import React from 'react';
import classNames from 'classnames';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { APP_INNER_STYLE_VARIANT, APP_EXTERNAL_STYLE_VARIANT } from '../forms/BaseInputProps';
import { useStyles } from './Button.styles';
import { ThemeProps } from '@styles/theme';
import MuiButton, { ButtonOwnProps } from '@mui/material/Button';

export const PRIMARY_COLOR_BUTTON_TYPE = 'primary';
export const SECONDARY_COLOR_BUTTON_TYPE = 'secondary';

export type ButtonProps = {
    onClick?: () => void;
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    type?: 'button' | 'submit';
    styleVariant?: 'appExternal' | 'appInner',
    color?: 'primary' | 'secondary',
    loading?: boolean,
    variant?: 'contained' | 'outlined' | 'text',
}

export const Button: React.FC<ButtonProps & ButtonOwnProps> = ({
    onClick, children, disabled, type = 'button', className, loading,
    styleVariant = APP_INNER_STYLE_VARIANT, color = PRIMARY_COLOR_BUTTON_TYPE,
    ...restOfProps
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    return (
        <MuiButton
            className={
                classNames(styles.button,
                    {
                        [styles.appExternal]: styleVariant === APP_EXTERNAL_STYLE_VARIANT,
                        [styles.secondary]: color === SECONDARY_COLOR_BUTTON_TYPE,
                        [styles.disabled]: disabled,
                    },
                    className
                )}
            onClick={() => onClick && onClick()}
            { ...restOfProps }
            disabled={disabled}
            type={type}
            data-testid="button"
            variant='contained'
            startIcon={loading ? <CircularProgress size={18} className={styles.loader} /> : restOfProps.startIcon}
        >
            {
                children
            }
        </MuiButton>
    )
};

Button.displayName = 'Button';
