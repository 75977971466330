import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams';
import { IApiStore } from '@core/api/apiStore';

export const performGetProcedureProcessToken = async (
    api: IApiStore,
    params: EndpointCallParams<{}>,
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const req = api
        .post('content-app/api/v1/Procedures/Token', {
            ...rest,
            payload,
        })

    return req;
}
