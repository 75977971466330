import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import DownloadIcon from '@assets/download.png';
import SwitchIcon from '@assets/turn-off.png';

import { useStyles } from './LicensesPage.styles';
import { messages } from 'Licenses/license.messages';
import ArrowRightIcon from '@assets/arrow-right.svg';
import classNames from 'classnames';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';

import { useLicensesUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { Observer } from 'mobx-react';
import { LicensesSummary } from 'Licenses/components/LicenseSummary';
import { SearchForm } from '@shared/components/SearchForm';
import { Table } from '@shared/components/Table';
import { NoResultsView } from '@shared/components/NoResultsView';
import { LicenseModal } from 'Licenses/components/LicenseModal';

import moment from 'moment';
import { DATE_MMM_DD_YYYY } from '@shared/constants';
import { ThemeProps } from '@styles/theme';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ILicenseStore } from 'Licenses/stores';
import { Button } from '@shared/components/Button';
type SubComponentProps = {
    row: {
        original: {
            subRows: Array<{
                unityId: string,
                allUser: string,
                key: string
            }>,
        }
    }
}
type FetchDataType = {
    pageIndex: number;
    sortBy: {
        id: string;
        desc: boolean;
    }[]
}

const EXPANDER_CELL_WIDTH = 24;
const NoInfo = () => {
    const styles = useGridStyles();

    return <div className={styles.noInfo}>{messages['licensePage.noInfo']}</div>
}

const columnHelper = createColumnHelper<ILicenseStore>();

const columnsData = [
    columnHelper.display({
        id: 'expander',
        minSize: 40,
        maxSize: 40,
        header: () => (
            <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
        ),
        cell: ({ cell: { row } }) => {
            const styles = useGridStyles();
            const expanderHandler = row.getToggleExpandedHandler();

            return (
                <div
                    style={{
                        minWidth: EXPANDER_CELL_WIDTH,
                        display: 'flex',
                    }}
                    onClick={expanderHandler}
                >
                    {row.getCanExpand() && (
                        <img
                            src={ArrowRightIcon}
                            className={classNames(styles.expandIcon, { [styles.expanded]: row.getIsExpanded() })}
                        />
                    )}
                </div>
            )
        },
        size: EXPANDER_CELL_WIDTH + 16,
        enableSorting: false,
    }),
    columnHelper.accessor('name', {
        header: messages['licensePage.table.header.name'],
        size: 18,
        maxSize: 150,
        cell: ({ getValue }) => {
            const styles = useGridStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName}>{getValue()}</span>
                </div>
            );
        },
    }),
    columnHelper.accessor('unityId', {
        header: messages['licensePage.table.header.unityId'],
        size: 18,
        maxSize: 400,
        cell: ({ getValue }) => {
            const styles = useGridStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName} title={getValue()}>{getValue()}</span>
                </div>
            );
        },
    }),
    columnHelper.accessor('lastUser', {
        header: messages['licensePage.table.header.lastUser'],
        size: 18,
        maxSize: 200,
        cell: ({ getValue }) => {
            const styles = useGridStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName}>{getValue()}</span>
                </div>
            );
        },
    }),
    columnHelper.accessor('lastLogin', {
        header: messages['licensePage.table.header.lastLogin'],
        size: 18,
        maxSize: 150,
        cell: ({ getValue }) => getValue() ? moment(getValue()).format(DATE_MMM_DD_YYYY) : <></>,
    }),
    columnHelper.accessor('usedIn', {
        header: messages['licensePage.table.header.usedIn'],
        size: 18,
        maxSize: 100,
        cell: ({ getValue }) => {
            const styles = useGridStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName}>{getValue()}</span>
                </div>
            );
        },
    }),
    columnHelper.accessor('expireDate', {
        header: messages['licensePage.table.header.expireDate'],
        size: 18,
        maxSize: 150,
        cell: ({ getValue }) => {
            const value = getValue();

            return (
                value ? moment(value.replace("T23:59:59Z", "T12:00:00Z")).format(DATE_MMM_DD_YYYY) : <></>
            );
        }
    }),
    columnHelper.display({
        id: 'download',
        minSize: 40,
        maxSize: 90,
        enableSorting: false,
        cell: ({ cell: { row } }) => {
            const styles = useGridStyles();

            const licensesUIStore = useLicensesUIStore();
            const original: Record<string, any> = row.original;

            const onCellClick = () => {
                if (!original.isDisabled) { licensesUIStore.downloadLicense(original.key, original.unityId); }
            }
            const onToggle = async () => {
                const result = await licensesUIStore.toggleLicense(original.unityId, !original.isDisabled);
                if (result.success) {
                    licensesUIStore.resetLicense(result.data.unityId, result.data.isDisabled);
                }
            }
            const userPermissionsUIStore = useUserPermissionsUIStore();
            userPermissionsUIStore.isCompaniesAvailable;

            return (
                <>
                    {!userPermissionsUIStore.isCompaniesAvailable && (<span className={styles.placeholder} ></span>)
                    }
                    <button
                        onClick={() => onCellClick()}
                        className={styles.button}
                        title={messages['licensePage.download']}
                    >
                        <img src={DownloadIcon} style={{ width: 24, height: 24 }} />
                    </button>
                    {userPermissionsUIStore.canDisableLicense && (<button
                        onClick={() => onToggle()}
                        className={styles.button}
                        title={messages['licensePage.switch']}
                    >
                        <img src={SwitchIcon} style={{ width: 24, height: 24 }} />
                    </button>)
                    }
                </>
            )
        },
    }),
]

const useGridStyles = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    return styles;
}

export const LicensesPage: React.FC = () => {
    const licensesUIStore = useLicensesUIStore();
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    useEffect(() => {
        licensesUIStore.loadStatistics();
    }, []);
    const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
        const data = row.original.subRows[0];

        return (
            <div className={styles.bodySubComponent} >
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['licensePage.subRow.unityId']}</h5>
                    <div className={styles.text} style={{ wordBreak: "break-all" }}>
                        {data.unityId || <NoInfo />}
                    </div>
                </div>
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['licensePage.subRow.allUser']}</h5>
                    <div className={styles.text} style={{ wordBreak: "break-all" }}>
                        {data.allUser || <NoInfo />}
                    </div>
                </div>
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['licensePage.subRow.key']}</h5>
                    <div className={styles.text} style={{ wordBreak: "break-all" }}>
                        {data.key || <NoInfo />}
                    </div>
                </div>

            </div>
        )
    }, []);
    const initialSortState = {
        sorting: [],
    };

    const onLicenseModalClose = () => {

        licensesUIStore.toggleLicenseModalOpen(false);
    }

    const onSearchTextChanged = (searchText: string) => {
        licensesUIStore.changeSearchText(searchText);
    };

    const onFetchData = ({ pageIndex, sortBy }: FetchDataType) => {
        if (sortBy && sortBy.length) {
            licensesUIStore.setupSort(sortBy[0].id, Number(sortBy[0].desc));
        }
        licensesUIStore.setupPage(pageIndex);
    };
    const columns: ColumnDef<ILicenseStore, any>[] = React.useMemo(() => columnsData, []);
    const onLicenseModalOpen = () => {
        licensesUIStore.toggleLicenseModalOpen(true);
    }

    return (
        <>
            <LicensesSummary statistics={licensesUIStore.statistics} />

            <div className={styles.tableActions}>
                <div className={styles.invite}>
                    <Button
                        color='primary'
                        onClick={onLicenseModalOpen}
                        startIcon={<BookmarkAddIcon />}
                            >
                            {messages['licensePage.createLicense.title']}
                    </Button>
                </div>
                <div className={styles.filters}>
                    <Observer>
                        {() => (
                            <SearchForm
                                initialValues={{
                                    search: licensesUIStore.searchText
                                        ? licensesUIStore.searchText
                                        : '',
                                }}
                                onSearchTextChanged={onSearchTextChanged}
                                className={styles.searchWrapper}
                                disabled={licensesUIStore.isLoading}
                            />
                        )}
                    </Observer>
                </div>
            </div>
            <Observer>
                {() => {
                    const data = licensesUIStore.licenses.toJSON();
                    const preparedData = data.map(row => ({
                        ...row,
                        subRows: [{
                            unityId: row.unityId,
                            allUser: row.allUser,
                            key: row.key,
                        }],
                    }));

                    return (
                        <>
                            <LicenseModal
                                isOpen={licensesUIStore.licenseModalOpened}
                                onRequestClose={onLicenseModalClose}
                            />
                            {
                                licensesUIStore.licenses && licensesUIStore.licenses.length > 0 && (
                                    <Table
                                        data={preparedData}
                                        columns={columns}
                                        fetchData={onFetchData}
                                        isLoading={licensesUIStore.isLoading}
                                        renderRowSubComponent={renderRowSubComponent}
                                        paginationStatus={{ ...licensesUIStore.pagination }}
                                        initialState={initialSortState}
                                    />
                                )
                            }

                            {
                                (!licensesUIStore.licenses || licensesUIStore.licenses.length == 0) && <NoResultsView entityName='licenses' />
                            }
                        </>
                    );
                }}
            </Observer>
        </>
    )
}

