import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export const performDeleteAvatar = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const req = api
        .delete('identity-app/api/v1/Users/UserPhoto', {
            ...params,
            payload: {},
        })

    return req;
}
