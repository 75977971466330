import { Instance, SnapshotIn, types, applySnapshot } from 'mobx-state-tree';

import { DeviceData, DeviceTabInfo } from '../domain/Device';

export const ProcedureDeviceInferred = types.model({
    deviceId: types.maybeNull(types.string),
    device: types.maybeNull(types.string),
    exist: types.maybeNull(types.boolean),
    platform: types.maybeNull(types.string),
    procedureName: types.maybeNull(types.string),
    procedureDescription: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
})
    .actions(self => {
        const updateDevice = (data: DeviceData) => applySnapshot(self, data);

        return {
            updateDevice,
        }
    });

type ProcedureDeviceFactoryType = typeof ProcedureDeviceInferred;
interface IProcedureDeviceFactory extends ProcedureDeviceFactoryType { };
export const ProcedureDevice: IProcedureDeviceFactory = ProcedureDeviceInferred;
export interface IProcedureDevice extends Instance<IProcedureDeviceFactory> { };
export interface IProcedureDeviceSnapshotIn extends SnapshotIn<IProcedureDevice> { };
