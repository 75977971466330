import React, { FC, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import { Button } from '@shared/components/Button';
import { useStyles } from './DateRangePopup.styles';
import { ThemeProps } from '@styles/theme';
import { COLOR_GREY_LIGHTER } from '@styles/constants';
import moment from 'moment';
import color from 'color';
import { DateRange, DefinedRange } from 'react-date-range';
import { useUserAnalyticUIStore } from '@core/useStores';
import { messages } from '@shared/components/components.messages';
import { ClickOutsideListener } from '@ace/core';
import { Typography } from '@mui/material';
import { DATE_MMM_DD_YYYY } from '@shared/constants';

type DateRangeModalProps = {
    isOpen: boolean;
    message: string;
    confirmText?: string;
    onDismiss: () => void;
    onConfirm: (from: Date, to: Date) => void;
}

const basicRangesList = [
    {
        label: messages['dateRange.lastWeek'],
        value: moment.utc().subtract(6, 'days').startOf('day').format(),
    },
    {
        label: messages['dateRange.lastMonth'],
        value: moment.utc().subtract(1, 'months').startOf('day').format(),
    },
    {
        label: messages['dateRange.lastThreeMonths'],
        value: moment.utc().subtract(3, 'months').startOf('day').format(),
    },
    {
        label: messages['dateRange.lastYear'],
        value: moment.utc().subtract(1, 'years').startOf('day').format(),
    },
    {
        label: messages['dateRange.allTime'],
        value: moment.utc().subtract(20, 'years').startOf('day').format(),
    }
]

export const DateRangePopup: FC<DateRangeModalProps> = ({
    isOpen,
    message,
    confirmText = '',
    onDismiss,
    onConfirm,
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const userAnalyticUIStore = useUserAnalyticUIStore();

    const [state, setState] = useState({
        startDate: new Date(userAnalyticUIStore.requestParams?.getParams.startDate),
        endDate: new Date(userAnalyticUIStore.requestParams?.getParams.endDate),
        key: 'selection',
    });

    const onDatePickerChange = (item: Record<string, any>) => {
        setState(item.selection);
    }

    const staticRanges = basicRangesList.map(item => {
        const startDate = new Date(item.value);
        const endDate = new Date(moment.utc().startOf('day').format());

        return {
            label: item.label,
            range: () => ({
                startDate,
                endDate,
            }),
            isSelected() {
                return +new Date(startDate) === +new Date(state.startDate) &&
                    +new Date(endDate) === +new Date(state.endDate);
            },
        }
    })

    const onSubmit = () => onConfirm(state.startDate, state.endDate);

    return (
        isOpen && <ClickOutsideListener
            outsideClickIgnoreClass={styles.fields}
            onClickOutside={onDismiss}
        >
            <div className={styles.modal}>
                {message && <h2 className={styles.message}>{message}</h2>}
                <Typography variant='body1' className={styles.range}>
                    {moment(state.startDate).format(DATE_MMM_DD_YYYY)} - {moment(state.endDate).format(DATE_MMM_DD_YYYY)}
                </Typography>
                <div className={styles.calendar}>
                    <DateRange
                        displayMode="dateRange"
                        onChange={onDatePickerChange}
                        months={2}
                        ranges={[state]}
                        rangeColors={[`${color(COLOR_GREY_LIGHTER).fade(0.25)}`]}
                    />
                    <div className={styles.controls}>
                        <DefinedRange
                            onChange={onDatePickerChange}
                            ranges={[state]}
                            staticRanges={staticRanges}
                        />
                        <Button className={styles.button} onClick={onSubmit}>
                            {confirmText || messages['confirmationModal.default.confirmText']}
                        </Button>
                    </div>
                </div>
            </div>
        </ClickOutsideListener>
    )
}
