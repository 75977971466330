import React from 'react';
import { Route, Navigate, RouterProvider } from 'react-router-dom';
import { AnonymousRoute, ProtectedPage, ProtectedRoute } from '@core/Auth/components';

import { Login } from 'AuthPart/Login';
import { ForgotPassword } from 'AuthPart/ForgotPassword';
import { SetPassword } from 'AuthPart/SetPassword';
import { CompaniesPage } from 'Companies/components';
import { UsersPage } from 'Users/components';
import { ProceduresPage } from 'Procedures/components';
import { AnalyticsPage } from 'Analytics/components/AnalyticsPage';
import { UserAnalyticsPage } from 'Analytics/components/UserAnalyticsPage';
import { ProfilePage } from 'Profile/components/ProfilePage';

import { RoutePaths } from './RoutePaths';
import { PagesNames } from './PagesNames';
import { AppExternalLayout, AppLayout } from '../layouts';
import { LicensesPage } from 'Licenses/LicensesPage';
import { DownloadPage } from 'Download/DownloadPage';
import path from 'path';
import { Register } from 'Register/Register';

export const routes = [
    {
        path: RoutePaths.root, // This is '/'
        children: [
            {
                element: <AnonymousRoute />,
                children: [
                {
                    path: RoutePaths.login,
                    element: <AppExternalLayout><Login /></AppExternalLayout>,
                },
                {
                    path: RoutePaths.register,
                    element: <AppExternalLayout><Login isRegister /></AppExternalLayout>,
                },
                {
                    path: RoutePaths.forgotPassword,
                    element: <AppExternalLayout><ForgotPassword /></AppExternalLayout>,
                },
                {
                    path: RoutePaths.setPassword,
                    element: <AppExternalLayout><SetPassword /></AppExternalLayout>,
                },
                ],
            },
            {
                element: <ProtectedRoute />,
                children: [
                {
                    index: true,
                    element: <Navigate to={RoutePaths.users} />,
                },
                {
                    path: RoutePaths.companies,
                    element: (
                    <ProtectedPage page={PagesNames.Companies}>
                        <AppLayout path={RoutePaths.companies} showSidebar={false}><CompaniesPage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                {
                    path: RoutePaths.users,
                    element: (
                    <ProtectedPage page={PagesNames.Users}>
                        <AppLayout path={RoutePaths.users}><UsersPage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                {
                    path: RoutePaths.procedures,
                    element: (
                    <ProtectedPage page={PagesNames.Procedures}>
                        <AppLayout path={RoutePaths.procedures}><ProceduresPage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                {
                    path: RoutePaths.analytics,
                    element: (
                    <ProtectedPage page={PagesNames.Analytics}>
                        <AppLayout path={RoutePaths.analytics}><AnalyticsPage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                {
                    path: RoutePaths.analyticsByUser,
                    element: (
                    <ProtectedPage page={PagesNames.Analytics}>
                        <AppLayout path={RoutePaths.analyticsByUser}><UserAnalyticsPage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                {
                    path: RoutePaths.licenses,
                    element: (
                    <ProtectedPage page={PagesNames.Licenses}>
                        <AppLayout path={RoutePaths.licenses} showSidebar={false}><LicensesPage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                {
                    path: RoutePaths.download,
                    element: (
                    <ProtectedPage page={PagesNames.Download}>
                        <AppLayout path={RoutePaths.download} showSidebar={false}><DownloadPage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                {
                    path: RoutePaths.profile,
                    element: (
                    <ProtectedPage page={PagesNames.Profile}>
                        <AppLayout path={RoutePaths.profile} showSidebar={false}><ProfilePage /></AppLayout>
                    </ProtectedPage>
                    ),
                },
                ],
            },
        ],
    },
];

export const AppRouter = ({router} : any) => {
    return (<RouterProvider router={router} fallbackElement={"Loading Manager..."} />);
};
