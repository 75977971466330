import color from 'color';

import {
    COLOR_WHITE, COLOR_BLACK, COLOR_GREY_DARKER,
    RADIUS, SPACING_BASE
} from '../constants';

export const font = (size = '18px', weight = 'normal', color = COLOR_BLACK) => ({
    fontSize: size,
    fontWeight: weight,
    color,
});

export const flex = (direction = 'row', justify = 'flex-start', align = 'stretch') => ({
    display: 'flex',
    flexDirection: direction,
    justifyContent: justify,
    alignItems: align,
});

export const textOverflowDots = () => ({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const setSize = (width = 0, height = 0) => ({
    width,
    height,
});

export const positionCenter = () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

export const customScroll = (
    width = 4, thumbColor = COLOR_GREY_DARKER,
    trackColor = `${color(COLOR_GREY_DARKER).fade(0.75)}`, borderRadius = RADIUS / 2
) => ({
    overflow: 'hidden auto !important',
    // '-webkit-overflow-scrolling': 'touch', // TODO: fix typing error here

    '&::-webkit-scrollbar': {
        width,
    },

    '&::-webkit-scrollbar-track': {
        width,
        borderRadius,
        backgroundColor: trackColor,
    },

    '&::-webkit-scrollbar-thumb': {
        borderRadius,
        backgroundColor: thumbColor,
    },

    // for Firefox
    scrollbarColor: thumbColor,
    // scrollbarWidth: 'thin', TODO: fix typing error here
});

export const stickyFormBlock = () => ({
    marginBottom: SPACING_BASE * 4,
    background: COLOR_WHITE,
    paddingTop: SPACING_BASE * 2,
    paddingBottom: SPACING_BASE,
    paddingRight: SPACING_BASE,
    paddingLeft: 0,
    marginTop: 0,
    position: 'sticky',
    zIndex: 1,
    top: 0,
});
