import { FormSchema } from '@shared/components/forms/FormSchema';

import { FormValues } from './FormValues';

type ForgotPasswordFormSchema = FormSchema<FormValues>;

export const valuesSchema: ForgotPasswordFormSchema = {
    email: {
        fieldName: 'email',
        label: 'Email',
        isRequired: true,
    },
};
