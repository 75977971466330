import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { flex } from '@resources/mixins';
import CircularProgress from '@mui/material/CircularProgress';
import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { ThemeProps } from '@styles/theme';
import { useAnalyticsGridUIStore, useUserAnalyticUIStore } from '@core/useStores';
import { ToastMessage } from '@shared/components/Toast';
import { messages } from '@shared/shared.messages';
import classNames from 'classnames';
import ExportIcon from '@assets/export.svg';
import { useTheme } from '@mui/material/styles';
import { Button } from '@shared/components/Button';
import { DateRangePopup } from '../../../@shared/components/DateRangePopup/DateRangePopup';
import { set } from 'lodash';

const useStyles = createUseStyles((theme: ThemeProps) => ({

    buttonExport: {
        width: 'auto',
        height: 'auto',
        marginLeft: 12,
        fontSize: 14,
        fontFamily: theme.font.secondary,
        padding: 8,
    },

    exportIcon: {
        marginRight: 4,
    },
}));

export const AllExecutionsExport: FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const [isExportModalOpen, setExportModalOpen] = useState(false);

    const analyticsGridUIStore = useAnalyticsGridUIStore();
    const exportAllExecutions = (start: Date, end: Date) => {
        toast.success(
            <ToastMessage
                message={messages['export.toast.inProgress']}
                type='success'
            />
        );
        analyticsGridUIStore.exportAllExecutions({ startDate: start, endDate: end });
        setExportModalOpen(false);
    }

    const openExportModal = () => setExportModalOpen(true);

    return (
        <>
            <DateRangePopup
                isOpen={isExportModalOpen}
                message={messages['export.dateRange']}
                confirmText={messages['export.exportButton']}
                onDismiss={() => setExportModalOpen(false)}
                onConfirm={exportAllExecutions} />
            <Observer>
                {() => {
                    const {
                        isExporting,
                        canExport,
                    } = analyticsGridUIStore;

                    return (
                        <Button
                            onClick={openExportModal}
                            color='secondary'
                            className={classNames(styles.buttonExport)}
                            disabled={!canExport}
                            loading={isExporting}
                            startIcon={<img src={ExportIcon} className={classNames(styles.exportIcon)} />}>
                            {
                                messages['export.exportButton.allExecutions']
                            }
                        </Button>
                    )
                }}
            </Observer>
        </>
    )
}
