import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    panel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: 96,
        borderRadius: theme.radius,
        border: `1.5px solid ${theme.colors.grey_light}`,
        backgroundColor: theme.colors.white,
        padding: `${0}px ${theme.spacing(6)}`,
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    iconWrapper: {
        display: 'flex',
    },
    icon: {
        marginLeft: theme.spacing(3),
        color: theme.colors.violet_primary,
        cursor: 'pointer',
        fontSize: 24,
    },
    title: {
        fontFamily: theme.font.secondary,
        fontSize: 24,
        lineHeight: 1.33,
        color: theme.colors.black,
        fontWeight: 'normal',
    },
    items: {
        display: 'flex',
        width: '60%',
        '@media (max-width: 1200px)': {
            width: '75%',
        },
    },
    item: {
        display: 'flex',
        width: '33.33%',
        alignItems: 'center',
    },
    info: {
        paddingLeft: theme.spacing(3),
    },
    itemTitle: {
        fontFamily: theme.font.secondary,
        fontSize: 16,
        lineHeight: 1.25,
        color: theme.colors.grey_text,
    },
    text: {
        fontFamily: theme.font.primary,
        color: theme.colors.violet_primary,
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: 1.4,
    },
}));
