import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { getEnv } from 'mobx-state-tree';

import { ThemeProps } from '@styles/theme';
import { SidebarFilterButton } from '@shared/components/SidebarFilterButton';
import { useProceduresUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import {
    ALL_PROCEDURES_ID,
    PROCEDURES_AVAILABILITY_ALL,
    PROCEDURES_IN_PUBLIC,
    PROCEDURES_NOT_ASSIGNED,
    PROCEDURES_PRIVATE,
} from 'Procedures/domain/constants';
import { messages } from 'Procedures/procedures.messages';
import { useTheme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    wrapper: {
        marginBottom: theme.spacing(4),
    },

    menuItem: {
        marginBottom: theme.spacing(2),
    },
}));

export const ProceduresSidebar: FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    const proceduresGridUIStore = useProceduresUIStore();

    const { groups } = getEnv<IStoresEnv>(proceduresGridUIStore);

    const setAvailabilityFilter = (availability: number) => {
        proceduresGridUIStore.setParams({
            page: 0,
            inGroup: 0,
            inGroupId: undefined,
            procedureAvailability: availability,
        });

        groups.unselectGroup();
    };

    return (
        <Observer>
            {() => {
                const filters = proceduresGridUIStore.requestParams.getParams;

                return (
                    <div className={styles.wrapper}>
                        <SidebarFilterButton
                            className={styles.menuItem}
                            active={
                                filters.inGroup === ALL_PROCEDURES_ID && filters.procedureAvailability === PROCEDURES_AVAILABILITY_ALL
                            }
                            onClick={() => setAvailabilityFilter(PROCEDURES_AVAILABILITY_ALL)}
                        >
                            {messages['proceduresSidebar.button.allProcedures']}
                        </SidebarFilterButton>

                        <SidebarFilterButton
                            className={styles.menuItem}
                            active={filters.procedureAvailability === PROCEDURES_IN_PUBLIC}
                            onClick={() => setAvailabilityFilter(PROCEDURES_IN_PUBLIC)}
                        >
                            {messages['proceduresSidebar.button.public']}
                        </SidebarFilterButton>

                        <SidebarFilterButton
                            className={styles.menuItem}
                            active={filters.procedureAvailability === PROCEDURES_NOT_ASSIGNED}
                            onClick={() => setAvailabilityFilter(PROCEDURES_NOT_ASSIGNED)}
                        >
                            {messages['proceduresSidebar.button.unassigned']}
                        </SidebarFilterButton>

                        <SidebarFilterButton
                            className={styles.menuItem}
                            active={filters.procedureAvailability === PROCEDURES_PRIVATE}
                            onClick={() => setAvailabilityFilter(PROCEDURES_PRIVATE)}
                        >
                            {messages['proceduresSidebar.button.private']}
                        </SidebarFilterButton>
                    </div>
                )
            }}
        </Observer>
    )
}
