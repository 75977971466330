import { Instance, SnapshotIn, types, flow, onSnapshot, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { useUserPermissionsUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import { USERS_IN_GROUP_ID, ALL_USERS_ID } from '@shared/constants';
import { UsersGridStore } from './UsersGrid.store';
import { IGroupsDataStoreSnaphotOut, GroupUpdate$ } from 'Groups/store';
import { IPageQueryStoreSnapshotIn } from './PageQuery.store';
import { IUserPermissionsUIStore } from '@core/Auth/stores/UserPermissionsUI.store';

export const UsersGridUIStoreInferred = types
    .model('UsersGridUIStoreInferred', {
        _users: types.optional(UsersGridStore, {}),
        isExporting: types.optional(types.boolean, false),
        isImporting: types.optional(types.boolean, false),
        isActivePage: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        status: DataStateStore.create({ state: DataState.initial }),
    }))
    .views(self => ({
        get users() { return self._users.usersList; },
        get pagination() { return self._users.paginationStatus; },
        get requestParams() { return self._users.requestParams; },
        get statistics() { return self._users.statistics; },
        get inviteUser() { return self._users.inviteUser; },
        get isFilterOrSearchApplied() { return self._users.requestParams.areSearchParamsSet; },
    }))
    .views(self => ({
        get canExport() { return self.users?.length; },
        get canImport() {
            const currentUserPermissions = useUserPermissionsUIStore();

            return currentUserPermissions.isSuperAdmin || currentUserPermissions.isMultiAdmin;
        },
        get isTableVisible() { return self.users.length || self.status.isLoading; },
        get noFilteringResults() { return !self.users.length && !self.status.isLoading && self.isFilterOrSearchApplied },
        get noItemsInGroup() { return !self.users.length && !self.status.isLoading && !self.isFilterOrSearchApplied },
    }))
    .actions(self => {
        const load = flow(function* () {
            self.status.setLoading();
            yield self._users.loadUsers();
            self.status.setDone();
        });

        const loadStatistics = flow(function* () {
            yield self._users.loadStatistics();
        });

        const setParams = (params: IPageQueryStoreSnapshotIn) => {
            self.requestParams.setParams(params);
            self.requestParams.saveParamsToStorage();
        }

        const resetParams = () => self.requestParams.resetParams();

        const togglePageActivity = (isActive: boolean) => self.isActivePage = isActive;

        const cleanUp = () => {
            resetParams();
            self._users.cleanData();
        }

        const exportAllUsers = flow(function* (payload, companyId) {
            self.isExporting = true;
            yield self._users.exportAllUsers(payload, companyId);
            self.isExporting = false;
        });

        const importUsers = flow(function* (payload) {
            self.isImporting = true;
            const result = yield self._users.importUsers(payload);
            self.isImporting = false;

            return result;
        });

        return {
            load,
            setParams,
            resetParams,
            loadStatistics,
            togglePageActivity,
            cleanUp,
            exportAllUsers,
            importUsers,
        }
    })
    .actions(self => {
        const { groups } = getEnv<IStoresEnv>(self);

        return {
            afterCreate: () => {
                GroupUpdate$.subscribe(() => {
                    if (self.isActivePage) {
                        self.load();
                        self.loadStatistics();
                    }
                });

                onSnapshot(self.requestParams, () => {
                    if (self.isActivePage) {
                        self.load();
                        self.loadStatistics();
                    }
                    self.requestParams.saveParamsToStorage();
                });

                onSnapshot(groups, (snap: IGroupsDataStoreSnaphotOut) => {
                    if (snap.selectedGroup) {
                        self.setParams({
                            page: 0,
                            inGroup: USERS_IN_GROUP_ID,
                            inGroupId: snap.selectedGroup as string,
                        });
                    }

                    // Show 0 page of 'All users' after group removing
                    if (self.requestParams.inGroup === USERS_IN_GROUP_ID && !snap.selectedGroup) {
                        self.setParams({
                            page: 0,
                            inGroup: ALL_USERS_ID,
                            inGroupId: undefined,
                        });
                    }
                })
            },
        }
    })

type UsersGridUIStoreFactoryType = typeof UsersGridUIStoreInferred;
interface IUsersGridUIStoreFactory extends UsersGridUIStoreFactoryType { }
export const UsersGridUIStore: IUsersGridUIStoreFactory = UsersGridUIStoreInferred;
export interface IUsersGridUIStore extends Instance<IUsersGridUIStoreFactory> { }
export interface IUsersGridUIStoreSnapshotIn extends SnapshotIn<IUsersGridUIStore> { }
