import { HttpStatusCode } from '@ace/core';

/// CIRCUITS PROD
export const BASE_URL = 'https://api.xr-weavr.com';
export const WEB_BASE_URL = 'https://manager.xr-weavr.com';
export const RECAPTCHA_KEY='6Lc-7uspAAAAAO9H5NPI5MQ8KFv-otu0TFuaGItb';

/// CIRCUITS DEV
// export const BASE_URL = 'https://dev.api.xr-weavr.com';
// export const WEB_BASE_URL = 'https://dev.manager.xr-weavr.com';
// export const RECAPTCHA_KEY='6Lde7espAAAAAPNRrGpWmAODI_QCV10SWx8wnrRX';

/// LOCAL
// export const BASE_URL = 'http://localhost:8001';
// export const WEB_BASE_URL = 'http://localhost:4444';
// export const RECAPTCHA_KEY = '6LcKB4sgAAAAACsVt7r_Yb-RjFG6YHg23ZJ3jvd3';

export const HUBSPOT_BASE_URL = 'https://api.hsforms.com';
const HUBSPOT_PORTAL_ID = '7532984';
const HUBSPOT_FORM_ID = '54fbcf1b-ce95-498c-aa39-ebef27ad1bda';
export const HUBSPOT_REGISTER_USER_URL = `${HUBSPOT_BASE_URL}/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_ID}`;

export const CLIENT_ID = 'ro.weavrManagerUI';
export const SECRET = 'secret';
export const SCOPE = 'offline_access openid profile identity analytic collaboration content manageruiagg export xapi guardedgateway server';
export const TOKEN_TYPE = 'Bearer';
export const GRANT_TYPE = 'refresh_token';

export const DEFAULT_API_ERROR_MESSAGES: Record<string, any> = {
    [HttpStatusCode.UNAUTHORIZED]: 'You are not authorized to perform this action',
    [HttpStatusCode.FORBIDDEN]: 'You are forbidden to perform this action',
    [HttpStatusCode.NOT_FOUND]: 'The resource was not found',
    [HttpStatusCode.REQUEST_TIMEOUT]: 'The request timed out',
    [HttpStatusCode.GATEWAY_TIMEOUT]: 'The request timed out',
    [HttpStatusCode.BAD_REQUEST]: 'Bad request',
    [HttpStatusCode.INTERNAL_SERVER_ERROR]: 'Internal server error',
}

