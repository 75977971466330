import { FormSchema } from '@shared/components/forms/FormSchema';

import { CompanyFormValues } from './CompanyModal';
import { messages } from 'Companies/companies.messages';

type CompanyFormSchema = FormSchema<CompanyFormValues>;

export const FIELD_NAMES = {
    name: messages['companyModal.name.label'],
    description: messages['companyModal.description.label'],
    maxGroups: messages['companyModal.maxGroups.label'],
    maxUsers: messages['companyModal.maxUsers.label'],
    maxProcedures: messages['companyModal.maxProcedures.label'],
    maxLicensesCreator: messages['companyModal.maxLicensesCreator.label'],
    maxLicensesPlayer: messages['companyModal.maxLicensesPlayer.label'],
    expireAt: messages['companyModal.expireAt.label'],
    dataIsolation: messages['companyModal.dataIsolation.label'],
    allowStandaloneBuild: messages['companyModal.allowStandaloneBuild.label'],
    maxLaunchPasses: messages['companyModal.maxLaunchPasses.label'],
    launchPassStartDate: messages['companyModal.launchPassStartDate.label'],
    isLaunchPassMonthly: messages['companyModal.isLaunchPassMonthly.label'],
    multiAdmins: messages['companyModal.multiadmin.label'],
}

export const valuesSchema: CompanyFormSchema = {
    name: {
        fieldName: 'name',
        label: FIELD_NAMES.name,
        isRequired: true,
    },
    description: {
        fieldName: 'description',
        label: FIELD_NAMES.description,
        isRequired: true,
    },
    maxGroups: {
        fieldName: 'maxGroups',
        label: FIELD_NAMES.maxGroups,
        isRequired: true,
    },
    maxUsers: {
        fieldName: 'maxUsers',
        label: FIELD_NAMES.maxUsers,
        isRequired: true,
    },
    maxProcedures: {
        fieldName: 'maxProcedures',
        label: FIELD_NAMES.maxProcedures,
        isRequired: true,
    },
    maxLicensesCreator: {
        fieldName: 'maxLicensesCreator',
        label: FIELD_NAMES.maxLicensesCreator,
        isRequired: true,
    },
    maxLicensesPlayer: {
        fieldName: 'maxLicensesPlayer',
        label: FIELD_NAMES.maxLicensesPlayer,
        isRequired: true,
    },
    expireAt: {
        fieldName: 'expireAt',
        label: FIELD_NAMES.expireAt,
        isRequired: false,
    },
    dataIsolation: {
        fieldName: 'dataIsolation',
        label: FIELD_NAMES.dataIsolation,
        isRequired: true,
    },
    allowStandaloneBuild: {
        fieldName: 'allowStandaloneBuild',
        label: FIELD_NAMES.allowStandaloneBuild,
        isRequired: true,
    },
    maxLaunchPasses: {
        fieldName: 'maxLaunchPasses',
        label: FIELD_NAMES.maxLaunchPasses,
        isRequired: false,
    },
    launchPassStartDate: {
        fieldName: 'launchPassStartDate',
        label: FIELD_NAMES.launchPassStartDate,
        isRequired: false,
    },
    isLaunchPassMonthly: {
        fieldName: 'isLaunchPassMonthly',
        label: FIELD_NAMES.isLaunchPassMonthly,
        isRequired: false,
    },
    admins: {
        fieldName: 'admins',
        label: FIELD_NAMES.multiAdmins,
        isRequired: false,
    },
};
