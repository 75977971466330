import React, { FC, useState, useEffect } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import color from 'color';
import { PieChart, Pie, Cell, PieProps } from 'recharts';
import { createUseStyles } from 'react-jss';
import { useUserAnalyticUIStore } from '@core/useStores';
import { ExpandableList } from '@shared/components/ExpandableList';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, useTheme } from '@mui/material';
import { ThemeProps } from '@styles/theme';
import {
    COLOR_YELLOW,
    COLOR_BLUE_LINK,
    COLOR_GREY_LIGHT
} from '@styles/constants';
import { messages } from 'Analytics/analytic.messages';
import { ActiveShape, ActiveShapeProps } from './ActiveShape';

type DataItem = {
    name: string,
    value: number | null,
    id: string
}

interface ExtendedPieProps extends PieProps {
    value: number
}

const SECTOR_COLORS = ['#87e9e9', COLOR_YELLOW, '#ffd08b', '#bd74b9', '#a08ad1', COLOR_BLUE_LINK, '#13c2c2', '#ff7a45', '#ff4d4f', '#36cfc9', '#40a9ff', '#597ef7', '#9254de', '#f759ab', '#ff85c0', '#ffc53d', '#ffc069', '#bae637', '#73d13d', '#36cfc9', '#40a9ff', '#597ef7', '#9254de', '#f759ab'];
const MAX_EXECUTIONS_INSIDE_COLUMN = 5;

const useStyles = createUseStyles((theme: ThemeProps) => ({
    faded: {},
    chart: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(8)} ${theme.spacing(10)}`,
        backgroundColor: theme.colors.white,
        borderRadius: theme.radius,
        border: `2px solid ${theme.colors.grey_light}`,
        marginBottom: theme.spacing(6),
        '@media (max-width: 1200px)': {
            flexWrap: 'wrap',
        },
    },
    pie: {
        width: '25%',
        marginRight: theme.spacing(16),
        '@media (max-width: 1200px)': {
            marginRight: theme.spacing(8),
        },
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    half: {
        width: 'calc(50% - 24px)',
        marginRight: theme.spacing(6),
    },
    procedure: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 20,
        marginBottom: theme.spacing(2.5),
        cursor: 'pointer',
        padding: 2,
        borderRadius: theme.radius,
    },
    name: {
        fontSize: 12,
        lineHeight: 1.33,
        color: theme.colors.black,
    },
    preview: {
        width: 16,
        height: 16,
        flexShrink: 0,
        borderRadius: theme.radius,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    title: {
        paddingBottom: theme.spacing(5),
        '&$faded': {
            color: theme.colors.grey_text,
        },
    },
    executed: {
        width: '50%',
        '@media (max-width: 1200px)': {
            width: 'calc(100% - 240px)',
        },
    },
    notExecuted: {
        minWidth: 245,
        width: '20%',
        flexWrap: 'wrap',
        '@media (max-width: 1200px)': {
            width: '60%',
            marginTop: theme.spacing(6),
        },
    },
    loader: {
        color: theme.colors.violet_primary,
    },
}));

export const AnalyticsPieChart: FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    const [activeIndex, setActiveIndex] = useState(0);

    const params = useParams();

    const userAnalyticUIStore = useUserAnalyticUIStore();

    useEffect(() => {
        //userAnalyticUIStore.requestParams.resetParams();
        const { startDate, endDate } = userAnalyticUIStore.requestParams;
        const endDateEOD = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1)).toISOString();

        userAnalyticUIStore.setParams({ userId: params.id });
        userAnalyticUIStore.loadUserProceduresExecutions({
            userId: params.id,
            startDate: startDate,
            endDate: endDateEOD,
        });
    }, [params.id]);

    const onSliceClick = async (sector: DataItem) => {
        const newParams = {
            ...userAnalyticUIStore.requestParams.getParams,
            procedureId: sector.id,
            procedureVersionId: '',
            procedureVersionPlatformId: '',
            executionMode: '',
        };

        await userAnalyticUIStore.analyticsFilters.loadFilterOptions(newParams);

        debugger;
        userAnalyticUIStore.setParams({
            procedureId: sector.id,
            procedureVersionId: '',
            procedureVersionPlatformId: '',
            executionMode: '',
        });

        userAnalyticUIStore.setProcedureName(sector.name);
    }

    const onPieEnter = (_data: any, index: number) => {
        setActiveIndex(index);
    }

    const renderProcedureRow = (sector: DataItem, i: number, keepIndexes?: boolean) => {
        const index = keepIndexes ? i + MAX_EXECUTIONS_INSIDE_COLUMN : i;
        const params = userAnalyticUIStore.requestParams.getParams;
        const isSelected = sector.id === params.procedureId;
        const isHovered = activeIndex === index;

        return (
            <div
                key={`sector-${index}`}
                className={styles.procedure}
                style={{
                    backgroundColor: (isHovered || isSelected) ? `${color(SECTOR_COLORS[index]).fade(0.8)}` : 'inherit',
                    fontWeight: isSelected ? 'bold' : 'normal',
                }}
                onMouseEnter={() => setActiveIndex(index)}
                onClick={() => onSliceClick(sector)}
                data-testid="analytics-pie-chart-row-button"
            >
                <div className={styles.preview} style={{ backgroundColor: SECTOR_COLORS[index] }} />
                <div className={styles.name}>{sector.name}</div>
            </div>
        )
    }

    const renderNotExecutedRow = (value: string, i: number) => (
        <div
            key={`sector-${i}`}
            className={styles.procedure}
        >
            <div className={styles.preview} style={{ backgroundColor: COLOR_GREY_LIGHT }} />
            <div className={styles.name}>{value}</div>
        </div>
    )

    return (
        <Observer>
            {() => {
                const {
                    isAnalyticsLoading,
                    executedProcedures,
                    notExecutedProcedures,
                } = userAnalyticUIStore;

                const displayNoData = (!executedProcedures.length && !isAnalyticsLoading);
                const leftColumnData = executedProcedures.slice(0, MAX_EXECUTIONS_INSIDE_COLUMN);
                const rightColumnData = executedProcedures.slice(MAX_EXECUTIONS_INSIDE_COLUMN, executedProcedures.length);

                return (
                    <div className={styles.chart}>
                        {isAnalyticsLoading && (
                            <CircularProgress className={styles.loader} color='inherit' size={24} />
                        )}
                        <PieChart className={styles.pie} width={200} height={200}>
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={(props: any) => <ActiveShape {...props} />}
                                data={executedProcedures}
                                cx={95}
                                cy={95}
                                innerRadius={70}
                                outerRadius={90}
                                fill={COLOR_YELLOW}
                                dataKey="value"
                                onMouseEnter={onPieEnter}
                                onClick={onSliceClick}
                            >
                                {executedProcedures
                                    .map((_entry, index) => <Cell key={`cell-${index}`} fill={SECTOR_COLORS[index]} />)
                                }
                            </Pie>
                        </PieChart>
                        <div className={styles.executed}>
                            <Typography
                                className={styles.title}
                                align="left"
                                variant="h6"
                            >
                                {messages['pieChart.completed']}
                            </Typography>
                            {displayNoData && (
                                <Typography
                                    variant="h6"
                                    className={classNames(styles.title, styles.faded)}
                                    data-testid="analytics-pic-chart-no-data"
                                >
                                    {messages['pieChart.noData']}
                                </Typography>
                            )}
                            {isAnalyticsLoading && (
                                <CircularProgress className={styles.loader} color='inherit' size={24} />
                            )}
                            {!!executedProcedures.length && (
                                <div className={styles.list}>
                                    <div className={styles.half}>
                                        {leftColumnData.map((sector, i) => renderProcedureRow(sector, i))}
                                    </div>
                                    {rightColumnData && (
                                        <div className={styles.half}>
                                            {rightColumnData.map((sector, i) => renderProcedureRow(sector, i, true))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={styles.notExecuted}>
                            <Typography
                                className={styles.title}
                                align="left"
                                variant="h6"
                            >
                                {messages['pieChart.notExecuted']}
                            </Typography>
                            {displayNoData && (
                                <Typography
                                    variant="h6"
                                    className={classNames(styles.title, styles.faded)}
                                    data-testid="analytics-pic-chart-no-data"
                                >
                                    {messages['pieChart.noData']}
                                </Typography>
                            )}
                            {isAnalyticsLoading && (
                                <CircularProgress className={styles.loader} color='inherit' size={24} />
                            )}
                            {!!notExecutedProcedures.length && (
                                <ExpandableList itemRenderer={renderNotExecutedRow} list={notExecutedProcedures} />
                            )}
                        </div>
                    </div>
                )
            }}
        </Observer>
    )
}
