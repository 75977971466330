import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export type DeleteCompanyParams = {
    id: string;
};

export const performDeleteCompany = async (api: IApiStore, params: EndpointCallParams<DeleteCompanyParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .delete(`identity-app/api/v1/Companies/${payload.id}`, {
            ...rest,
            payload,
        })

    return req;
}
