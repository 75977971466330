export enum RootRoutes {
  Companies = 'companies',
  Users = 'users',
  Procedures = 'procedures',
  Collaboration = 'collaboration',
  Analytics = 'analytics',
  Licenses = 'licenses',
  Download = 'download',
  Profile = 'profile',
}

export enum AuthRoutes {
  Login = 'login',
  Register = 'register',
  ForgotPassword = 'forgot-password',
  SetPassword = 'set-password',
}

export class RoutePaths {
  static readonly root = '/';
  static readonly companies: string = `${RootRoutes.Companies}`;
  static readonly users: string = `${RootRoutes.Users}`;
  static readonly procedures: string = `${RootRoutes.Procedures}`;
  static readonly collaboration: string = `${RootRoutes.Collaboration}`;
  static readonly analytics: string = `${RootRoutes.Analytics}`;
  static readonly licenses: string = `${RootRoutes.Licenses}`;
  static readonly download: string = `${RootRoutes.Download}`;
  static readonly analyticsByUser: string = `${RootRoutes.Analytics}/:id`;
  static readonly profile: string = `${RootRoutes.Profile}`;
  static readonly login: string = `${AuthRoutes.Login}`;
  static readonly register: string = `${AuthRoutes.Register}`;
  static readonly forgotPassword: string = `${AuthRoutes.ForgotPassword}`;
  static readonly setPassword: string = `${AuthRoutes.SetPassword}`;
}
