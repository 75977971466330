import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { stickyFormBlock } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    halfWidth: {},
    modal: {
        maxHeight: 'calc(100vh - 50px)',
        overflowY: 'auto',
    },
    form: {
        width: 476,
    },
    h2: {
        fontFamily: theme.font.secondary,
        fontSize: 24,
        lineHeight: 1.33,
        color: theme.colors.black,
        fontWeight: 'normal',
        marginBottom: theme.spacing(7),
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.white,
        zIndex: 1,
    },
    field: {
        marginBottom: theme.spacing(6),
        width: 'calc(100% - 12px)',
        '&$halfWidth': {
            width: 'calc(50% - 12px)',
        },
    },
    fieldWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    dropdown: {
        margin: 0,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
    },
    end: {
        justifyContent: 'flex-end',
    },
    button: {
        marginLeft: theme.spacing(5),
    },
    errors: {
        ...stickyFormBlock(),
    },
    error: {
        backgroundColor: theme.colors.red,
        marginBottom: theme.spacing(2),
    },
}));
