import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useObserver } from 'mobx-react';
import { getEnv } from 'mobx-state-tree';
import { createUseStyles } from 'react-jss';
import CircularProgress from '@mui/material/CircularProgress';

import { useQuery } from '@shared/helpers';
import { IStoresEnv } from '@core/storesEnv';
import { useSetPasswordUIStore } from '@core/useStores';
import { AppExternalSuccessMessage } from '@shared/components/AppExternalSuccessMessage';
import { SetPasswordFormControls } from './SetPasswordFormControls';
import { createValidationSchema } from './validationSchema';
import { flex } from '@resources/mixins';
import { FormValues } from './FormValues';
import { ThemeProps } from '@styles/theme';
import { messages } from 'AuthPart/authpart.messages';
import { useTheme } from '@mui/material/styles';

const QUERY_TOKEN = 'token';
const QUERY_EMAIL = 'email';
const QUERY_MODE = 'mode';
const INVITE_MODE = 'invite';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    viewWrapper: {
        width: 468,
        position: 'relative',
    },

    loadingOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: theme.colors.white,
        zIndex: 1,
        ...flex('row', 'center', 'center'),
    },
}));

export const SetPasswordForm: React.FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const setPasswordUiStore = useSetPasswordUIStore();
    const { navigator } = getEnv<IStoresEnv>(setPasswordUiStore);

    const query = useQuery();
    const token = query.get(QUERY_TOKEN) || '';
    const email = query.get(QUERY_EMAIL) || '';
    const mode = query.get(QUERY_MODE) || '';
    const isInviteMode = mode === INVITE_MODE;

    useEffect(() => {
        setPasswordUiStore.verifyToken(email, token, mode);
    }, []);

    const setPassword = (values: FormValues) => {
        if (token && email) {
            setPasswordUiStore.setPassword({
                ...values,
                token,
                email,
            }, isInviteMode);
        }
    }

    return useObserver(() => (
        setPasswordUiStore.status.isDone
            ? (
                <AppExternalSuccessMessage
                    text={messages['setPasswordForm.success'](isInviteMode)}
                    navigator={navigator}
                />
            )
            : (
                <div className={styles.viewWrapper}>
                    {
                        setPasswordUiStore.tokenValidationStatus.isLoading && (
                            <div className={styles.loadingOverlay}>
                                <CircularProgress color='inherit' size={48} />
                            </div>
                        )
                    }

                    <Formik
                        onSubmit={setPassword}
                        initialValues={new FormValues()}
                        enableReinitialize={true}
                        validationSchema={createValidationSchema(email)}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {props =>
                            <SetPasswordFormControls
                                {...props}
                                inviteMode={isInviteMode}
                            />
                        }
                    </Formik>
                </div>
            )
    ));
}
