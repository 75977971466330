import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    errorWrapper: {
        marginBottom: theme.spacing(8),
    },

    wrapper: {
        margin: `0 auto ${theme.spacing(8)}`,
        width: 320,
    },

    formTitle: {
        color: theme.colors.white,
        fontSize: 20,
        lineHeight: 1.4,
        fontWeight: 'normal',
        fontFamily: theme.font.secondary,
    },

    fieldWrapper: {
        marginBottom: theme.spacing(4),
    },

    actionWrapper: {
        marginTop: theme.spacing(8),

        '& button': {
            width: '100%',
        },
    },
}));
