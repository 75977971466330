import React, { FC, useEffect, useState } from 'react';
import { getEnv } from 'mobx-state-tree';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';

import { useStyles } from './WelcomePopup.styles';
import { messages } from 'Welcome/welcome.messages';
import { ThemeProps } from '@styles/theme';


export type WelcomePopupProps = {
    isOpen: boolean,
    onRequestClose: () => void,
    onRequestCloseSave: () => void,
}

export const WelcomePopup: FC<WelcomePopupProps> = ({
    isOpen,
    onRequestClose,
    onRequestCloseSave,
}) => {
    const theme = useTheme<ThemeProps>();
    
    const styles = useStyles({ theme });


    const [isLoading, setLoading] = useState(false);
    const [backEndErrors, setBackEndErrors] = useState([]);


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
        >
            <div className={styles.heading}>
                <h2 className={styles.h2}>
                    {messages['welcomePage.title']}
                </h2>
            </div>
            <div className={styles.text}>
                {messages['welcomePage.text']}
            </div>
            <div className={styles.buttons}>
                <Button
                    onClick={onRequestCloseSave}
                    className={styles.cancel}
                    color="secondary"
                    data-testid="group-modal-cancel"
                >
                    {messages['welcomePage.cancel']}
                </Button>
            </div>
        </Modal>
    )
}
