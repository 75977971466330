import React from 'react';
import { ModalPlaceholder } from '@ace/core';
import 'react-toastify/dist/ReactToastify.min.css';
import '@ace/core/lib/core.css';

import { ToastContainer } from '@shared/components/Toast';
import { modalService } from '@shared/components/Modal/modalService';
import '@styles/styles.scss';
import { useTypographyStyles } from '@styles/typography';

import { AppRouter } from './routes/AppRouter';
import { ThemeProps } from '@styles/theme';
import { useTheme } from '@mui/material/styles';
import { NavigateFunction, useNavigation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

type Props = {
    router: any;
};

export const App: React.FC<Props> = ({router}) => {
    const theme = useTheme<ThemeProps>()
    useTypographyStyles({ theme });

    return (
        <div className="app">
            <AppRouter router={router} />
            <ModalPlaceholder modalService={modalService} />
            <ToastContainer />
        </div>
    )
};
