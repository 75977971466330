import React from 'react';
import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';
import { messages } from '../../procedures.messages';
import { useTheme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    wrapper: {
        ...flex('column', 'center', 'center'),
        width: '100%',
        border: `1.5px solid ${theme.colors.grey_light}`,
        minHeight: 'calc(100vh - 300px)',
        height: 100,
        background: theme.colors.white,
        borderRadius: theme.radius,
        color: theme.colors.grey_text,
        fontFamily: theme.font.secondary,
        fontSize: 24,
        lineHeight: 1.3,
    },
}));

export const NoProceduresInGroup: React.FC = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    return (
        <section className={styles.wrapper}>
            <p data-testid="no-procedures-in-group-view">{messages['proceduresPage.noProceduresInGroup']}</p>
            {/* Extra buttons will be added here in future release */}
        </section>
    )
}
