import React from 'react';
import classNames from 'classnames';
import { FormikProps, FieldMetaProps } from 'formik';
import { useTheme } from '@mui/material/styles';
import { SvgIcon, Svg } from '@ace/icons';

import { APP_EXTERNAL_STYLE_VARIANT } from '../../BaseInputProps';
import { useStyles } from './CleanValue.styles';
import { ThemeProps } from '@styles/theme';

export type CleanValueProps = {
    meta: FieldMetaProps<any>; // TODO: try to fix <any>
    form: FormikProps<any>; // TODO: try to fix <any>
    name: string;
    styleVariant?: 'appExternal' | 'appInner',
    disabled?: boolean;
};

export const CleanValue: React.FC<CleanValueProps> = ({
    meta, form, name, styleVariant, disabled,
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const clearInputValue = () => form.setFieldValue(name, '');

    return (
        <div
            onClick={clearInputValue}
            className={classNames(
                styles.actionWrapper,
                { [styles.appExternal]: styleVariant === APP_EXTERNAL_STYLE_VARIANT },
                { [styles.hiddenAction]: !meta.value },
                { [styles.disabledAction]: disabled },
            )}
        >
            <SvgIcon src={Svg.Close} width={12} className={styles.iconStyle} />
        </div>
    );
}
