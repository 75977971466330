export const messages = {
    'registerForm.validation.email': 'Email must contain \'@\', \'.\' and must not contain space',
    'registerForm.validation.required': (fieldLabel?: string) => `${fieldLabel} is required.`,

    'registerForm.title': 'Register',
    'registerForm.register': 'Register',
    'registerForm.email.label': 'Email',
    'registerForm.companyName.label': 'Company name',
    'registerForm.companyDescription.label': 'Company description',
    'registerForm.department.label': 'Department',
    'registerForm.name.label': 'First name',
    'registerForm.surname.label': 'Last Name',
    'registerForm.cancel': 'Cancel',
    'registerForm.errorCaptcha': 'Invalid captcha, please try again',

    'registerForm.success': 'Your account has been created, check your email to proceed.',
}
